import { getAccessToken, isSignedIn } from './Auth/auth'
import { Question, OpinionQuestion, EmailQuestion, Assignment } from "./english/model/Assignment";

const currentScriptURL = new URL(document.currentScript.src)
const HOST_DOMAIN = currentScriptURL.origin

export async function callAuthenticatedAPI(method, url, body = null) {
  const accessToken = await getAccessToken()
  return await callAPI(method, url, { 'Authorization': `Bearer ${accessToken}` }, body)
}

export async function callAPI(method, url, headers={}, body=null) {
  const reqOptions = {
    method: method,
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: body
  };
  const response = await fetch(HOST_DOMAIN + url, reqOptions);
  const jsonData = await response.json()
  return { json: jsonData, status: response.status }
}

export async function getUserProfile() {
  const response = await callAuthenticatedAPI('GET', '/api/profile')
  if (response.status == 200) {
    return response.json
  }
  else {
    return null
  }
}

export async function getCurrentPoint(){
  const response = await callAuthenticatedAPI('GET', '/api/point')
  if (response.status == 200) {
    return response.json
  }
  else {
    return null
  }
}

export async function updateAcceptedTosVersion(accepted_tos_version) {
  const response = await callAuthenticatedAPI('PUT', '/api/profile/accepted_tos_version', JSON.stringify({
    "accepted_tos_version": accepted_tos_version,
  }))

  if (response.status == 200) {
    return response.json
  }
  else {
    return false
  }
}

export async function deleteUserAccount(id) {
  const response = await callAuthenticatedAPI('DELETE', `/api/profile`)
  if (response.status == 200) {
    return true
  }
  else {
    return false
  }
}

export async function getUsers(tenant_id, user_id=null) {
  const response = await callAuthenticatedAPI('GET', `/api/tenants/${tenant_id}/users`)
  if (response.status == 200) {
    return response.json
  }
  else {
    return null
  }
}

export async function getUserAssignments(tenant_id, user_id) {
  const response = await callAuthenticatedAPI('GET', `/api/tenants/${tenant_id}/users/${user_id}/assignments`)
  if (response.status == 200) {
    return response.json
  }
  else {
    return null
  }
}

export async function getUserAssignment(tenant_id, user_id, assignment_id, revision_id = null) {
  const baseUrl = `/api/tenants/${tenant_id}/users/${user_id}/assignments/${assignment_id}`
  const url = baseUrl + (revision_id ? `/revisions/${revision_id}` : '')
  const response = await callAuthenticatedAPI('GET', url)
  if (response.status != 200) {
    return null
  }

  const assignment = response.json

  const assignment_data = assignment.assignment_data

  if (assignment_data.question.type == undefined) {
    assignment_data.question.type = assignment.type;
  }

  if (assignment_data.question.grade == undefined) {
    assignment_data.question.grade = assignment.grade;
  }

  return {
    "id": assignment.id,
    "grade": assignment.grade,
    "type": assignment.type,
    "title": assignment.title,
    "score": assignment.score,
    "modified_at": assignment.modified_at,
    "assignment": Assignment.fromJSON(assignment_data),
    "revision_number": assignment.revision_number,
    "revisions": assignment.revisions,
  }
}

export async function deleteUserAssignment(id) {
  const response = await callAuthenticatedAPI('DELETE', `/api/assignments/${id}`)
  if (response.status == 200) {
    return true
  }
  else {
    return false
  }
}

export async function createUserAssignment(assignment) {
  const response = await callAuthenticatedAPI('POST', `/api/assignments/`, JSON.stringify({
    "grade": assignment.question.grade,
    "type": assignment.question.type,
    "title": assignment.title,
    "score": assignment.score,
    "assignment": assignment
  }))
  if (response.status == 201) {
    return response.json
  }
  else {
    return null
  }
}

export async function updateUserAssignment(id, assignment) {
  await callAuthenticatedAPI('PUT', `/api/assignments/${id}`, JSON.stringify({
    "grade": assignment.question.grade,
    "type": assignment.question.type,
    "title": assignment.title,
    "score": assignment.score,
    "assignment": assignment
  }))
  return true
}

export async function getSystemConfig() {
  const response = await callAPI('GET', '/api/system_config')
  if (response.status == 200) {
    return response.json
  }
  else {
    return null
  }
}

export async function getCorrection(content) {
  let response
  let request_data = {
    content: content,
    options: {"style": "standard"},
    lang: 'en'
  }
  const isLoggedIn = await isSignedIn()
  if (isLoggedIn) {
    response = await callAuthenticatedAPI('POST', `/api/correction`, JSON.stringify(request_data))
  }
  else {
    response = await callAPI('POST', `/api/correction`, {}, JSON.stringify(request_data))
  }

  if (response.status == 200) {
    return response.json
  }
  else {
    return null
  }
}

export async function get_ocr_result(task_id) {
  let response = await callAuthenticatedAPI('GET', `/api/ocr/${task_id}`)
  if (response.status == 200) {
    return response.json
  }
  else {
    throw new Error();
  }
}

export async function chat(thread_id, message) {
  // console.log('chat message', message)
  let response = await callAuthenticatedAPI('POST', `/api/chat/${thread_id}`, JSON.stringify({ 'message': message }))
  if (response.status == 201) {
    return response.json;
  }
  else {
    return null
  }
}

export async function delete_chat(thread_id) {
  await callAuthenticatedAPI('DELETE', `/api/chat/${thread_id}`)
}

export async function createCheckoutSession(plan_code) {
  const domain = window.location.origin;
  const success_url = `${domain}/stripe/complete_checkout_session?session_id={CHECKOUT_SESSION_ID}`;
  const cancel_url = `${domain}/stripe/cancel_checkout_session`;

  const response = await callAuthenticatedAPI('POST', '/api/stripe/checkout_sessions', JSON.stringify({ plan_code, success_url, cancel_url }))
  if (response.status == 200) {
    return response.json
  } else {
    console.error("Failed to create checkout session", response.json)
    return null
  }
}

export async function getCheckoutSession(session_id) {
  const response = await callAuthenticatedAPI('GET', `/api/stripe/checkout_sessions/${session_id}`)
  if (response.status == 200) {
    return response.json
  } else {
    return null
  }
}

export async function createBillingPortalSession(returnUrl) {
  const response = await callAuthenticatedAPI('POST', '/api/stripe/billing_portal_session', JSON.stringify({ return_url: returnUrl }));
  if (response.status == 200) {
    return response.json;
  } else {
    console.error("Failed to create billing portal session", response.json);
    return null;
  }
}
