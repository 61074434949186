import {Container, Row, Col, Modal } from 'react-bootstrap';
import SummaryScore from './SummaryScore'
import Perspectives from './Perspectives'
import {ReactComponent as ChatIcon} from "../images/chat.svg";
import Colors from '../constants/Colors'
import { MDBBtn } from 'mdb-react-ui-kit';

export default function SummaryDialog(props) {
  if (!props.scores) return null;

  function getText() {
    const texts = {
      1: "解答お疲れさまでした。解説を読んで修正してみましょう！",
      2: "がんばりました！解説を読んで、解答をブラッシュアップしましょう！",
      3: "もうひといき。解説を読んで、上のレベルを目指して再チャレンジしましょう！",
      4: "とてもよいです。別の問題にもチャレンジしてレベルアップしましょう！",
      5: "すばらしいです！別の問題もどんどんチャレンジして高いレベルをキープしましょう！",
    }
    if (props.scores.summaryScore == props.scores.maxScore) {
      return texts[5]
    }
    return texts[props.scores.summaryScore]
  }
  return (
    <Modal
      {...props}
    >
      <Modal.Header className="text-center" closeButton style={{
        backgroundColor:"var(--dim-gray)"
      }}>
        <div class="ms-4 modal-title w-100 fw-bold">今回の採点結果は</div>
      </Modal.Header>
      <Modal.Body className="p-0 text-center" style={{
        backgroundColor:"var(--dim-gray)",
      }}>
        <div className="mx-5">
          <Col>
            <SummaryScore scores={props.scores}/>
          </Col>
        </div>
        <div className="mx-5" style={{
          borderRadius: "10px",
          backgroundColor: "white"
        }}>
          <div className="p-2 p-md-3 text-start">
            {getText()}
          </div>
        </div>
        <div className="mx-2 mx-md-5 my-4">
          <Col>
            <Perspectives scores={props.scores}/>
          </Col>
        </div>
        <div fluid className="m-0 py-4" style={{
          backgroundColor: "white"
        }}>
          <div className="px-3 px-md-5 pb-4 text-start small">
            {props.scores.summaryScore <= 3 &&
              <div>
              添削内容・観点評価と改善ポイントをみながら解答を修正したら、もう一度AI採点をしてみましょう。
              </div>
            }
            {props.scores.summaryScore >= 4 &&
              <div>
              添削内容・観点評価と改善ポイントを確認したら、次の問題に挑戦しましょう。
              </div>
            }
            <div>
            より詳しいアドバイスが欲しい場合は<ChatIcon stroke={Colors.green}/><span className="green-text fw-bold">UGUIS先生のボタン</span>をクリックしてみてください。
            </div>
          </div>
          <div className="mx-1 mx-md-5">
            <Col>
            <MDBBtn className="green-button" onClick={() => props.onHide()}>詳しく確認する</MDBBtn>
            </Col>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
