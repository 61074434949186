import React, { useState, useRef, useContext, useEffect } from "react";
import Modal from 'react-bootstrap/Modal'
import { MDBContainer, MDBRow, MDBCol, MDBTabs, MDBTabsItem, MDBTabsLink, MDBInput, MDBIcon, MDBBtn } from 'mdb-react-ui-kit';
import { AuthContext } from "../Auth/AuthContext"
import Dialog from 'react-bootstrap-dialog'
import eventBus from "../EventBus";

function AdvancedOption(props) {
  // console.log('AdvancedOption', props.option);
  const handleChange= (e) => {
    props.onCustomOptionChange(e.target.value)
  }
  let displayOptions = props.option.values.concat(['その他']);
  return (
    <MDBContainer className="small m-2">
      <MDBRow className="fw-bold">
        <MDBCol>
          {props.label}
        </MDBCol>
      </MDBRow>
      {displayOptions.length > 1 &&
        <MDBRow>
          <MDBCol>
            <MDBTabs justify>
              {displayOptions.map((option, i) => {
                let tabDirection = '';
                if (displayOptions.length > 1) {
                  if (i == 0) {
                    tabDirection = 'left';
                  }
                  else if (i == displayOptions.length - 1) {
                    tabDirection = 'right';
                  }
                  else {
                    tabDirection = 'middle';
                  }
                }
                return (
                  <MDBTabsItem>
                    <MDBTabsLink className={`p-2 text-wrap d-flex text-center align-items-center justify-content-center ${tabDirection} ${i == props.option.selectedValue ? 'active' : ''}`}  style={{"height": "40pt"}} onClick={() => props.onOptionChange?.(i)}>
                      {option}
                    </MDBTabsLink>
                  </MDBTabsItem>
                );
            })}
            </MDBTabs>
          </MDBCol>
        </MDBRow>
      }
      {props.option.selectedValue == displayOptions.length - 1 &&
        <MDBRow className="mt-3 mx-3">
          <MDBCol>
            <MDBInput type="text" value={props.option.customValue} onChange={handleChange} label={`${props.label}`}/>
          </MDBCol>
        </MDBRow>
      }
    </MDBContainer>
  )
}

export default function CorrectionOptionDialog(props) {
  const { user } = useContext(AuthContext);

  const [tempStyle, setTempStyle] = useState(props.correctionStyle)
  const [tempType, setTempType] = useState(props.type)
  const [tempSituation, setTempSituation] = useState(props.situation)
  const [tempTo, setTempTo] = useState(props.to)
  const [tempAttitude, setTempAttitude] = useState(props.attitude)

  useEffect(() => { setTempStyle(props.correctionStyle)}, [props.correctionStyle] )
  useEffect(() => { setTempType(props.type)}, [props.type] )
  useEffect(() => { setTempSituation(props.situation)}, [props.situation] )
  useEffect(() => { setTempTo(props.to)}, [props.to] )
  useEffect(() => { setTempAttitude(props.attitude)}, [props.attitude] )

  function resetOptions() {
    setTempStyle(props.correctionStyle);
    setTempType(props.type);
    setTempSituation(props.situation);
    setTempTo(props.to);
    setTempAttitude(props.attitude);
  }

  const unrestrictedStyles = ["standard"]

  const dialogRef = useRef();

  function handleStyleChange(e) {
    const style = e.target.dataset.value;
    // if (!unrestrictedStyles.includes(style) && user == null) {
    //   dialogRef.current?.show({
    //     title: 'AIで英語添削',
    //     body: 'こちらのオプションで添削するには、無料のユーザ登録をしてログインしてください。',
    //     actions: [
    //       Dialog.Action(
    //         'キャンセル',
    //         (dialog) => dialog.hide(),
    //         'btn-secondary'
    //       ),
    //       Dialog.Action(
    //         'ログイン',
    //         async () => {
    //           eventBus.dispatch("login");
    //         },
    //         'btn-secondary'
    //       ),
    //       Dialog.DefaultAction(
    //         'ユーザ登録',
    //         async () => {
    //           eventBus.dispatch("signup");
    //         },
    //         'btn-success'
    //       )
    //     ],
    //     bsSize: 'small',
    //     onHide: (dialog) => {
    //       dialog.hide()
    //     }
    //   })
    // }
    // else {
      setTempStyle(style);
    // }
  }

  function getAdvancedOption(name, label, option, setOption) {
    return (tempStyle == 'advanced') &&
      <MDBRow className="m-3">
        <MDBCol>
          <AdvancedOption name={name} label={label}
            option={option}
            onOptionChange={(i) => {
              setOption({...option,
                "selectedValue": i
              });
            }}
            onCustomOptionChange={(value) => {
              setOption({...option,
                "customValue": value
              });
            }}/>
        </MDBCol>
      </MDBRow>
  }

  return (
    <Modal
      {...props}
      fullscreen="md-down" size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <div className="h6 d-flex align-items-center"><MDBIcon fas icon="cog" className="me-2"/>添削モード</div>
      </Modal.Header>
      <Modal.Body className="px-1 px-md-3">
        <MDBContainer className='my-3'>
          <MDBRow>
            <MDBCol>
              <MDBTabs justify fill>
                {
                  [
                    ['standard', 'AIにおまかせ'],
                    ['business', 'ビジネス'],
                    ['casual', 'カジュアル'],
                    ['advanced', 'カスタム'],
                  ].map(item => (
                    <MDBTabsItem>
                      <MDBTabsLink  id={item[0]} className={`d-flex text-center align-items-center justify-content-center px-0 ${tempStyle == item[0] ? 'active' : ''}`} style={{"height": "40pt"}} data-value={item[0]} onClick={handleStyleChange}>
                        {item[1]}
                      </MDBTabsLink>
                    </MDBTabsItem>
                  ))
                }
              </MDBTabs>
            </MDBCol>

          </MDBRow>
          { getAdvancedOption('type', '文書の種類', tempType, setTempType) }
          { getAdvancedOption('situation', 'シチュエーション', tempSituation, setTempSituation) }
          { getAdvancedOption('to', '相手', tempTo, setTempTo) }
          { getAdvancedOption('attitude', '口調', tempAttitude, setTempAttitude) }
          <div className="d-flex justify-content-end mt-5 mb-3">
            <MDBBtn className="green-button outline mx-3" onClick={() => {
              resetOptions();
              props.onHide?.();
            }}>キャンセル</MDBBtn>
            <MDBBtn size='lg' className="green-button" onClick={() => props.onOptionChanged?.(tempStyle, tempType)}>OK</MDBBtn>
          </div>
        </MDBContainer>
      </Modal.Body>
      <Dialog ref={dialogRef} />
    </Modal>
  )

}
