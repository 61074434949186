import React from "react";
import { getStatement } from './SampleData';
import { Row, Card, Stack } from 'react-bootstrap';
import parse from 'html-react-parser';

import {ReactComponent as DocumentIcon} from "../images/document.svg";
import Colors from "../constants/Colors"

export function OpinionQuestionSupplement(props){
  const question = props.question
  if (question.type != 'situational'){
    return null;
  }

  let statement_data = getStatement(question)

  const subject_title = statement_data['supplement_title'];
  const articles = question.passage.split('\n').map((article, i) => {
    return <p key={i}>{article}</p>
  })

  return (
    <>
      <Card.Header className="fw-bold">
        <div>
          <DocumentIcon className="me-2 align-middle" stroke={Colors.green}/>
          <span className="align-middle">{subject_title}</span>
        </div>
      </Card.Header>
      <Card.Body className="p-md-4 px-1 py-2">
        <div className="article ms-1">
          {articles}
        </div>
      </Card.Body>
    </>
  );
}

export function OpinionQuestion(props) {
  let points = [];
  if (props.question.keywords != null){
    points = props.question.keywords.map(
      (point, index) => {
        if (point == "") {
          return null;
        }
        else {
          return <li>{point}</li>
        }
      }
    ).filter(x => x != null);
  }
  let points_list = null
  if (points.length != 0) {
    points_list = <div className="mt-3">
      <div>POINTS</div>
      <ul>{points}</ul>
    </div>
  }
  const statementData = getStatement(props.question)
  return (
    <>
      <div>{statementData['subject_title']}</div>
      {props.question.exam_type == 'univ_ent_exam' && props.question.lang == 'JP'
        ?
        <div>{props.question.question_jp}</div>
        :
        <div>{props.question.question}</div>
      }
      {points_list}
    </>
  )
}
