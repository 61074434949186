import React, { createContext, useState } from "react"
import * as api from "../../api"
import {delay} from '../../retry'
import { PointConfirmationContext } from "../../point/PointConfirmationContext"
import { useApiWithPointUpdate } from "../../apiPointConsuming"
import Semaphore from '../../Semaphore'

const ChatContext = createContext()
const chatThrottler = new Semaphore(1);

function ChatProvider({ children }) {
  const [showChat, setShowChat] = useState(false);
  const [currentChat, setCurrentChat] = useState('');
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [threadid, setThreadid] = useState(null);
  const [speaking, setSpeaking] = useState(true);
  const { showConfirmationModal } = React.useContext(PointConfirmationContext);
  const { create_chat_wrapped } = useApiWithPointUpdate();

  function clearChatHistory() {
    setChats([]);
    setLoading(false);
    setSpeaking(false);
  }

  function needToCreate(){
    return !threadid;
  }

  async function createChat() {
    let tid = threadid;
    if (needToCreate()) {
      tid = await create_chat_wrapped();
      setThreadid(tid);
    }
    return tid;
  }

  async function startChat(message) {
    appendMessage('ai', 'えっと、ちょっと待ってください。', true);
    await sendChat(message);
  }

  async function sendChat(message) {
    chatThrottler.callFunction(_sendChat, message);
  }

  async function _sendChat(message) {
    setLoading(true)
    if (needToCreate()) {
      const confirmed = await showConfirmationModal('UGUIS先生')
      if (!confirmed) {
        setLoading(false);
        return;
      }
    }
    const tid = await createChat();
    let response = await api.chat(tid, message)
    setLoading(false)
    if (response) {
      appendMessage('ai', response);
    }
  }

  function appendMessage(role, message, sectionBeginning=false) {
    setChats(chats => {
      chats.push([role, message, sectionBeginning]);
      if (role == 'ai') {
        setSpeaking(true);
      }
      return chats;
    });
  }

  function submitChat(text) {
    appendMessage('user',  text)
    delay(async () => {
      sendChat(text);
    }, 750);
  }

  const values = {
    showChat, setShowChat,
    currentChat, setCurrentChat,
    chats, setChats,
    loading, setLoading,
    speaking, setSpeaking,
    startChat,
    submitChat,
  }

  return (
    <ChatContext.Provider value={values}>
      {children}
    </ChatContext.Provider>
  )
}

export { ChatProvider, ChatContext }
