import React, {
  Component,
  useState,
  useEffect,
  useRef,
  useCallback,
  forwardRef,
} from "react";

import ReactDOM from 'react-dom'

import {
  MDBIcon,
  MDBBadge,
  MDBBtn,
  MDBRow,
  MDBCol,
} from 'mdb-react-ui-kit';

import {
  createEditor,
  Editor,
  Transforms,
  Element,
  Node,
  Path,
  Point,
  Range,
} from 'slate'

import {
  Slate,
  useSlate,
  Editable,
  withReact,
  ReactEditor,
  useFocused
} from 'slate-react'

import { withHistory } from 'slate-history'

import { cx, css } from '@emotion/css'

import eventBus from "../EventBus";

import './UguisTextEditor.css'
import { isMobile } from './WindowDimensions';

// helper
const isEmptyNode = node => {
  if (node.text) {
    return (node.text.length === 0)
  }
  if (!node.children) {
    return true
  }
  return node.children.every(c => isEmptyNode(c))
}

const hasProperty = (node, propName) => {
  if (node[propName]) return true
  if (!node.children) {
    return false
  }
  return node.children.some(c => hasProperty(c, propName))
}



const detectNodeEntry = (editor, targetPath, predict) => {
  const targetNode = Node.get(editor, targetPath)
  if(predict(targetNode)) {
    //action(editor, targetNode, targetPath)
    return [targetNode, targetPath]
  }

  const parentPath = Path.parent(targetPath)
  if (parentPath.length == 0) return null
  return detectNodeEntry(editor, parentPath, predict)
}

const withCustomBehavior = editor => {
  const { isInline, isVoid, markableVoid, normalizeNode } = editor
  editor.isInline = element => {
    return isInline(element)
  }

  //editor.isVoid = element => {
  //  if (element.action && element.action == 'added') {
  //    return true
  //  }
  //  return isVoid(element)
  //}

  editor.markableVoid = element => {
    return markableVoid(element)
  }

  editor.normalizeNode = entry => {
    const [node, path] = entry

    // empty element を削除
    if (Element.isElement(node) && node.tag) {
      // placeholder 以外の empty は削除する
      if (isEmptyNode(node) && !hasProperty(node, 'uPlaceholder')) {
        Transforms.removeNodes(editor, {at: path})
        return
      }
    }

    // action は normalize 対象外
    //if (Element.isElement(node) && isEmptyNode(node)) {
    if (Element.isElement(node)) {
      const [match] = Editor.nodes(editor, {match: n => n.action})
      if (match) {
        const [matchNode, matchPath] = match
        if (matchNode.action === 'added') {
          console.log("action")
          return
        }
      }
    }

    //if (node.uPlaceholder) { return }

    normalizeNode(entry)
  }
  return editor
}

const withLineBreak = editor => {
  const { insertBreak, deleteBackward } = editor

  editor.insertBreak = () => {
    const { selection } = editor;
    if (!selection || !Range.isCollapsed(selection)) {
      return insertBreak()
    }

    const selectedNodePath = Path.parent(editor.selection.anchor.path)
    const selectedNode = Node.get(editor, selectedNodePath)


    let isAtTagStart = false
    let isAtTagEnd = false
    let isAtPlaceholderStart = false

    //-- check --
    // check tag first position
    const taggedNodeEntry = detectNodeEntry(editor,
                                            selectedNodePath,
                                            node => { return node['tag'] })
    if (taggedNodeEntry) {
      const [node, path] = taggedNodeEntry
      isAtTagStart = Editor.isStart(editor, selection.focus, path)
      isAtTagEnd = Editor.isEnd(editor, selection.focus, path)
    }

    const [placeholderMatch] = Editor.nodes(editor, {match: n => n.uPlaceholder})
    if (placeholderMatch) {
      const [node, path] = placeholderMatch
      isAtPlaceholderStart = Editor.isStart(editor, selection.focus, path)
    }

    // check action edge
    //const [actionNodeEntry] = Editor.nodes(editor, {match: n => n.action})
    //if (actionNodeEntry) {
    //  const [node, path] = actionNodeEntry
    //  isAtActionEdge = Editor.isEdge(editor, selection.focus, path)
    //}

    //console.log("tag start", isAtTagStart)
    //console.log("tag end", isAtTagEnd)
    //console.log("placeholder start", isAtPlaceholderStart)

    // 現在の選択されているノードの root を分割
    Transforms.splitNodes(editor, { always: true, match: n => n.isRoot });

    const prevNodeEntry = Editor.previous(editor)
    const nextNodeEntry = Editor.next(editor)

    // 前方に action を残した場合だけ、後方の action を削除する
    let needUnsetNextAction = false
    let needUnsetNextPlaceholder = false

    if (prevNodeEntry) {
      const [prevNode, prevPath] = prevNodeEntry

      // // tag
      // const taggedNodeEntry = detectNodeEntry(editor,
      //                                         prevPath,
      //                                         node => { return node['tag'] })
      // needUnsetNextTag = taggedNodeEntry ? true : false

      // action
      const actionNodeEntry = detectNodeEntry(editor,
                                              prevPath,
                                              node => { return node['action'] })
      if (actionNodeEntry) {
        const [actionNode, actionPath] = actionNodeEntry
        // 前方の action が空になる場合は、前方の action を削除
        if (actionNode.text.length == 0) {
          console.log("remove prev action")
          Transforms.unsetNodes(editor, 'action', {at: actionPath})
          Transforms.unsetNodes(editor, 'action_id', {at: actionPath})
          Transforms.unsetNodes(editor, 'from', {at: actionPath})
          Transforms.unsetNodes(editor, 'to', {at: actionPath})
        } else {
          needUnsetNextAction = true
        }
      }

      const placeholderNodeEntry = detectNodeEntry(editor,
                                                   prevPath,
                                                   node => { return node['uPlaceholder'] })
      needUnsetNextPlaceholder = placeholderNodeEntry ? true : false
      //console.log("prevPath", prevPath)
    }

    if (nextNodeEntry) {
      const [nextNode, nextPath] = nextNodeEntry

      // remove tag
      const taggedNodeEntry = detectNodeEntry(editor,
                                              nextPath,
                                              node => { return node['tag'] })
      // tag の途中で分割した時のみ、後方の tag を削除
      if (!isAtTagStart && !isAtTagEnd && taggedNodeEntry) {
        const [taggedNode, taggedPath] = taggedNodeEntry
        Transforms.unsetNodes(editor, 'tag', {at: taggedPath})
      }

      // remove action
      const actionNodeEntry = detectNodeEntry(editor,
                                              nextPath,
                                              node => { return node['action'] })
      if (needUnsetNextAction && actionNodeEntry) {
        const [actionNode, actionPath] = actionNodeEntry
        Transforms.unsetNodes(editor, 'action', {at: actionPath})
        Transforms.unsetNodes(editor, 'action_id', {at: actionPath})
        Transforms.unsetNodes(editor, 'from', {at: actionPath})
        Transforms.unsetNodes(editor, 'to', {at: actionPath})
      }

      // remove placeholder
      //const placeholderNodeEntry = detectNodeEntry(editor,
      //                                             nextPath,
      //                                             node => { return node['uPlaceholder'] })
      //if (needUnsetNextPlaceholder && placeholderNodeEntry) {
      //  const [placeholderNode, placeholderPath] = placeholderNodeEntry
      //  Transforms.unsetNodes(editor, 'uPlaceholder', {at: placeholderPath})
      //}
      // TODO: 上と何が違うのか不明
      Transforms.unsetNodes(editor, 'uPlaceholder', {match: n => n.uPlaceholder})

      //Transforms.unsetNodes(editor, 'uPlaceholder', {at: nextPath})
    }
  }

  editor.deleteBackward = unit => {
    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      // 現在の場所が tag の先頭なら削除
      const [match] = Editor.nodes(editor, {match: n => n.tag})
      if (match) {
        const [node, path] = match
        const start = Editor.start(editor, path)

        if (selection.anchor.path.toString() === start.path.toString() &&
            selection.anchor.offset === 0) {

          Transforms.unsetNodes(
            editor,
            'tag',
            { at: path }
          )
        }
      }
    }
    deleteBackward(unit)
  }
  return editor
}

const UguisTextEditor = (props, ref) => {
  const [selectedCard, setSelectedCard] = useState(null)
  const [hoveredCard, setHoveredCard] = useState(null)
  const [changed, setChanged] = useState(false)
  const [editor] = useState(() => withLineBreak(withCustomBehavior(withReact(withHistory(createEditor())))))

  const renderElement = useCallback(props => <CustomElement {...props} />, [selectedCard, hoveredCard])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])

  useEffect(() => {
    window.addEventListener("beforeunload", onUnload);
    eventBus.on("selectCard", (data) =>
      {
        setSelectedCard(data)
        scrollEditorIntoView(data)
      }
    );
    eventBus.on("hoverCard", (data) =>
      {
        setHoveredCard(data)
        scrollEditorIntoView(data)
    }
    );
    eventBus.on("unhoverCard", (data) =>
      {
        setHoveredCard(null)
      }
    );

    return () => {
      window.removeEventListener("beforeunload", onUnload);
      eventBus.remove("selectCard");
      eventBus.remove("hoverCard");
      eventBus.remove("unhoverCard");
    }
  }, [])

  // 管理のため action, uPlaceholder は text と同じ階層の leaf に配置
  const Leaf = ({attributes, children, leaf}) => {
    const onBlock = e => {
      e.stopPropagation()
    }
    if (leaf.action != undefined) {
      return (
        <span {...attributes} onClick={onBlock}>
          <UguisEditableSpan key={`editable-span-${leaf.id}`}
                             leaf={leaf}
                             action={leaf.action}
                             to={leaf.to}
                             onCorrect={handleInputChange}>
            {children}
          </UguisEditableSpan>
        </span>
      )
    }

    // uPlaceholder
    if (leaf.uPlaceholder) {
      const ctx = document.createElement('canvas').getContext('2d')
      ctx.font = ''
      const placeholderWidth = ctx.measureText(leaf.uPlaceholder).width * 1.4 + 20

      return (
        <span {...attributes} className='texteditor essay missing text-dark u-placeholder'
              style={{
                minWidth: isEmptyNode(leaf) ? placeholderWidth : '180px',
                display: isEmptyNode(leaf) ? 'inline-block' : 'inline-block'
              }}
              suppressContentEditableWarning={true}>
          {children}
          {
            isEmptyNode(leaf) && (
              <span contentEditable={false} className="small"
                    style={{ userSelect: 'none',
                             display: 'inline-block',
                             top: 0,
                             pointerEvents: 'none',
                             opacity: 0.3, position: 'absolute',
                             overflow: 'hidden',
                             whiteSpace: 'nowrap',
                             textOverflow: 'ellipsis'}}
              >
                {leaf.uPlaceholder}
              </span>
            )
          }
        </span>
      )
    }

    return (
      <span {...attributes}
        className='u-leaf'
      >
        {children}
      </span>
    )
  }

  const _findNodesWithProperty = (editor, name, value) => {
    for (const [node, path] of Node.nodes(editor)) {
      if (node[name] === value) {
        return path
      }
    }
    return null
  }

  const UguisEditorPortal = ({ children }) => {
    return typeof document === 'object'
      ? ReactDOM.createPortal(children, document.body)
      : null
  }

  const UguisEditorMenu = React.forwardRef(({ className, ...props}, ref) => (
    <div
      {...props}
      data-test-id="menu"
      ref={ref}
      className={cx(
        className,
        css`
          & > * {
            display: inline-block;
          }
        `
      )}
    />
  ))

  const UguisEditorPopover = () => {
    const ref = useRef()
    const editor = useSlate()
    const inFocus = useFocused()

    let action = null
    let action_id = null
    let to = null
    let from = null

    useEffect(() => {
      const el = ref.current
      const { selection } = editor
      if (!el || !selection) {
        return
      }

      const selectedNode = Node.get(editor, selection.anchor.path)

      if (
        !selection ||
        !inFocus ||
        !selectedNode.action
      ) {
        el.removeAttribute('style')
        action = null
        action_id = null
        to = null
        from = null
        return
      }

      action = selectedNode.action
      action_id = selectedNode.action_id
      to = selectedNode.to
      from = selectedNode.from

      //const domSelection = window.getSelection()
      //const domRange = domSelection.getRangeAt(0)
      //const rect = domRange.getBoundingClientRect()
      const domNode = ReactEditor.toDOMNode(editor, selectedNode)
      const rect = domNode.getBoundingClientRect()
      el.style.opacity = '1'
      el.style.top = `${rect.bottom + window.pageYOffset - el.offsetHeight - 32}px`
      el.style.left = `${rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2}px`
    })

    const handleAccept = (e) => {
      e.preventDefault()
      const path = _findNodesWithProperty(editor, "action_id", action_id)

      if (action == "added") {
        Transforms.insertText(editor, to, {at: path})
        Transforms.setNodes(editor, {action: "", to: "",from: ""}, {at: path})
      } else if (action == 'removed') {
        Transforms.setNodes(editor, {action: "", to: "",from: ""}, {at: path})
        Transforms.insertText(editor, to, {at: path})
      } else if (action == "replaced") {
        Transforms.insertText(editor, to, {at: path})
        Transforms.setNodes(editor, {action: "", to: "",from: ""}, {at: path})
      }
      if (props.onCorrect) {
        props.onCorrect()
      }
    }

    const handleReject = (e) => {
      e.preventDefault()
      const path = _findNodesWithProperty(editor, "action_id", action_id)

      if (action == "added") {
        Transforms.setNodes(editor, {action: "", to: "",from: ""}, {at: path})
      } else if (action == 'removed') {
        Transforms.setNodes(editor, {action: "", to: "",from: ""}, {at: path})
      } else if (action == "replaced") {
        Transforms.setNodes(editor, {action: "", to: "",from: ""}, {at: path})
      }
      if (props.onCorrect) {
        props.onCorrect()
      }
    }

    return (
      <UguisEditorPortal>
        <UguisEditorMenu
          ref={ref}
          className='uguis-editor-menu'
          onMouseDown={e => {
            e.preventDefault()
          }}
        >
          <MDBBtn color="success" className="me-2" rounded onClick={handleAccept}>修正</MDBBtn>
          <MDBBtn color="danger" rounded onClick={handleReject}>却下</MDBBtn>
        </UguisEditorMenu>
      </UguisEditorPortal>
    )
  }

  const UguisEditableSpan = props => {
    const { attributes, leaf, children } = props
    const editor = useSlate()

    const element = leaf

    const handleAccept = (e) => {
      e.preventDefault()
      const path = _findNodesWithProperty(editor, "action_id", leaf.action_id)

      if (element.action == "added") {
        Transforms.insertText(editor, element.to, {at: path})
        Transforms.setNodes(editor, {action: "", to: "",from: ""}, {at: path})
      } else if (element.action == 'removed') {
        Transforms.setNodes(editor, {action: "", to: "",from: ""}, {at: path})
      } else if (element.action == "replaced") {
        Transforms.insertText(editor, element.to, {at: path})
        Transforms.setNodes(editor, {action: "", to: "",from: ""}, {at: path})
      }
      if (props.onCorrect) {
        props.onCorrect()
      }
    }

    const handleReject = (e) => {
      e.preventDefault()
      const path = _findNodesWithProperty(editor, "action_id", leaf.action_id)

      if (element.action == "added") {
        Transforms.setNodes(editor, {action: "", to: "",from: ""}, {at: path})
      } else if (element.action == 'removed') {
        Transforms.setNodes(editor, {action: "", to: "",from: ""}, {at: path})
      } else if (element.action == "replaced") {
        Transforms.setNodes(editor, {action: "", to: "",from: ""}, {at: path})
      }
      if (props.onCorrect) {
        props.onCorrect()
      }
    }

    if (element.action == 'added') {
      return (
        <ruby {...attributes}>
          <MDBIcon fas icon="angle-down mx-2 text-success"/>
          <span suppressContentEditableWarning={true}
                className="texteditor essay">
            {children}
          </span>
          <rt>
            <span contentEditable={false}
                  className="u-editable texteditor noessay text-success">{element.to}</span>
          </rt>
        </ruby>
      )
    } else if (element.action == 'removed') {
      return (
        <span {...attributes}
              className="texteditor essay text-danger"
              style={{'textDecoration':'line-through'}}>{children}</span>
      )
    } else if (element.action == 'replaced') {
      return (
        <span {...attributes}>
        <ruby>
          <span color={'color-danger'} style={{textDecoration: 'line-through'}}>
            {children}
          </span>
          <rt contentEditable={false}>
            <span contentEditable={false}
                  className="u-editable noessay text-success">{element.to}</span>
          </rt>
        </ruby>
        </span>
      )
    } else {
      return <span {...attributes}>{children}</span>
    }
  }

  const UguisTagSpan = ({attributes, text, color}) => {
    if (text === null) {
      return <span></span>
    }
    return (
      <MDBBadge {...attributes} color={color}
                light className="u-tag h5 noessay unselectable"
                contentEditable={false}>{text}</MDBBadge>
    )
  }

  const UguisSentence = props => {
    const { attributes, children, element, selectedCard, hoveredCard } = props

    // getSentenceView の実装
    let _clickable = ''
    let _important = ''
    let _sentenceOnClick = null
    let _sentenceOnMouseOver = null
    let _sentenceOnMouseOut = null
    let _selectedStyle = isSentence(selectedCard, element.id) ? 'selected' : ''
    let _hoverStyle = isSentence(hoveredCard, element.id) ? 'hovered' : ''

    if (element.id != undefined) {
      if (element.hasError) {
        if (element.important) {
          _important = 'important'
        }
        _clickable = 'clickable'
        _sentenceOnClick = sentenceOnClick
        _sentenceOnMouseOver = sentenceOnMouseOver
        _sentenceOnMouseOut = sentenceOnMouseOut
      }
    }

    return (
      <>
        <span id={`sentence-${element.id}`}
              key={`sentence-${element.id}`}
              className={`u-sentence ${_important} ${_clickable} ${_selectedStyle} ${_hoverStyle}`}
              onClick={_sentenceOnClick}
              onMouseOver={_sentenceOnMouseOver}
              onMouseOut={_sentenceOnMouseOut}
              sentence={element.id}>
          {children}
        </span>
      </>
    )
  }

  const CustomElement = props => {
    const { attributes, children, element } = props

    if (element.isRoot) {
      return (<span {...attributes} style={{display: 'block'}}>{children}</span>)
    }
    // tag
    if (element.tag) {
      let _clickable = ''
      let _tagOnClick = null
      let _tagOnMouseOver = null
      let _tagOnMouseOut = null
      let _selectedStyle = isTagSelected(element.tag.id) ? 'selected' : ''
      let _hoverStyle = isTagHovered(element.tag.id) ? 'hovered' : ''
      if (element.hasError) {
        _clickable = 'clickable important'
        _tagOnClick = tagOnClick
        _tagOnMouseOver = tagOnMouseOver
        _tagOnMouseOut = tagOnMouseOut
      }

      return (
        <span {...attributes}
              id={`text-group-${element.tag.id}`}
              key={`text-group-${element.tag.id}`}
              className={`u-group ${_clickable} ${_selectedStyle} ${_hoverStyle}`}
              suppressContentEditableWarning={true}
              onClick={_tagOnClick}
              onMouseOver={_tagOnMouseOver}
              onMouseOut={_tagOnMouseOut}
              tag={element.tag.id}
              style={{
                position:'relative',
                lineHeight: '3.5em',
              }}>
          <ruby style={{display: 'inline-block'}}>
            <rt><UguisTagSpan text={element.tag.text} color={element.tag.color}/></rt>
            {children}
          </ruby>
        </span>
      )
    }

    // sentence
    return (
             <UguisSentence {...attributes}
                            element={element}
                            style={{ lineHeight: '3.5em' }}
                            selectedCard={selectedCard}
                            hoveredCard={hoveredCard}>
               {children}
             </UguisSentence>
           )
  }

  const tagOnClick = (e) => {
    e.preventDefault();
    const tag = e.currentTarget.getAttribute('tag')
    const card = { type: 'tag', id: tag }
    eventBus.storage.selectedCard = card
    eventBus.dispatch("selectEditor", card);
    setSelectedCard(card)
  }

  const tagOnMouseOver = (e) => {
    e.preventDefault();
    const tag = e.currentTarget.getAttribute('tag')
    eventBus.dispatch("hoverEditor", { type: 'tag', id: tag });
  }

  const tagOnMouseOut = (e) => {
    e.preventDefault();
    const tag = e.currentTarget.getAttribute('tag')
    eventBus.dispatch("unhoverEditor", { type: 'tag', id: tag });
  }

  const sentenceOnClick = (e) => {
    e.preventDefault();
    const sentenceId = e.currentTarget.getAttribute('sentence')
    const card = { type: 'sentence', id: sentenceId }
    eventBus.storage.selectedCard = card
    eventBus.dispatch("selectEditor", card);
    setSelectedCard(card)
  }

  const sentenceOnMouseOver = (e) => {
    e.preventDefault();
    const sentenceId = e.currentTarget.getAttribute('sentence')
    eventBus.dispatch("hoverEditor", { type: 'sentence', id: sentenceId });
  }

  const sentenceOnMouseOut = (e) => {
    e.preventDefault();
    const sentenceId = e.currentTarget.getAttribute('sentence')
    eventBus.dispatch("unhoverEditor", { type: 'sentence', id: sentenceId });
  }

  const onUnload = (e) => {
    if (changed) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  const handleInputChange = () => {
    //setChanged(true)
    //if (props.onEssayChange) {
    //  props.onEssayChange(true)
    //}
  }

  const scrollEditorIntoView = (card) => {
    let element;
    if (card.type == 'sentence') {
      element = document.getElementById(`sentence-${card.id}`);
    }
    else if (card.type == 'tag') {
      element = document.getElementById(`text-group-${card.id}`);
    }
    if (element) {
      if (isMobile()) {
        // scroll to the center so mobile feedback cards will not cover the highlighted text
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center'});
      }
      else {
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start'});
      }
    }
  }

  const isTag = (card, tag) => {
    return card != null && card.type == 'tag' && card.id == tag
  }

  const isTagSelected = (tag) => {
    return isTag(selectedCard, tag)
  }

  const isTagHovered = (tag) => {
    return isTag(hoveredCard, tag)
  }

  const isSentence = (card, sentence) => {
    return card != null && card.type == 'sentence' && card.id == sentence
  }

  // 削除候補
  //const isSentenceSelected = (selectedCard, sentence) => {
  //  return isSentence(selectedCard, sentence)
  //}

  //const isSentenceHovered = (hoveredCard, sentence) => {
  //  return isSentence(hoveredCard, sentence)
  //}

  const traverseElement = (element, filter, func) => {
    if (!filter(element)) {
      return
    }
    func(element)
    element.childNodes?.forEach(node => traverseElement(node, filter, func))
  }

  const handleChange = value => {
    const isAstChange = editor.operations.some(op => 'set_selection' !== op.type)
    if (isAstChange) {
      setChanged(true)
      //const content = JSON.stringify(value, null, "  ")
      props.onDataChanged?.(value)
    }
  }

  const handleCopy = e => {
    e.preventDefault()
    const { selection } = editor
    const selectedText = Editor.string(editor, selection)
    e.clipboardData.setData('text/plain', selectedText)
  }

  return (
    <span>
    <Slate editor={editor}
           initialValue={props.data}
           onChange={handleChange}>
      <UguisEditorPopover />
      <Editable className='texteditor u-editor'
                renderLeaf={renderLeaf}
                renderElement={renderElement}
                suppressContentEditableWarning={true}  // debug
                placeholder={props.placeholder}
                style={{minHeight: '100px', IMEMode: "inactive"}}
                spellCheck={false}
                inputMode={props.keyboardOff == true ? 'none' : 'text'}
                readOnly={props.readOnly}
                onCopy={handleCopy}
                onInput={handleInputChange} />
    </Slate>

    {/*
    <div>[draft]</div>
    <pre style={{textWrap: 'initial', wordBreak: 'break-all'}}>{JSON.stringify(draftData, null, 2)}</pre>
    <hr />
    <div>[feedback]</div>
    <pre style={{textWrap: 'initial', wordBreak: 'break-all'}}>{JSON.stringify(feedback, null, 2)}</pre>
    <hr />
    <div>[groupedSentences]</div>
    <pre style={{textWrap: 'initial', wordBreak: 'break-all'}}>{JSON.stringify(groupedSentences, null, 2)}</pre>
    */}
    </span>
  );
}

export default forwardRef(UguisTextEditor)
