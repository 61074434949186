import React, {
  useState,
  useContext,
  useEffect,
} from 'react'

import {
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

import { Stack } from 'react-bootstrap'

import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from 'mdb-react-ui-kit';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'

import { AuthContext } from "../Auth/AuthContext"
import EikenLogo from "../EikenLogo"
import * as api from '../api'
import * as concourse from '../services/concourse'

const ItemBlock = (props) => {
  return (
    <div>
      <div className="h6 text-center">{props.title}</div>
      <div className="fw-bold h2 text-center">{props.value}</div>
    </div>
  )
}

const toYYYYMMDD = (dateString) => {
  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}.${month}.${day}`
}

const toGradeName = (grade) => {
  switch (grade) {
    case 'G10': return '1級'
    case 'G15': return '準1級'
    case 'G20': return '2級'
    case 'G25': return '準2級'
    case 'G30': return '3級'
    default:
      break
  }
  return null
}

const toQuestionTypeName = (type) => {
  switch (type) {
    case 'opinion': return "論述"
    case 'email':   return "Eメール"
    case 'summary': return "要約"
  }
  return null
}

const toQuestionFormatName = (grade, question_type) => {
  const gradeName = toGradeName(grade)
  const typeName = toQuestionTypeName(question_type)
  if (!gradeName || !typeName) return '-'
  return gradeName + typeName
}

const columnHelper = createColumnHelper()
const columns = [
  columnHelper.accessor('question_format_name', {
    header: () => '形式',
    cell: info => info.getValue(),
  }),
  columnHelper.accessor('title', {
    header: () => '問題',
    cell: info => info.getValue(),
  }),
  columnHelper.accessor('revisions.length', {
    header: () => '練習数',
    cell: info => info.getValue(),
  }),
  columnHelper.accessor('score', {
    header: () => '総合',
    cell: info => info.getValue(),
  }),
  columnHelper.accessor('vocabulary_score', {
    header: () => '語彙',
    cell: info => info.getValue(),
  }),
  columnHelper.accessor('grammar_score', {
    header: () => '文法',
    cell: info => info.getValue(),
  }),
  columnHelper.accessor('content_score', {
    header: () => '内容',
    cell: info => info.getValue(),
  }),
  columnHelper.accessor('coherence_score', {
    header: () => '構成',
    cell: info => info.getValue(),
  }),
  columnHelper.accessor('modified_at', {
    header: () => '学習日',
    cell: info => info.getValue(),
  }),
]

const History = (props) => {
  const navigate = useNavigate()
  const { user, tenantId, isLoading, setShowNavBar } = useContext(AuthContext)

  const [ assignments, setAssignments ] = useState([])
  const [ numRevisions, setNumRevisions ] = useState(0)
  const [ lastModified, setLastModified] = useState(0)

  const table = useReactTable({
    data: assignments,
    columns,
    getCoreRowModel: getCoreRowModel(),
   })

  const getUserAssignments = async () => {
    if (user) {
      const targetUserId = props.userId ? props.userId : user.userId
      const assignments = await api.getUserAssignments(tenantId, targetUserId)
      const _numRevisions = assignments.reduce((accm, cur) => { return accm + cur.revisions.length }, 0)
      const _lastModified = assignments.length > 0 ? toYYYYMMDD(assignments[0].modified_at) : "--"

      const converted_assignments = assignments.map(assignment => ({
        ...assignment,
        question_format_name: toQuestionFormatName(assignment.grade, assignment.type),
        modified_at: toYYYYMMDD(assignment.modified_at),
      }))

      setAssignments(converted_assignments)
      setNumRevisions(_numRevisions)
      setLastModified(_lastModified)
    }
  }

  useEffect(() => {
    getUserAssignments()
  }, [user, props.userId])

  const handleSelectAssignment = (e, assignment) => {
    let queryParams = new URLSearchParams()
    if (props.userId) {
      queryParams.append('user_id', props.userId)
    }
    queryParams.append('id', assignment.id)
    if (assignment.revisions.length > 0) {
      queryParams.append('revision_id', assignment.revisions.slice(-1)[0].id)
    }
    navigate({
      pathname: '/english',
      search: queryParams.toString()
    })
  }

  return (
    <div>
      {/* nav(grade) TBD */}

      {/* sumamry */}
      <Stack direction="horizontal" gap={3} style={{border: 'solid black 1px'}}>
        <div className="p-3 m-2" style={{backgroundColor: 'var(--light-green)'}}>
          <span className="fw-bold h3">英検受験</span>
          <div className='fw-bold h3 text-center m-0'>3級要約</div>
        </div> 
        <ItemBlock title="問題数" value={assignments.length} />
        <ItemBlock title="練習数" value={numRevisions} />
        <div>
          <div className="text-center">最終学習日</div>
          <div className="fw-bold h4 text-center">{lastModified}</div>
        </div>
      </Stack>

      {/* history */}
      <MDBTable small borderless hover>
        <MDBTableHead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </MDBTableHead>
        <MDBTableBody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id} onClick={(e) => handleSelectAssignment(e, row.original)}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>

    </div>
  )
}

export default History
