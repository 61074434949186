import React from "react";
import { getStatement } from './SampleData';
import { Card, Stack } from 'react-bootstrap';
import { MDBIcon, MDBBadge } from 'mdb-react-ui-kit';
import parse from 'html-react-parser';
import {OpinionQuestion, OpinionQuestionSupplement} from './OpinionQuestion';
import {EMailQuestion, EMailQuestionSupplement} from './EMailQuestion';
import {SummaryQuestion, SummaryQuestionSupplement} from './SummaryQuestion';

import {ReactComponent as QuestionIcon} from "../images/question.svg";
import Colors from '../constants/Colors'

// const maxLengths = {
//   "G15": {"opinion": 1050},
//   "G20": {"opnion": 700},
//   "G25": {"opnion": 420},
//   "G30": {"opnion": 245},
//   "G30": {"email": 245},
// }
// maxLengths is not taking effect for now

export function QuestionSupplementCard(props) {
  let type_specific_supplement_info = null;
  if (props.question.type == 'opinion' || props.question.type == 'simple' || props.question.type == 'situational') {
    type_specific_supplement_info = <OpinionQuestionSupplement question={props.question} />
  }
  else if (props.question.type == 'email') {
    type_specific_supplement_info = <EMailQuestionSupplement question={props.question} />
  }
  else if (props.question.type =='summary') {
    type_specific_supplement_info = <SummaryQuestionSupplement question={props.question} />
  }
  else {
    throw new Error(`unsupported type: ${props.question.type}`);
  }
  if (type_specific_supplement_info == null){
    return null;
  }
  return (
    <Card className='px-md-0'>
      {type_specific_supplement_info}
    </Card>
  )
}

export function QuestionCard(props) {
  const statementData = getStatement(props.question)
  let instruction_list = statementData['statement'].map((statement, i) => {
    return <li key={i}>{parse(statement)}</li>
  })

  let type_specific_info = null;
  if (props.question.type == 'opinion' || props.question.type == 'simple' || props.question.type == 'situational') {
    type_specific_info = <OpinionQuestion question={props.question} />
  }
  else if (props.question.type == 'email') {
    type_specific_info = <EMailQuestion question={props.question} />
  }
  else if (props.question.type == 'summary') {
    type_specific_info = <SummaryQuestion question={props.question} />
  }
  else {
    throw new Error(`unsupported type: ${props.question.type}`);
  }

  return (
    <Card className='px-md-0'>
      <Card.Header className="fw-bold">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <QuestionIcon fill={Colors.green} className="me-2"/>
            <span className="align-middle fw-bold">設問</span>
          </div>
          <div className='ms-auto'>
            <MDBBadge color='light' size='sm' className='supposed-time-badge'>
              解答時間の目安：{statementData['supposed_min']}分
            </MDBBadge >
          </div>
        </div>
      </Card.Header>
      <Card.Body className="p-md-4 px-1 py-3 lh-lg">
        <div>
          <div>
            <ul>
              {instruction_list}
            </ul>
          </div>
          {type_specific_info}
        </div>
      </Card.Body>
    </Card>
  )
}
