import statement from './config/Statement.json'
import assignments from './config/SampleAssignments.json'

var Statement = statement;
var SampleAssignments = assignments;

export function getStatement(question){
  const exam_type = question.exam_type ?? 'eiken';
  if (exam_type == 'eiken'){
    return statement[exam_type][question.grade][question.type]
  }
  else if (exam_type == 'univ_ent_exam'){
    return statement[exam_type][question.grade][question.type][question.lang][question.ansType]
  }
  else {
    throw new Error(`Invalid exam type: ${exam_type}`)
  }
}

export {
  Statement,
  SampleAssignments,
}
