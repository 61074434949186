var SubtaskData = {
  'G15': {
    'summary': {
      'config': {
        'A': {
          's0': { 'text': '英文の主題（テーマ）', 'color': 'success' },
          's1': { 'text': '主題に対する賛成意見・根拠', 'color': 'success' },
          's2': { 'text': '主題に対する1つ目の反対意見・根拠', 'color': 'success' },
          's3': { 'text': '主題に対する2つ目の反対意見・根拠', 'color': 'success' },
          'missing_s0': { 'text': '英文の主題（テーマ）', 'color': 'danger' },
          'missing_s1': { 'text': '主題に対する賛成意見・根拠', 'color': 'danger' },
          'missing_s2': { 'text': '主題に対する1つ目の反対意見・根拠', 'color': 'danger' },
          'missing_s3': { 'text': '主題に対する2つ目の反対意見・根拠', 'color': 'danger' },
        },
        'B': {
          's0': { 'text': '英文の主題（テーマ）', 'color': 'success' },
          's1': { 'text': '主題に対する1つ目の賛成意見・根拠', 'color': 'success' },
          's2': { 'text': '主題に対する2つ目の賛成意見・根拠', 'color': 'success' },
          's3': { 'text': '主題に対する反対意見・根拠', 'color': 'success' },
          'missing_s0': { 'text': '英文の主題（テーマ）', 'color': 'danger' },
          'missing_s1': { 'text': '主題に対する1つ目の賛成意見・根拠', 'color': 'danger' },
          'missing_s2': { 'text': '主題に対する2つ目の賛成意見・根拠', 'color': 'danger' },
          'missing_s3': { 'text': '主題に対する反対意見・根拠', 'color': 'danger' },
        },
      },
      'placeholder': {
        'A': {
          'missing_s0': '英文の主題（テーマ）がありません',
          'missing_s1': '主題に対する賛成意見・根拠がありません',
          'missing_s2': '主題に対する1つ目の反対意見・根拠がありません',
          'missing_s3': '主題に対する2つ目の反対意見・根拠がありません',
        },
        'B': {
          'missing_s0': '英文の主題（テーマ）がありません',
          'missing_s1': '主題に対する1つ目の賛成意見・根拠がありません',
          'missing_s2': '主題に対する2つ目の賛成意見・根拠がありません',
          'missing_s3': '主題に対する反対意見・根拠がありません',
        },
      },
      'feedback_templates': {
        'content': {
          'A': [
            [
              's0',
              '英文の主題（テーマ）の要約が不十分です。',
              '英文の主題（テーマ）の要約が不十分です。',
            ],
            [
              's1',
              '主題に対する賛成意見・根拠の要約が不十分です。',
              '主題に対する賛成意見・根拠の要約が不十分です。',
            ],
            [
              's2',
              '主題に対する1つ目の反対意見・根拠の要約が不十分です。',
              '主題に対する1つ目の反対意見・根拠の要約が不十分です。',
            ],
            [
              's3',
              '主題に対する2つ目の反対意見・根拠の要約が不十分です。',
              '主題に対する2つ目の反対意見・根拠の要約が不十分です。',
            ],
          ],
          'B': [
            [
              's0',
              '英文の主題（テーマ）の要約が不十分です。',
              '英文の主題（テーマ）の要約が不十分です。'
            ],
            [
              's1',
              '主題に対する1つ目の賛成意見・根拠の要約が不十分です。',
              '主題に対する1つ目の賛成意見・根拠の要約が不十分です。'
            ],
            [
              's2',
              '主題に対する2つ目の賛成意見・根拠の要約が不十分です。',
              '主題に対する2つ目の賛成意見・根拠の要約が不十分です。'
            ],
            [
              's3',
              '主題に対する反対意見・根拠の要約が不十分です。',
              '主題に対する反対意見・根拠の要約が不十分です。'
            ],
          ]
        }
      }
    }
  },
  'G20': {
    'summary': {
      'config': {
        "s0": { "text": "英文の主題（テーマ）", "color": "success" },
        "s1": { "text": "主題の利点", "color": "success" },
        "s2": { "text": "主題の欠点", "color": "success" },
        "missing_s0": {"text":"英文の主題（テーマ）", "color":"danger"},
        "missing_s1": {"text":"主題の利点", "color":"danger"},
        "missing_s2": {"text":"主題の欠点", "color":"danger"},
      },
      'placeholder': {
        'missing_s0': '英文の主題（テーマ）がありません',
        'missing_s1': '主題の利点がありません',
        'missing_s2': '主題の欠点がありません',
      },
      'feedback_templates': {
        'content': [
          [
            's0',
            '英文の主題（テーマ）の要約が不十分です。',
            '英文の主題（テーマ）の要約が不十分です。'],
          [
            's1',
            '主題の利点の要約が不十分です。',
            '主題の利点の要約が不十分です。'
          ],
          [
            's2',
            '主題の欠点の要約が不十分です。',
            '主題の欠点の要約が不十分です。'
          ],
        ]
      }
    }
  },
  'G25': {
    'email': {
      'config': {
        "s0": { "text": "質問への解答", "color": "success" },
        "s1": { "text": "質問への解答の理由", "color": "success" },
        "s2": { "text": "１つ目の追加の質問", "color": "success" },
        "s3": { "text": "２つ目の追加の質問", "color": "success" },
        "missing_s0": {"text":"質問への解答", "color":"danger"},
        "missing_s1": {"text":"質問への解答の理由", "color":"danger"},
        "missing_s2": {"text":"１つ目の追加の質問", "color":"danger"},
        "missing_s3": {"text":"２つ目の追加の質問", "color":"danger"},
      },
      'placeholder': {
        'missing_s0': '質問への解答がありません',
        'missing_s1': '質問への解答の理由がありません',
        'missing_s2': '１つ目の追加の質問がありません',
        'missing_s3': '２つ目の追加の質問がありません',
      },
      'feedback_templates': {
        'content': [
          ['s0', '質問に解答できていません。', '質問への解答が不十分です。'],
          ['s1', '質問への解答の理由を説明できていません', '質問への解答の理由が不十分です。'],
          ['s2', '1つ目の追加の質問をできていません。', '1つ目の追加の質問が不十分です。'],
          ['s3', '2つ目の追加の質問をできていません。', '2つ目の追加の質問が不十分です。'],
        ]
      }
    }
  },
  'G30': {
    'email': {
      'config': {
        "s0": { "text": "１つ目の質問への解答", "color": "success" },
        "s1": { "text": "２つ目の質問への解答", "color": "success" },
        "missing_s0": {"text":"１つ目の質問への解答", "color":"danger"},
        "missing_s1": {"text":"２つ目の質問への解答", "color":"danger"},
      },
      'placeholder': {
        'missing_s0': '１つ目の質問に答えていません。',
        'missing_s1': '２つ目の質問に答えていません。',
      },
      'feedback_templates': {
        'content': [
          ['s0', '1つ目の質問に答えていません。', '質問の解答が不十分です。'],
          ['s1', '2つ目の質問に答えていません。', '質問の解答が不十分です。']
        ]
      }
    }
  }
}

export {
  SubtaskData
}
