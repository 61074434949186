import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';

export default function PointRefillModal(props) {
  const handleClose = () => {
    props.setShow(false)
    props.setRefillReason(null);
  };

  if (!props.refillReason){
    return null;
  }
  let refillReasonBody = null;
  if (props.refillReason === 'daily') {
    refillReasonBody = <>
      今日もログインありがとうございます
      <br/>
      3ポイントまで補充しました
    </>
  }
  else if (props.refillReason === 'initial') {
    refillReasonBody = <>
      登録ありがとうございます！
      <br/>
      10ポイント補充しました
    </>
  }
  else{
    throw new Error(`Invalid refill reason: ${props.refillReason}`);
  }
  return (
    <MDBModal show={props.show} setShow={props.setShow}>
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalBody>
            {refillReasonBody}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={() => handleClose()}>
              閉じる
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  )
}