import React from "react";
import {Container, Row, Col} from 'react-bootstrap';
import {
  MDBRow, MDBCol, MDBBadge,
  MDBIcon, MDBCard, MDBCardBody, MDBCardFooter, MDBBtn, MDBRipple
} from 'mdb-react-ui-kit';
import UguisSensei from './UguisSensei'

export default function FeedbackCard(props) {
  return (
    <MDBCard id={`feedbackcard-${props.id}`} key={`feedbackcard-${props.id}`} className={`feedback ${props.selected ? "selected" : "unselected"} ${props.hovered ? "hovered" : ""} mb-3`} feedbackid={props.id} onClick={props.onClick} onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut}>
      <MDBRipple role='button' rippleColor='#6dd6c2'>
        <MDBCardBody className="pb-0">
          <Container className="feedback-card p-0 small">
            <div className="mb-2 h5">
              {props.isVocabulary &&
                <MDBBadge className='me-2 badge-vocabulary'>語彙</MDBBadge>
              }
              {props.isGrammar &&
                <MDBBadge className='me-2 badge-grammar'>文法</MDBBadge>
              }
              {props.isContent &&
                <MDBBadge className='me-2 badge-content'>内容</MDBBadge>
              }
              {props.isCoherence &&
                <MDBBadge className='badge-coherence'>構成</MDBBadge>
              }
            </div>
            <Row>
              <Col>
                {props.renderContent?.()}
              </Col>
            </Row>
          </Container>
        </MDBCardBody>
        <MDBCardFooter className="feedback-footer pt-0">
          <MDBRow>
            <MDBCol className="d-flex align-items-center justify-content-end small text-muted">
              <UguisSensei onClick={props.showChat} disabled={props.disabledChat} />
            </MDBCol>
          </MDBRow>
        </MDBCardFooter>
      </MDBRipple>
    </MDBCard>
  )
}
