import React, { Component } from "react";
import {Container, Row, Col} from 'react-bootstrap';
import eventBus from "../EventBus";
import {
  MDBIcon, MDBCard, MDBCardBody, MDBBtn, MDBCardTitle, MDBCardText, MDBRipple, MDBRow, MDBCol
}
from 'mdb-react-ui-kit';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import FeedbackCard from './FeedbackCard'

export default class FeedbackContent extends Component {
  constructor(props) {
    super(props);

    props.direction ??= "vertical";

    this.state = {
      selectedCard: null,
      hoveredCard: null
    };
  }

  cardOnClick = (e, type, id) => {
    e.preventDefault();
    this.cardSelected(type, id);
    this.selectCard({ type: type, id: id });
  }

  onSwiperAfterInit(swiper) {
    this.swiper = swiper;
    let type = swiper.slides[swiper.activeIndex]?.getAttribute("type");
    let id = swiper.slides[swiper.activeIndex]?.getAttribute("id");
    this.cardSelected(type, id);
  }

  onSwiperSlideChange(swiper) {
    let type = swiper.slides[swiper.activeIndex]?.getAttribute("type");
    let id = swiper.slides[swiper.activeIndex]?.getAttribute("id");
    this.cardSelected(type, id);
  }

  cardSelected(type, id) {
    if (id != null){
      eventBus.dispatch("selectCard", { type: type, id: id });
    }
  }

  cardOnMouseOver = (e, type, id) => {
    // console.log('mouseover', type, id)
    e.preventDefault();
    eventBus.dispatch("hoverCard", { type: type, id: id });
  }

  cardOnMouseOut = (e, type, id) => {
    e.preventDefault();
    eventBus.dispatch("unhoverCard", { type: type, id: id });
  }

  scrollCardIntoView(id) {
    // console.log('scrollCardIntoView', id)
    const element = document.getElementById(`feedbackcard-${id}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  }

  selectCard(card) {
    // for vertical
    this.setState(prevState => ({
      ...prevState,
      selectedCard: {
        type: card.type,
        id: card.id,
      }
    }))
    this.scrollCardIntoView(card.id)

    // for horizontal
    if (this.swiper && this.swiper.slides) {
      let index = this.swiper.slides.findIndex(slide => slide.getAttribute("id") == card.id && slide.getAttribute("type") == card.type);
      if (index >= 0) {
        this.swiper.slideTo(index, 500, false);
      }
    }
  }

  highlightCard(card) {
    this.setState(prevState => ({
      ...prevState,
      hoveredCard: {
        type: card.type,
        id: card.id,
      }
    }))
    this.scrollCardIntoView(card.id)
  }

  lowlightCard(card) {
    this.setState(prevState => ({
      ...prevState,
      hoveredCard: null,
    }))
  }

  componentDidMount() {
    if (eventBus.storage.selectedCard) {
      this.selectCard(eventBus.storage.selectedCard)
    }

    eventBus.on("selectEditor", (data) =>
      {
        this.selectCard(data)
      }
    );
    eventBus.on("hoverEditor", (data) =>
      {
        this.highlightCard(data)
      }
    );
    eventBus.on("unhoverEditor", (data) =>
      {
        // console.log('unhover')
        this.lowlightCard(data)
      }
    );
  }

  componentWillUnmount() {
    eventBus.remove("selectEditor");
    eventBus.remove("hoverEditor");
    eventBus.remove("unhoverEditor");
  }

  isSelectedCard(feedback) {
    return this.isTargetCard(this.state.selectedCard, feedback);
  }

  isHoveredCard(feedback) {
    return this.isTargetCard(this.state.hoveredCard, feedback);
  }

  isTargetCard(targetCard, feedback) {
    return targetCard?.type == feedback.selectType && targetCard?.id == feedback.id
  }

  renderNoFeedback() {
    return (
      <MDBRow className="text-center text-muted"><MDBCol>改善のポイントはありません。</MDBCol></MDBRow>
    )
  }

  render() {
    if (this.props.feedbackCards == null){
      return null;
    }

    if (this.props.direction == 'vertical') {
      if (this.props.feedbackCards.length > 0) {
        return this.props.feedbackCards.map((feedback) => {
          return (
            <FeedbackCard {...feedback}
              selected={this.isSelectedCard(feedback)}
              hovered={this.isHoveredCard(feedback)}
              disabledChat={this.props.disabledChat}
              onClick={(e) => this.cardOnClick(e, feedback.selectType, feedback.id)}
              onMouseOver={(e) => this.cardOnMouseOver(e, feedback.selectType, feedback.id)}
              onMouseOut={(e) => this.cardOnMouseOut(e, feedback.selectType, feedback.id)}
            />
          )
        });
      }
      else {
        return this.renderNoFeedback();
      }
    }
    else {
      if (this.props.feedbackCards.length > 0) {
        return (
          <Swiper
            autoHeight={true}
            spaceBetween={50}
            slidesPerView={1}
            onAfterInit={(swiper) => {this.onSwiperAfterInit(swiper)}}
            onSlideChange={(swiper) => {this.onSwiperSlideChange(swiper)}}
            pagination={true}
            modules={[Pagination]}>
            {
              this.props.feedbackCards.map((feedback) =>
                <SwiperSlide id={feedback.id} type={feedback.selectType}>
                  <div className="pb-3">
                    <FeedbackCard {...feedback}
                      selected={true}
                      disabledChat={this.props.disabledChat}
                    />
                  </div>
                </SwiperSlide>
              )
            }
          </Swiper>
          )
      }
      else {
        return this.renderNoFeedback();
      }
    }
  }
}
