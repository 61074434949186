import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';
import * as Auth from 'aws-amplify/auth'
import { config } from "./amplifyConfig"
import * as gtm from "../services/gtm"
import crypto from 'crypto-js';

import * as auth_uguis from "./auth_uguis"
import * as auth_webcomponent from "./auth_webcomponent"


Amplify.configure(config);

export const hashUserId = (userId) => {
  return crypto.SHA256(userId).toString();
};

let _auth = auth_uguis
export function setConcourseToken(token) {
  const result = auth_webcomponent.setConcourseToken(token)
  if (result) {
    _auth = auth_webcomponent
  }
}

export async function signUp(email, password) {
  return await _auth.signUp(email, password)
}

export async function confirmSignUp(username, confirmationCode) {
  return await _auth.confirmSignUp(username, confirmationCode)
}

export async function signIn(username, password) {
  return await _auth.signIn(username, password)
}

export async function forgotPassword(username) {
  return _auth.forgotPassword(username)
}

export async function confirmPassword(username, confirmationCode, newPassword) {
  return _auth.confirmPassword(username, confirmationCode, newPassword)
}

export async function signOut() {
  return await _auth.signOut()
}

export async function getCurrentUser() {
  return await _auth.getCurrentUser()
}

export async function getAccessToken() {
  return await _auth.getAccessToken()
}

export async function getTenantId() {
  return await _auth.getTenantId()
}

export async function changePassword(oldPassword, newPassword) {
  return await _auth.changePassword(oldPassword, newPassword)
}

export async function deleteUser() {
  return await _auth.deleteUser()
}

export async function isSignedIn() {
  return await _auth.isSignedIn()
}

export async function signInWithGoogle() {
  return await _auth.signInWithGoogle()
}

Hub.listen('auth', async ({ payload }) => {
  console.log(payload.event);
  switch (payload.event) {
    case 'signedIn':
      console.log('user have been signedIn successfully.');
      const user = await getCurrentUser()
      const userHash = hashUserId(user.username)
      gtm.setUserId(userHash);
      gtm.sendLoginEvent();
      break;
    case 'signedOut':
      console.log('user have been signedOut successfully.');
      gtm.sendLogoutEvent()
      gtm.clearUserId()
      break;
    case 'tokenRefresh':
      console.log('auth tokens have been refreshed.');
      break;
    case 'tokenRefresh_failure':
      console.log('failure while refreshing auth tokens.');
      break;
    case 'signInWithRedirect':
      console.log('signInWithRedirect API has successfully been resolved.');
      break;
    case 'signInWithRedirect_failure':
      console.log('failure while trying to resolve signInWithRedirect API.');
      break;
  }
});
