import React, { useState, useContext, useEffect } from 'react';

import {
  MDBIcon,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBRipple,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from 'mdb-react-ui-kit';

import { MenuDialog, MenuContent } from './Menu'
import DetailedMenu from './DetailedMenu'
import { useNavigate, Link, Navigate } from "react-router-dom";
import { AuthContext } from "./Auth/AuthContext"
import { PointConfirmationContext } from './point/PointConfirmationContext';
import { Alert, Card, CardGroup, Fade } from 'react-bootstrap';
import Dialog from 'react-bootstrap-dialog'
import EikenTrademarkNote from './EikenTrademarkNote';
import EikenLogo from "./EikenLogo"
import Star from './english/Star';

import { Question } from "./english/model/Assignment";

import * as api from './api'
import * as device from './device';

import { UguisLogoTypeYoko, UguisLogoMarkGray } from './images';
import WelcomePage from './WelcomePage';
import LearningSelectionPage from './LearningSelectionPage';

Date.prototype.getDateTimeString = function () {
  if (this.isToday()) {
    return  `今日 ${this.toLocaleString("ja-JP", { hour: 'numeric', minute: '2-digit' })}`
  }
  else if (this.isYesterday()) {
    return  `昨日 ${this.toLocaleString("ja-JP", { hour: 'numeric', minute: '2-digit' })}`
  }
  else {
    return this.toLocaleString("ja-JP", { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: '2-digit' });
  }
}
Date.prototype.isSameDate = function(d) {
  return new Date(this).setHours(0,0,0,0) == new Date(d).setHours(0,0,0,0);
}

Date.prototype.isToday = function() {
  const today = new Date();
  return this.isSameDate(today);
}

Date.prototype.previousDay = function() {
  return new Date(this.setDate(this.getDate() - 1));
}

Date.prototype.isYesterday = function() {
  var yesterday = new Date().previousDay();
  return this.isSameDate(yesterday);
}

function UsageStep({ bubbleTitle, content }) {
  return (
    <div className="m-md-2 d-flex flex-row">
      <div className="fw-bold me-3 px-0 bubble">
        {bubbleTitle}
      </div>
      <div className='mt-sm-1 mt-md-0 mt-lg-1 px-0'>
        {content}
      </div>
    </div>
  );
}

export default function Top() {
  const navigate = useNavigate();
  const [ assignments, setAssignments ] = useState(null)
  const { user, tenantId, isLoading, showWelcomePage } = useContext(AuthContext)
  const [menuModalShow, setMenuModalShow] = React.useState(false);
  const [grade, setGrade] = React.useState(null);
  const [type, setType] = React.useState(null);
  const [detailedMenuModalShow, setDetailedMenuModalShow] = React.useState(false);
  const { showConfirmationModal } = useContext(PointConfirmationContext)
  var dialog;

  const getUserAssignments = async () => {
    if (user) {
      const assignments = await api.getUserAssignments(tenantId, user.userId)
      // console.log('assignments', assignments)
      setAssignments(assignments)
    }
  }

  useEffect(() => {
    getUserAssignments()
  }, [user])

  useEffect(() => {
    document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
  }, []);

  const handleSelectMenu = async (grade, type) => {
    setMenuModalShow(false);
    const confirmed = await showConfirmationModal('問題生成')
    if (!confirmed) {
      setMenuModalShow(true);
      return;
    }
    setGrade(grade)
    setType(type)
    if (user == null) {
      navigate(`/english?grade=${grade}&type=${type}&isSample=true`);
    }
    else {
      navigate(`/english?grade=${grade}&type=${type}`);
      // Modal to choose generation or custom is temporary disabled
      // setDetailedMenuModalShow(true)
    }
  }

  const handleSelectDetailedMenu = async (selection) => {
    setDetailedMenuModalShow(false)
    switch (selection) {
      case "sample":
        navigate(`/english?grade=${grade}&type=${type}&isSample=true`);
        break;
      case "topic":
        // use topic menu
        // setTopicMenuModalShow(true)

        // do not use topic menu
        navigate(`/english?grade=${grade}&type=${type}`);
        break;
      case "custom":
        break;
    }
  }


  function headline0() {
    return (
      <MDBRow className="text-center">
        <div className="lh-sm strong title text-center">
          英語ライティング学習サービス
        </div>
        <div>
          <UguisLogoTypeYoko style={{width: '75vw', maxWidth: '400px'}} alt="logo" />
        </div>
      </MDBRow>
    )
  }

  function appstore() {
    return (
      <MDBRow className="justify-content-center text-center">
        <MDBCol>
          <div className="h3 text-center fw-bold">
            すきま時間に、英語ライティングスキルを高めよう
          </div>
          <div className="text-center mt-3">
            <a href="https://apps.apple.com/jp/app/%E8%8B%B1%E6%A4%9C-%E3%83%A9%E3%82%A4%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0%E5%AF%BE%E7%AD%96-uguis-ai/id6498982523" title="App Storeからダウンロード">
              <img src="/Download_on_the_App_Store_Badge_JP_RGB_blk_100317.svg"
                    style={{width: '150px'}} alt="appstore" />
            </a>
          </div>
          <div className="text-center mt-3">
            <img src="/qrcode.svg"
                    style={{width: '150px', background: 'white'}} alt="qrcode" />
          </div>
        </MDBCol>
      </MDBRow>
    )
  }

  function catchcopy0() {
    return (
      <>
        <MDBRow className="mt-0 mt-md-3 text-center white-text">
          <div className="h4 fw-bold">
            誰でも簡単に繰り返し使える<span style={{fontSize: '0.7em', verticalAlign: 'buttom'}}>※</span>
          </div>
        </MDBRow>
        <MDBRow className="my-3 my-md-5 justify-content-center lh-sm striking strong flex-nowrap">
          <MDBCol size='4' className="catchcopy me-2 me-md-3">
            <div>
              <div><span className="subtitle">無料</span><span className="subscript">で</span></div>
              <div className="subtitle">AI学習</div>
            </div>
          </MDBCol>
          <MDBCol size='4' className="catchcopy me-2 me-md-3 ">
            <div>
              <div className="subscript">即時に</div>
              <div className="subtitle">採点・添削</div>
            </div>
          </MDBCol>
          <MDBCol size='4' className="catchcopy">
            <div>
              <div className="subtitle">英検<sup>®</sup>新問題</div>
              <div className="subtitle">形式対応</div>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-5">
          <div className="h6 fw-bold text-center">
            ＼合格への近道は練習にあり！／
          </div>
        </MDBRow>
        <div className='text-center mt-1 mb-3'>
          <MDBBtn id='top_button_upper' size='lg' className="orange-button" style={{width:"300px"}} onClick={() => setMenuModalShow(true)}>
              <div className="lh-sm strong">
                <span className="subscript">
                  今すぐ無料で
                </span>
                <br/>
                <span className="subtitle ms-1">
                  AI体験してみよう
                </span>
              </div>
          </MDBBtn>
        </div>
      </>
    )
  }

  function screenshot0() {
    return (
      <>
        <MDBRow className="mt-3 mt-md-0 justify-content-center">
          <div className="speech-bubble d-flex flex-column justify-content-center green-text">
            <div className='justify-content-center'>
              <div className='text-center strong striking'>
                新機能登場！
              </div>
            </div>
            <div className='d-flex flex-row justify-content-center'>
              <div className='d-flex justify-content-end align-items-center me-2'>
                <MDBBtn floating size="sm" className="green-button">
                  <MDBIcon far icon="comment" />
                </MDBBtn>
              </div>
              <div className='subscript strong text-center px-0'>
                <div>
                  <span className='subtitle'>AI</span>
                  の
                  <span className='subtitle'>UGUIS先生</span>
                  が
                  <span className='subtitle'>チャット</span>
                  で
                </div>
                <div className='green-text'>
                  わかるまで教えてくれます※
                </div>
              </div>
            </div>
          </div>
        </MDBRow>
        <MDBRow>
          <img src="/uguis-write.png" className='img-fluid'/>
        </MDBRow>
        <MDBRow className='mt-3'>
          <div className='blockquote-footer white-text'>
          ※ ユーザ登録（無料）いただくと、英検®の各級に対応したAI作問・採点・添削と、AIのUGUIS先生とのチャットをご利用いただけます。
          </div>
        </MDBRow>
      </>
    )
  }

  function headline1() {
    return (
      <MDBRow center className='px-0'>
        <div className="h1 lh-sm strong title text-center">
          UGUIS.AIとは
        </div>
        <div className="h6 mt-1 px-0">
          AIがライティング問題を自動生成、学習改善に役立つフィードバックを即時表示します。練習と修正を繰り返すことで、確実に改善を実感しながらライティング力を向上させられます。
        </div>
      </MDBRow>
    )
  }

  function catchcopy1() {
    return (
      <MDBRow>
        <div className="lh-sm">
          <div className="h4 striking strong wide">
            01
          </div>
          <div className="h5 fw-bold green-text">
            <div>すぐに採点結果と改善ポイントがわかる！</div>
            <div>確認したら再チャレンジ</div>
          </div>
        </div>
        <div className="h6 mt-2">
          <div>
          あなたの解答レベルが<MDBIcon fas icon="star" style={{color: "orange"}}/>の数ですぐわかります。
          </div>
          <div>
          アドバイスを読んで修正して、次は<MDBIcon fas icon="star" style={{color: "orange"}}/>レベルUPを目指しましょう！
          </div>
        </div>
      </MDBRow>
    )
  }

  function screenshot1() {
    return (
      <MDBRow className='catch-copy-ss'>
        <img src="/feedback.png" className='img-fluid' style={{ width: '90%' }} />
      </MDBRow>
    )
  }

  function catchcopy2() {
    return (
      <MDBRow>
        <div>
          <div className="h4 striking strong wide">
            02
          </div>
          <div className="h5 fw-bold green-text">
            <div>まるで英語の先生！</div>
            <div>あなたの英作文の改善点を即時に具体的にアドバイス</div>
          </div>
        </div>
        <div className="h6 mt-2">
          <div>模範解答と見比べてセルチェックする…なんて手間は、もう必要ありません。</div>
          <div className="mt-2">あなたが解答した英文のどこを改善すべきか、具体的に指摘をしてもらえます。</div>
        </div>
      </MDBRow>
    )
  }

  function screenshot2() {
    return (
      <MDBRow className='catch-copy-ss'>
        <img src="/correction.png" className='img-fluid' />
      </MDBRow>
    )
  }

  function catchcopy3() {
    return (
      <MDBRow>
        <div>
          <div className="h4 striking strong wide">
            03
          </div>
          <div className="h5 fw-bold green-text">
            <div>ライティング学習の新体験！</div>
            <div>AIのUGUIS先生にチャットで質問・相談しながら解ける</div>
          </div>
        </div>
        <div className="h6 mt-2">
          <div>
          「アドバイスちょうだい」「模範解答だして」「問題和訳して」など、わからないときは解答前でもUGUIS先生に質問できます。UGUIS先生があなたのライティング力が上達するようにサポートします。
          </div>
        </div>
      </MDBRow>
    )
  }

  function screenshot3() {
    return (
        <MDBRow className='catch-copy-ss'>
          <img src="/master-uguis.png" className='img-fluid fade-img'/>
        </MDBRow>
    )
  }


  function catchcopy4() {
    return (
      <MDBRow>
        <div>
          <div className="h4 striking strong wide">
            04
          </div>
          <div className="h5 fw-bold green-text">
            <div>英検<sup>®</sup>ライティングの練習ができる！</div>
            <div>新問題形式にも対応！</div>
          </div>
        </div>
        <div className="h6 mt-2">
          <div>英検<sup>®</sup>準1級～3級のライティング問題に対応。意見論述問題に加え、2024年度からの新問題形式（準2級と3級Eメール問題、準1級と2級は要約問題）の練習もしっかりできます！！
          </div>
          <div className="mt-2">AIがあなたの英検<sup>®</sup>級に合わせた問題を無限に出題<sup>※</sup>してくれます。</div>
        </div>
      </MDBRow>
    )
  }

  function screenshot4() {
    return (
        <MDBRow className='catch-copy-ss'>
          <img src="/menu.png" className='img-fluid fade-img'/>
        </MDBRow>
    )
  }

  function catchcopy5() {
    return (
      <MDBRow>
        <div>
          <div className="h4 striking strong wide">
            05
          </div>
          <div className="h5 fw-bold green-text">
            <div>高い採点精度に自信あり！</div>
            <div>採点ノウハウ・データがすごい！</div>
          </div>
        </div>
        <div className="h6 mt-2">
          <div>
            <u>UGUIS.AI</u>は、英検<sup>®</sup>のテストに精通した専門家チームが20年間にわたって英語の採点を研究し、その専門知識と豊富なデータをAI技術に活かしています。
          </div>
          <div className="mt-2">
          だからこそ、統一した基準で継続して採点・添削を行うには、採点者やその品質を維持管理するための負担が大きい、という課題をAIが効率化し、常に高品質で一貫したフィードバックを可能としました。
          </div>
          <div className="mt-2">
            学習者の対策の難しさと採点を行う指導者の労力の両面を解決できる、画期的なライティング学習サービスです。
          </div>
        </div>
      </MDBRow>
    )
  }

  function screenshot5() {
    return (
      <MDBRow className='catch-copy-ss'>
        <MDBContainer className="recommendation-reason">
          <MDBRow className="m-md-3">
            <MDBCol className='text-center'>
              <img src="/logo_type_yoko.svg"
                style={{ width: '60%', maxWidth: '400px' }} alt="logo" />
            </MDBCol>
          </MDBRow>
          <MDBRow className="m-md-3">
            <MDBCol className='text-center p-0 ms-auto'>
              <div className='reason-circle fw-bold'>
                <span>
                豊富な<br/>採点ノウハウ
                </span>
              </div>
            </MDBCol>
            <MDBCol className='text-center p-0'>
              <div className='reason-circle fw-bold'>
                <span>
                AI開発の<br/>技術力
                </span>
              </div>
            </MDBCol>
            <MDBCol className='text-center p-0 me-auto'>
              <div className='reason-circle fw-bold'>
                <span>
                大量の<br/>採点データ
                </span>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow className="m-md-3">
            <MDBCol className='text-center'>
              <div className='reason-rectangle fw-bold'>
                英語テストとAI開発の<br/>専門家集団が開発
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBRow>
    )
  }

  function catchcopy6() {
    return (
      <MDBRow>
        <div className="lh-sm">
          <div className="h4 striking strong wide">
            06
          </div>
          <div className="h5 fw-bold green-text">
            使い方はカンタン！まずはサンプル問題で体験してみよう！
          </div>
        </div>
        <div className="h6 mt-2">
          <div className='striking strong'>
            ＼新機能／
          </div>
          <div className="mt-2">
          ノートや紙に手書きした解答を、カメラで読み込むことができるようになりました！（WEB版のみ）
          </div>
        </div>
      </MDBRow>
    )
  }

  function screenshot6() {
    const steps_data = [
      {
        bubbleTitle: "STEP 1",
        content: <>
          <div className="fw-bold">AIが出題</div>
          <div>英検<sup>®</sup>級と問題形式を選びます</div>
        </>
      },
      {
        bubbleTitle: "STEP 2",
        content: <>
          <div className="fw-bold">問題に解答する</div>
          <div>英語で解答しましょう</div>
        </>
      },
      {
        bubbleTitle: "STEP 3",
        content: <>
          <div className="fw-bold">AIが添削＆採点！</div>
          <div>すぐに今のレベルが分かります</div>
          <div className="striking fw-bold">具体的な添削とアドバイス付き</div>
        </>
      },
    ]
    const steps = steps_data.map((step, index) => (
      <UsageStep key={index} bubbleTitle={step.bubbleTitle} content={step.content} />
    ));
    return (
      <MDBRow className='catch-copy-ss'>
        <div className="usage d-flex flex-column">
          {steps}
        </div>
      </MDBRow>
    )
  }

  function Landing() {
    return (
      <MDBContainer fluid>
        <MDBRow center className='green-page py-3 p-md-5 white-text'>
          <MDBCol>
            <MDBRow center>
              {headline0()}
            </MDBRow>
            <MDBRow className="mt-3">
              <MDBCol size='12' md='6'>
                {catchcopy0()}
              </MDBCol>
              <MDBCol size='12' md='6'>
                {screenshot0()}
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>

        <MDBRow center className="mt-5 px-md-5">
          <MDBCol>
            <MDBRow center className='catch-copy-margin'>
              {headline1()}
            </MDBRow>
            <MDBRow className="mt-5 catch-copy-box catch-copy-margin">
              <MDBCol md='6'>
                {catchcopy1()}
              </MDBCol>
              <MDBCol md='6' className='catch-copy-ss-vend'>
                {screenshot1()}
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-5 catch-copy-box catch-copy-margin">
              <MDBCol md='6'>
                {catchcopy2()}
              </MDBCol>
              <MDBCol md='6' className="order-md-first catch-copy-ss-vcenter">
                {screenshot2()}
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-5 catch-copy-box catch-copy-margin">
              <MDBCol md='6'>
                {catchcopy3()}
              </MDBCol>
              <MDBCol md='6' className='catch-copy-ss-vend'>
                {screenshot3()}
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-5 catch-copy-box catch-copy-margin">
              <MDBCol md='6'>
                {catchcopy4()}
              </MDBCol>
              <MDBCol md='6' className="order-md-first catch-copy-ss-vcenter">
                {screenshot4()}
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-5 catch-copy-box catch-copy-margin">
              <MDBCol md='6'>
                {catchcopy5()}
              </MDBCol>
              <MDBCol md='6' className='catch-copy-ss-vcenter'>
                {screenshot5()}
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-5 catch-copy-box catch-copy-margin">
              <MDBCol md='6'>
                {catchcopy6()}
              </MDBCol>
              <MDBCol md='6' className="order-md-first catch-copy-ss-vcenter">
                {screenshot6()}
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-5 py-3 p-md-5 mid-green-page justify-content-center">
          <MDBCol>
            <div className="mt-3 h5 fw-bold text-center green-text">
              ＼合格への近道は練習にあり！／
            </div>
            <div className='text-center mt-1'>
              <MDBBtn id='top_button_bottom' size='lg' className="orange-button lh-sm strong" style={{width:"300px"}} onClick={() => setMenuModalShow(true)}>
                  <div>
                    <span className="subscript">
                      今すぐ無料で
                    </span>
                    <br/>
                    <span className="subtitle ms-1">
                      AI体験してみよう
                    </span>
                  </div>
              </MDBBtn>
            </div>
            <div className="text-center mt-5">
            どの級でも、何問でも、何回でも<br/>今なら完全無料です！
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow className="justify-content-center white-page py-3 p-md-5">
          <MDBContainer className="compact-section">
            <h2 className="text-center fw-bold">AI英語添削</h2>
            <div className="mt-3" >
              ・英作文してみたけど、あっているかな？<br/>
              ・私の英文、ビジネスでも通用するかな？<br/>
              ・外国の友達との会話、この伝え方でいいかな？
            </div>
            <div className="text-center mt-3">
              そんな時はAI添削<br/>
              シーン別にあなたの英作文を添削します！
            </div>
            <MDBRow className='text-center my-3'>
              <Link to="/correction">
                <MDBBtn size='sm' className="green-button outline">
                    <span className="h5 fw-bold mx-3">AI英語添削はこちら</span>
                </MDBBtn>
              </Link>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBRow center className='green-page py-3 p-md-5 white-text'>
          <MDBCol>
            <MDBRow center>
              {appstore()}
            </MDBRow>
          </MDBCol>
        </MDBRow>

        <MDBRow className="justify-content-center white-page py-3 p-md-5">
          <MDBContainer className="compact-section">
            <MDBRow>
              <MDBCol className='ms-auto me-auto'>
                <h3 className="text-center fw-bold">
                  <span style={{ display: 'inline-block' }}>学校や塾でも</span>
                  <span style={{ display: 'inline-block' }}>ぜひご活用ください</span>
                </h3>
                <div className="mt-3" >
                  パンフレットをご用意しました。ぜひ生徒さんにもご紹介ください。
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow className='text-center my-3'>
              <MDBCol>
                <MDBBtn href={`${process.env.PUBLIC_URL}/UGUIS.AI.pdf`} target="_blank" size='sm' className="green-button outline">
                  <span className="h5 fw-bold mx-3">
                    PDFをダウンロード<MDBIcon className='ms-2' fas icon="download" />
                  </span>
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBRow center　className='mt-5 px-md-5'>
          <MDBCol>
            <EikenTrademarkNote/>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }

  function Menus() {
    return (
      <>
        <MenuDialog
          show={menuModalShow}
          onHide={() => setMenuModalShow(false)}
          onSelectMenu={handleSelectMenu}
          isLoggedIn={!(user == null)}
        />
        <DetailedMenu show={detailedMenuModalShow} grade={grade} type={type} onHide={() => setDetailedMenuModalShow(false)} onSelectMenu={handleSelectDetailedMenu}/>
      </>
    )
  }

  function NewButton() {
    return (
      <>
        <MDBContainer className="floating-button-pane fixed-bottom pt-4 pb-4 d-block d-md-none">
          <MDBRow className="text-center">
            <MDBCol>
            <MDBBtn className="orange-button" onClick={() => setMenuModalShow(true)}>
                <div className="mt-1 h6 fw-bold"><MDBIcon fas icon='add' className="me-1"/>新しい問題を学習する</div>
            </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <Card className='new-tile d-none d-md-block'>
          <MDBRipple role='button' rippleColor='#6dd6c2' onClick={() => setMenuModalShow(true)}>
            <Card.Body>
              <Card.Title>
                <MDBContainer className="text-center mt-2">
                  <MDBRow center>
                    <MDBIcon far icon="add" size='3x'/>
                  </MDBRow>
                  <MDBRow className="fw-bold mt-md-3 mt-1">
                    新しい問題を学習する
                  </MDBRow>
                </MDBContainer>
              </Card.Title>
            </Card.Body>
          </MDBRipple>
        </Card>
      </>
    )
  }

  function AssignmentIcon(question_type) {
    switch (question_type) {
      case 'email':
        return (
          <span className='me-2'>
          <MDBIcon far icon="envelope" />
          {/* <span class="badge bg-secondary">メ</span> */}
          </span>
        )
      case 'summary':
        return (
          <span className='me-2'>
          <MDBIcon far icon="file-alt" />
          {/* <span class="badge bg-secondary">要</span> */}
          </span>
        )
      case 'opinion':
        return (
          <span className='me-2'>
          <MDBIcon far icon="file-alt" />
          {/* <span class="badge bg-secondary">意</span> */}
          </span>
        )
      default:
        throw new Error(`${question_type} is unknown question type`)
    }
  }

  const handleSelectAssignment = (id) => {
    navigate(`/english?id=${id}`);
  }

  async function deleteAssignment(id) {
    const result = await api.deleteUserAssignment(id)
    if (true) {
      setAssignments(assignments.map(assignment => {
        if (assignment.id == id) {
          return { ...assignment, deleted: true };
        } else {
          // No changes
          return assignment;
        }
      }));
    }
    else {
      // TODO: error handling
    }
  }

  const handleDeleteAssignment = (id) => {
    // console.log('delete', id)
    dialog.show({
      title: '問題と解答の削除をしますか？',
      body: '削除すると書いた解答を取り戻せません。',
      actions: [
        Dialog.Action(
          'キャンセル',
          () => dialog.hide(),
          'btn-secondary'
        ),
        Dialog.DefaultAction(
          '削除',
          () => {
            deleteAssignment(id)
          },
          'btn-danger'
        )
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      }
    })
  }

  function ScoreStars(props){
    let stars
    const score = props.assignment.score;
    if(score){
      const question = Question.fromJSON(props.assignment)
      const max_score = question.get_max_score() + 1
      stars = Array.from({length: max_score}).map((_, i) => {
        return <Star key={i} score={score > i ? 1 : 0} size='sm'/>
      })
    }
    return (
      <span class='assignment-score'>
        {stars}
      </span>
    )
  }

  function Assignments() {
    if (assignments == null || typeof assignments == 'undefined') {
      return null;
    }

    return assignments.map((assignment, i) => {
      return (
        <Fade in={assignment.deleted!=true} onExited={() => getUserAssignments()}>
          <Card className='tile'>
            <MDBRipple role='button' rippleColor='#6dd6c2' onClick={() =>  handleSelectAssignment(assignment.id)} className="h-100">
              <Card.Body className='d-flex pt-3 px-3 pb-0 flex-column h-100'>
                <Card.Title>
                  {AssignmentIcon(assignment.type)}
                  <EikenLogo grade={assignment.grade} size='sm'/>
                </Card.Title>
                <Card.Text className="h-100 d-flex flex-column">
                  <div className="fw-bold mt-md-2 mt-0 lh-sm fade-text">
                    {assignment.title}
                  </div>
                  <div className="mt-auto pt-1">
                    <div className="d-flex justify-content-end" style={{fontSize:"0.75em"}}>
                      <span>学習日: {new Date(assignment.modified_at).getDateTimeString()}</span>
                    </div>
                  </div>
                </Card.Text>
              </Card.Body>
            </MDBRipple>
            <Card.Footer className='px-3 py-1 tile-footer'>
              <MDBRow>
                <MDBCol className="d-flex justify-content-between">
                  <ScoreStars assignment={assignment} />
                  <MDBDropdown dropdown>
                    <MDBDropdownToggle className="caret-off nav-link" tag='a' role='button'>
                      <MDBIcon fas icon="ellipsis-h" role="button"/>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu responsive='end' className="option">
                      <MDBDropdownItem link onClick={() =>  handleDeleteAssignment(assignment.id)}>
                        <div className="red-text fw-bold">
                          <MDBIcon far icon="trash-alt me-2" role="button"/>削除
                        </div>
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBCol>
              </MDBRow>
            </Card.Footer>
          </Card>
        </Fade>
      );
    })
  }

  function Home() {
    return (
      <MDBRow className="m-1">
        {assignments != null && assignments.length > 0 &&
          (
            <>
              <MDBRow className="m-1" between>
                <MDBCol size='6' className="h5 text-start">
                  あなたが学習した問題
                </MDBCol>
                <MDBCol size='6' className="text-end">
                  <small>
                    <Alert variant='primary' className='fitting-alert text-start'>
                      <Alert.Link
                        href='https://jp.surveymonkey.com/r/XKJ3NYM'
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        アンケートにご協力お願いします
                        <MDBIcon fas icon="external-link-alt" className='ms-1'/>
                      </Alert.Link>
                    </Alert>
                  </small>
                </MDBCol>
              </MDBRow>
              {NewButton()}
            </>
          )
        }
        {Assignments()}
        {assignments != null && assignments.length == 0 && <LearningSelectionPage setMenuModalShow={setMenuModalShow}/>}
        <Dialog ref={(el) => { dialog = el }} />
        {assignments != null && assignments.length > 0 &&
          <div className='my-5'>
            <EikenTrademarkNote />
          </div>
        }
      </MDBRow>
    )
  }

  // if mobile app, we will not show landing page, but show login right away
  // if (navigator.userAgent == 'uguis.ios' && user == null) {
  //   setShowNavBar(false)
  // }
  // else {
  //   setShowNavBar(true)
  // }

  if (showWelcomePage){
    return (
      <WelcomePage />
    )
  }

  return (
    <div className="light-green-page">
      {device.isIOSApp() === true && user == null && !isLoading &&
        <MDBContainer className="my-3">
          {headline0()}
          <div className="text-center lh-sm strong my-1">
            <span className="subscript">
              今すぐ無料で
            </span>
            <br/>
            <span className="subtitle ms-1">
              AI体験してみよう
            </span>
          </div>
          <MenuContent onSelectMenu={handleSelectMenu}/>
        </MDBContainer>
      }
      {device.isIOSApp() === false && user == null && !isLoading && Landing()}
      {user != null && !isLoading && Home()}
      {Menus()}
    </div>
  )
}
