import React from "react";
import { useMeasure } from 'react-use';

export default function Score(props) {
  const [ref, { width }] = useMeasure();

  const score = props.score;
  const max_score = props.max_score ?? 4;
  const height = 12;
  const padding = height/2;
  const level_width = (width-padding*2)/max_score;
  const radius = 2;
  const colors = {
    'vocabulary': '#88DDA0',
    'grammar': '#66D6F8',
    'content': '#FCD562',
    'coherence': '#FD9F3A',
  }

  function getColor() {
    return colors[props.type] ?? '#027880'
  }
  return (
    <svg ref={ref} width="inherit" height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1_3163)">
        <rect x="0" y="0" width={width} height={height} rx={height/2} fill="#E8EBEC"/>
        <rect x="0" y="0" width={level_width * score + padding*2} height={height} rx={height/2} fill={getColor()}/>
      </g>
      {
        Array.from(Array(props.max_score - 1)).map((_, i) => {
          return (
            <circle cx={padding + level_width*(i+1)} cy={height/2} r={radius} fill={i < score ? 'white' : getColor()}/>
          )
        })
      }
    </svg>
  );
}
