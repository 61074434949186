import React, {
  useState,
  useContext,
  useEffect,
} from 'react'

import { useNavigate, useSearchParams } from "react-router-dom";

import {
  MDBBtn,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
} from 'mdb-react-ui-kit';

import {
  Container,
  Stack,
} from 'react-bootstrap'

import { AuthContext } from "./Auth/AuthContext"
import EikenHistory from './english/EikenHistory'

import './History.css'

const tabs = [
  { type: 'eiken',   label: '英検受験' },
  { type: 'univ',    label: '大学受験' },
  { type: 'correct', label: '英語添削' },
]

const History = (props, ref) => {
  const navigate = useNavigate()
  const { user, isLoading, setShowNavBar } = useContext(AuthContext)

  const [searchParams, setSearchParams] = useSearchParams();
  const [assignmentType, setAssignmentType] = useState('eiken')
  const [userId, setUserId] = useState(searchParams.get('user_id'))

  useEffect(() => {
    const user_id = searchParams.get('user_id')
    setUserId(user_id)
  }, [searchParams])

  const handleHome = (e) => {
    navigate('/');
  }

  const handleSelectTab = (e, selectType) => {
    setAssignmentType(selectType)
  }

  const renderContent = () => {
    switch (assignmentType) {
      case 'eiken':
        return <EikenHistory userId={userId} />
      case 'univ':
        // TBD
        return null
      case 'correct':
        // TBD
        return null
      default:
        return null
    }
  }

  return (
    <div className="uguis-content">
      {/* breadcrumb */}
      <div className="mt-1 mx-4 d-flex align-items-center justify-content-between uguis-nav">
        <MDBBreadcrumb className="pt-3">
          <MDBBreadcrumbItem>
            <MDBBtn color='secondary' outline rounded onClick={handleHome}>
              ホーム
            </MDBBtn>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem active className="mt-1">
            <span className="fw-bold">
              学習履歴
            </span>
          </MDBBreadcrumbItem>
        </MDBBreadcrumb>
      </div>

      <Container>
      {/* nav */}
      <MDBTabs pills className='mx-0 mb-3'>
        {tabs.map(tab => (
          <MDBTabsItem key={tab.type}>
            <MDBTabsLink
              active={assignmentType == tab.type}
              onClick={(e) => handleSelectTab(e, tab.type)}
            >
              {tab.label}
            </MDBTabsLink>
          </MDBTabsItem>
        ))}
      </MDBTabs>

      {/* history content */}
      { renderContent() }
      </Container>
    </div>
  )
}

export default History
