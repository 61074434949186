import React, { useContext } from "react";
import {Container, Row, Col} from 'react-bootstrap';
import {
  MDBRow, MDBCol, MDBContainer, MDBBadge,
  MDBIcon, MDBCard, MDBCardBody, MDBCardFooter, MDBBtn, MDBCardTitle, MDBCardText, MDBRipple
}
from 'mdb-react-ui-kit';
import '../utils'
import { AuthContext } from "../Auth/AuthContext"
import UguisSensei from './UguisSensei'
import FeedbackCard from './FeedbackCard'

const grammarVocabularyErrors = {
    'ADJ': '形容詞を正しく使いましょう。',
    'ADJ:FORM': '比較級・最上級を正しく使いましょう。２つのものを比較するときには"-er"を使用します。例："nicer"。３つ以上を比較するときには"-est"を使用します。例："nicest"。',
    'ADV': '副詞を正しく使いましょう。',
    'CONJ': '接続詞を正しく使いましょう。',
    'CONTR': '短縮形を正しく使いましょう。例：dont (X), don\'t (○)',
    'DET': '限定詞を正しく使いましょう。',
    'MORPH': '語の形を正しく使いましょう。',
    'NOUN': '名詞を正しく使いましょう。',
    'NOUN:INFL': '不可算名詞を正しく使いましょう。例：baggages(X), baggage(O)',
    'NOUN:NUM': '複数形を正しく使いましょう。',
    'NOUN:POSS': '所有格を正しく使いましょう。例：my school volunteer work(X), my school\'s volunteer work(O)',
    'ORTH': '大文字小文字やスペースを正しく使いましょう。',
    'PART': '熟語を正しく使いましょう。',
    'PREP': '前置詞を正しく使いましょう。例：He agreed with my demands. (X),  He agreed to my demands. (O)',
    'PRON': '代名詞を正しく使いましょう。例：There is relaxing (X), It is relaxing (O)',
    'PUNCT': '句読点を正しく使いましょう。',
    'SPELL': '正しいスペルで書きましょう。',
    'VERB': '動詞を正しく使いましょう。',
    'VERB:FORM': '不定詞、動名詞、分詞を正しく使いましょう。例：I have free time enjoy them (X), I have free time to enjoy them (O).',
    'VERB:INFL': '動詞の形を正しく使いましょう。例：getted (X), got (O)',
    'VERB:SVA': '主語に合わせて動詞を正しく選びましょう。主語が単数形の時は動詞も単数形。主語が複数形の時は動詞も複数形。',
    'VERB:TENSE': '動詞の時制を正しく使いましょう',
    'WO': '正しい語順で書きましょう',
    'OTHER': '正しい言葉を使いましょう',
}

export default function GrammarVocabularyFeedbackCardContent(props) {

  const grammar_err = props.feedback.grammar_err?.removeDuplicates()
  const vocabulary_err = props.feedback.vocabulary_err?.removeDuplicates()

  return (
    <>
      {grammar_err?.length > 0 && props.feedback.is_grammar_error_critical === 1 &&
        <div>
        <MDBIcon fas icon="times-circle" className='me-2 text-danger'/>
        多くの文法間違いがあります。より高い点数を取るためにこれらの文法の間違いを直していきましょう。</div>
      }
      {grammar_err?.length > 0 && props.feedback.is_grammar_error_critical === 0 &&
        <div><MDBIcon fas icon="info-circle" className='me-2 text-info'/>文法の間違いがあります。作文の点数に影響はありませんが、正しい英語を使うことに心がけしましょう。</div>
      }
      {
        grammar_err?.map(error => {
          const error_description = grammarVocabularyErrors[error.substring(2)]
          return (
            <div key={`feedback-grammar-sentence-text-${props.id}`} className='my-3 ms-3'><MDBIcon far icon="check-square" className='me-2'/>{error_description}</div>
          )
        })
      }
      {vocabulary_err?.length > 0 && props.feedback.is_vocabulary_error_critical === 1 &&
        <div>
        <MDBIcon fas icon="times-circle" className='me-2 text-danger'/>
        意味が正しく伝わらなくなるような多くの語彙の間違いがあります。より高い点数を取るためにこれらの語彙の間違いを直していきましょう。</div>
      }
      {vocabulary_err?.length > 0 && props.feedback.is_vocabulary_error_critical === 0 &&
        <div><MDBIcon fas icon="info-circle" className='me-2 text-info'/>意味は伝わりますが、語彙の間違いがあります。作文の点数に影響はありませんが、正しい英語を使うことに心がけしましょう。</div>
      }
      {
        vocabulary_err?.map(error => {
          const error_description = grammarVocabularyErrors[error.substring(2)]
          return (
            <div key={`feedback-vocabulary-sentence-text-${props.id}`} className='my-3 ms-3'><MDBIcon far icon="check-square" className='me-2'/>{error_description}</div>
          )
        })
      }
      {props.feedback.exp}
    </>
  )
}
