import { useState, useEffect } from 'react';
import React, { Component } from 'react'

const MOBILE_WINDOW_WIDTH = 768;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function withWindowDimensions(Children) {
  return (props) => {
    const { width, height } = useWindowDimensions();
    return <Children {...props}
    windowWidth = { width }
    windowHeight = { height }
    />
  }
}

function isMobile() {
  // outerWidth didn't work on iPhone when changing device orientation. Change to innerWidth.
  // return window.outerWidth < MOBILE_WINDOW_WIDTH;
  return window.innerWidth < MOBILE_WINDOW_WIDTH;
}

function useIsMobile() {
  const [vIsMobile, setIsMobile] = useState(isMobile());

  useEffect(() => {
    function handleResize() {
      setIsMobile(isMobile());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return vIsMobile;
}

class WindowDimensionComponent extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    // console.log('windowWidth updateWindowDimensions')
    this.setState(prevState => ({...prevState,
      windowWidth: window.outerWidth,
      windowHeight: window.outerHeight
    }));
  }

  isMobile() {
    // console.log('windowWidth', this.state.windowWidth)
    return this.state.windowWidth < MOBILE_WINDOW_WIDTH;
  }

}

export {useWindowDimensions, withWindowDimensions, WindowDimensionComponent, isMobile, useIsMobile}
