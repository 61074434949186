import HeaderedScore from './HeaderedScore';
import {Row} from 'react-bootstrap';

export default function Perspectives(props) {
  return (
    <Row className="d-flex align-items-center justify-content-center m-0 p-0">
      <Row>
        <HeaderedScore
          header="語彙"
          score={props.scores.vocabularyScore}
          max_score={props.scores.maxScore}
          type="vocabulary"
        />
      </Row>
      <Row className="mt-3">
        <HeaderedScore
          header="文法"
          score={props.scores.grammarScore}
          max_score={props.scores.maxScore}
          type="grammar"
        />
      </Row>
      <Row className="mt-3">
        <HeaderedScore
          header="内容"
          score={props.scores.contentScore}
          max_score={props.scores.maxScore}
          type="content"
        />
      </Row>
      <Row className="mt-3">
        <HeaderedScore
          header="構成"
          score={props.scores.coherenceScore}
          max_score={props.scores.maxScore}
          type="coherence"
        />
      </Row>
    </Row>
  )
}
