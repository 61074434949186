import { useContext } from "react"
import { MDBBtn, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";

import { AuthContext } from './Auth/AuthContext';

export default function WelcomePage() {
  const { setShowWelcomePage } = useContext(AuthContext);
  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol md="12">
          <h1>Welcome Page Placeholder</h1>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol md="12">
          <MDBBtn onClick={() => {setShowWelcomePage(false);}}>
            学習スタート
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}