import React, { useState, useRef, useContext, useEffect } from "react";
import {Helmet} from "react-helmet";
import AssignmentView from './AssignmentView';
import Dialog from 'react-bootstrap-dialog'
import { Container, Row, Col, Form, FloatingLabel } from 'react-bootstrap';
import * as device from '../device';
import * as api from '../api'
import { v7 as uuidv7 } from 'uuid'
import { useIsMobile } from './WindowDimensions';
import { MDBContainer, MDBRow, MDBCol, MDBTabs, MDBTabsItem, MDBTabsLink, MDBInput, MDBBtn } from 'mdb-react-ui-kit';
import CorrectionOptionDialog from './CorrectionOptionDialog'
import { AuthContext } from "../Auth/AuthContext"
import eventBus from "../EventBus";
import CorrectionContentFeedbackCardContent from './CorrectionContentFeedbackCardContent'
import CorrectionGrammarVocabularyFeedbackCardContent from './CorrectionGrammarVocabularyFeedbackCardContent'
import {ChatContext} from './chat/ChatContext'
import { PointConfirmationContext } from "../point/PointConfirmationContext";
import { useApiWithPointUpdate } from "../apiPointConsuming";

export default function CorrectionView(props) {
  const { startChat, setShowChat } = useContext(ChatContext);
  const { user, event, fireEvent } = useContext(AuthContext);

  const [uniqueKey, setUniqueKey] = useState(uuidv7())
  const isMobile = useIsMobile();

  const [answer, setAnswer] = useState('');
  const [feedback, setFeedback] = useState(null);
  const [loading, setLoading] = useState(false);
  const dialogRef = useRef();
  const [showOptionDialog, setShowOptionDialog] = useState(false);
  const { showConfirmationModal } = useContext(PointConfirmationContext);
  const { getCorrectionCustomWrapped } = useApiWithPointUpdate();

  const defaultValues = {
    "values": [],
    "selectedValue": 0,
    "customValue": ""
  };
  const [correctionStyle, setCorrectionStyle] = useState("standard");
  const [type, setType] = useState(defaultValues);
  const [situation, setSituation] = useState(defaultValues);
  const [to, setTo] = useState(defaultValues);
  const [attitude, setAttitude] = useState(defaultValues);

  let layout;
  if (isMobile) {
    layout = [ [["answer"]] ];
  }
  else if (feedback == null) {
    layout = [["answer"]]
  }
  else {
    layout = [ [], [["answer"], ["feedback"]] ];
  }

  useEffect(() => {
    if (event?.name == 'chat') {
      showChat(event.data)
    }
  }, [event]);

  function showChat(aFeedback) {
    function getInitialChatMessage(aFeedback) {
      let message = "私は以下の英語の文章を書きました。\n";
      message += "===文章の開始===\n"
      message += answer;
      message += "\n===文章の終わり===\n"
      message += "私は以下の英語の文章の修正を勧められました。解説をお願いします。\n"
      message += aFeedback;
      return message;
    }
    if (user == null) {
      dialogRef.current?.show({
        title: 'UGUIS先生にチャットで質問',
        body: 'AIのUGUIS先生にチャットでアドバイスがもらえます。チャットを始めるには、無料のユーザ登録をしてログインしてください。',
        actions: [
          Dialog.Action(
            'キャンセル',
            (dialog) => dialog.hide(),
            'btn-secondary'
          ),
          Dialog.Action(
            'ログイン',
            async () => {
              eventBus.dispatch("login");
            },
            'btn-secondary'
          ),
          Dialog.DefaultAction(
            'ユーザ登録',
            async () => {
              eventBus.dispatch("signup");
            },
            'btn-success'
          )
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide()
        }
      })
    }
    else {
      setShowChat(true)
      startChat(getInitialChatMessage(aFeedback.text));
    }
  }

  function handleAnswerChanged(data, text) {
    setAnswer(text);
  }

  function showOption() {
    // console.log(type)
    setShowOptionDialog(true);
  }

  function hideOption() {
    setShowOptionDialog(false);
  }

  function handleClearAnswer() {
    dialogRef.current?.show({
      title: '書かれている文章をクリアしますか？',
      body: '文章をクリアすると元に戻せません。',
      actions: [
        Dialog.Action(
          'キャンセル',
          (dialog) => dialog.hide(),
          'btn-secondary'
        ),
        Dialog.Action(
          'クリアする',
          async () => {
            setFeedback(null);
            setCorrectionStyle("standard");
            setType(defaultValues)
            setSituation(defaultValues)
            setTo(defaultValues)
            setAttitude(defaultValues)

            setAnswer('');
            forceUpdate();
          },
          'btn-danger'
        )
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      }
    })
  }

  function renderHeader() {
    return (
      <Container className="m-3">
        <Row className="h3">英語添削</Row>
        <Row className="h5">あなたが自由に書いた英語文章をAIが添削してくれます。</Row>
      </Container>
    )
  }

  function getOptionText(option) {
    const values = option["values"];
    const selectedValue = option["selectedValue"];
    return selectedValue == values.length ? option["customValue"] : values[selectedValue];
  }

  function handleOptionChanged(style, type) {
    setCorrectionStyle(style);
    setType(type);
    setShowOptionDialog(false);
  }

  async function handleCorrection() {
    setLoading(true);
    if (correctionStyle !== 'standard'){
      const confirmed = await showConfirmationModal('添削')
      if (!confirmed) {
        setLoading(false);
        return;
      }
    }
    try {
      let feedback;
      if (correctionStyle == 'standard') {
        feedback = await api.getCorrection(answer);
      } else {
        feedback = await getCorrectionCustomWrapped(answer,
          correctionStyle == 'advanced' ? {
            'type': getOptionText(type),
            'situation': getOptionText(situation),
            'to': getOptionText(to),
            'attitude': getOptionText(attitude),
          } : {
            "style": correctionStyle
          }
        );
      }
      console.log('feedback', feedback)

      const applyFeedback = (option, setOption, newValues) => {
        if (correctionStyle != "advanced") {
          setOption(prevOption => ({...prevOption,
            values: newValues
          }));
          if (option['customValue']) {
            // custom value exists, set to the last index
            setOption(prevOption => ({...prevOption,
              selectedValue: newValues.length
            }));
          } else {
            // custom value does not exist, set to the default index
            setOption(prevOption => ({...prevOption,
              selectedValue: 0
            }));
          }
        }
      }
      applyFeedback(type, setType, feedback.type)
      applyFeedback(situation, setSituation, feedback.situation)
      applyFeedback(to, setTo, feedback.to)
      applyFeedback(attitude, setAttitude, feedback.attitude)

      setFeedback(feedback);
      setLoading(false);
      forceUpdate();
    }
    catch (err){
      console.error('Failed to get correction', err);
    }

  }

  function renderPreButton() {
    const modes = {
      "standard": "AIにおまかせ",
      "business": "ビジネス",
      "casual": "カジュアル",
      "advanced": "カスタム"
    }
    return (
      <div className="d-flex justify-content-end align-items-center mt-3 mb-5">
        <div className="small mx-2">添削モード：{modes[correctionStyle]}</div>
        <MDBBtn className="green-button outline" size="sm" onClick={showOption}>変更</MDBBtn>
      </div>
    );
  }

  function getPlaceholder() {
    return device.isIOSApp() === false ? '英語文章を入力、またはカメラで読み込みましょう' : '英語文章を入力しましょう'
  }

  function forceUpdate() {
    setUniqueKey(uuidv7());
  }

  let feedbackCards;
  if (feedback) {
    let vocabularyFeedbackCards = feedback.grammar_vocabulary.sentences.map((sentence, i) =>
      ({
        "id": i, "sentence": sentence
      })).filter((indexedSentence) => indexedSentence.sentence.cor_sent != indexedSentence.sentence.org_sent).map((indexedSentence) => {
        const sentence = indexedSentence.sentence;
        const id = indexedSentence.id;
        return {
          id: id,
          selectType: 'sentence',
          isContent: false,
          isCoherence: false,
          isVocabulary: true,
          isGrammar: true,
          renderContent: () =>
            <CorrectionGrammarVocabularyFeedbackCardContent feedback={sentence}/>
          ,
          showChat: () => {
            let text = "元の文章は：" + sentence["org_sent"] + " 薦められた修正は：" + sentence["cor_sent"]
            fireEvent("chat", {
              'type': 'sentence',
              'id': id,
              'text': text
            })
          }
        }
      });

    let contentFeedbackCards = feedback.content_corrections.map(feedback => ({
      id: feedback.tag,
      selectType: 'tag',
      isContent: true,
      isCoherence: true,
      isVocabulary: false,
      isGrammar: false,
      renderContent: () =>
        <CorrectionContentFeedbackCardContent feedback={feedback}/>
      ,
      showChat: () => {
        let feedbackText = feedback.title + "\n";
        feedbackText += feedback.description + "\n";
        feedbackText += "修正例：" + feedback.rewrite;
        fireEvent("chat", {
          'type': 'tag',
          'id': props.id,
          'text': feedbackText
        })
      }
    }))
    feedbackCards = vocabularyFeedbackCards.concat(contentFeedbackCards);
  }

  return (
    <div style={{ backgroundColor: 'var(--light-green)'}}>
      <Helmet>
        <title>UGUIS.AI | AI英語添削 | JIEM, Inc.</title>
        <meta
          name="description"
          content="UGUIS.AI | AI英語添削 | JIEM, Inc."
        />
      </Helmet>
      <AssignmentView
        key={uniqueKey}
        title="英語添削"
        answer={answer}
        answerTitle="あなたの英語文章"
        answerTabTitle="あなたの文章"
        feedback={feedback}
        feedbackCards={feedbackCards}
        layout={layout}
        actionButton={{
          enabled: {
            type: "primary",
            caption: ["AIで英語添削"],
            image: "/ai.svg",
            callback: handleCorrection,
          }
        }}
        onAnswerChanged={handleAnswerChanged}
        onClearAnswer={handleClearAnswer}
        renderHeader={renderHeader}
        renderPreButton={renderPreButton}
        placeholder={getPlaceholder()}
        scoring={loading}
        showEssayChat={false}
      />
      <Dialog ref={dialogRef} />
      <CorrectionOptionDialog
        correctionStyle={correctionStyle}
        type={type}
        situation={situation}
        to={to}
        attitude={attitude}
        show={showOptionDialog}
        onHide={hideOption}
        onOptionChanged={handleOptionChanged}/>
    </div>
  );
}
