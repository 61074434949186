import { Amplify } from 'aws-amplify';
import * as Auth from 'aws-amplify/auth'
import { config } from "./amplifyConfig"
import * as gtm from "../services/gtm"
import crypto from 'crypto-js';

// Amplify.configure(config);

export async function signUp(email, password) {
  try{
    const result = await Auth.signUp({
      username: email,
      password: password,
      options: {
        userAttributes: {
          email: email,
          given_name: email
        },
      }
    });
    console.debug('result', result);
    gtm.sendSignUpEvent();
    return result;
  }
  catch(err){
    throw err;
  }
}

export async function confirmSignUp(username, confirmationCode) {
  try{
    const result = await Auth.confirmSignUp({
      username: username,
      confirmationCode: confirmationCode
    })
    console.debug('result', result);
    gtm.sendConfirmSignUpEvent()
    return result
  }
  catch(err){
    console.debug('err', err)
    throw (err)
  }
}

export async function signIn(username, password) {
  try{
    if(isSignedIn()){
      await Auth.signOut()
    }
    await Auth.signIn({
      username: username,
      password: password,
    })
    return
  }
  catch(err){
    throw (err)
  }
}

export async function forgotPassword(username) {
  return await Auth.resetPassword({
    username: username
  });
}

export async function confirmPassword(username, confirmationCode, newPassword) {
  return await Auth.confirmResetPassword({
    username: username,
    confirmationCode: confirmationCode,
    newPassword: newPassword,
  })
}

export async function signOut() {
  if(isSignedIn()){
    return Auth.signOut()
  }
}

export async function getCurrentUser() {
  try {
    const user = await Auth.getCurrentUser()
    return user
  }
  catch(err){
    console.debug('failed to get current user', err)
    throw (err)
  }
}

export async function getAccessToken() {
  const session = await Auth.fetchAuthSession()
  return session.tokens.accessToken.toString()
}

export async function getTenantId() {
  return "00000000-0000-0000-0000-000000000000"
}

export async function changePassword(oldPassword, newPassword) {
  if(!isSignedIn()){
    return
  }
  return Auth.updatePassword({
    oldPassword: oldPassword,
    newPassword: newPassword
  })
}

export async function deleteUser() {
  if(!isSignedIn()){
    return
  }
  return Auth.deleteUser()
}

export async function isSignedIn() {
  try{
    const user = await getCurrentUser()
    return user ? true : false
  }
  catch(err){
    return false
  }
}

export async function signInWithGoogle() {
  if(isSignedIn()){ // If the user is already signed in, sign out first to prevent auth errors
    signOut()
  }
  gtm.sendLoginWithGoogleEvent();
  return await Auth.signInWithRedirect({ provider: 'Google' })
}

