import { MDBBtn, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";

export default function LearningSelectionPage({setMenuModalShow}) {
  const handleEiken = async (e) => {
    setMenuModalShow(true)
  }
  const handleUnivEntExam = async (e) => {
  }
  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol>
          <h2>Learning Selection Page Placeholder</h2>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBBtn onClick={handleEiken}>英検</MDBBtn>
        </MDBCol>
        <MDBCol>
          <MDBBtn onClick={handleUnivEntExam}>大学入試</MDBBtn>
        </MDBCol>
        <MDBCol>
          <Link to='/correction'>
            <MDBBtn>添削</MDBBtn>
          </Link>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}