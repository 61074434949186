import {Container, Row, Col } from 'react-bootstrap';
import Level from './Level'

export default function SummaryScore(props) {

  function getScoreBackground() {
    return `illustration_level${props.scores.summaryScore <= 2 ? '12' : (props.scores.summaryScore >= props.scores.maxScore ? '5' : '34')}`
  }

  return (
    <div style={{
      backgroundImage: `url("/${getScoreBackground()}.svg")`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain'
    }}>
      <Row className="justify-content-center">
        総合評価
      </Row>
      <Row className="justify-content-center">
      レベル
      </Row>
      <Row className="d-flex justify-content-center">
        <Level level={props.scores.summaryScore} maxLevel={props.scores.maxScore}/>
      </Row>
    </div>
  )

}
