import React, { forwardRef, useContext, useState, useEffect, useRef } from "react";
import { Container, Row, Card, Alert } from 'react-bootstrap';
import { MDBSpinner, MDBIcon, MDBBtn } from 'mdb-react-ui-kit';
import UguisTextEditor from "./UguisTextEditor"
import UguisTextEditor2 from "./UguisTextEditor2"
import {WordCount} from '../utils'
import DiffEx from './DiffEx';
import { SubtaskData } from "./SubTaskData";
import { AuthContext } from "../Auth/AuthContext"
import UguisSensei from "./UguisSensei"
import CameraButton from '../CameraButton'
import * as device from '../device';
import { v7 as uuidv7 } from 'uuid'

import {ReactComponent as PenIcon} from "../images/pen.svg";
import Colors from '../constants/Colors'
import Dialog from 'react-bootstrap-dialog'

function text2data(text) {
  return [{isRoot: true, children:[{ children:[{text: text}]}] }];
}

function data2text(data) {
  const getText = element => {
    let text = ""
    for (const child of element.children) {
      if ('text' in child) {
        text += child['text']
      } else {
        text += getText(child)
      }
    }
    return text
  }

  let sentences = []
  for (const element of data) {
    const s = getText(element)
    sentences.push(s)
  }

  return sentences.join("\n")
}

function pushMissingTags(groupedSentences, _feedback) {
  function findMissingTagPositionFromOrders(tag, tagOrder) {
    let index = tagOrder.indexOf(tag)
    if (index < 0) {
      // error: no tag in order
      return -1
    }
    return findMissingTagPosition(tag, tagOrder.slice(0, index), tagOrder.slice(index+1))
  }

  function findMissingTagPosition(tag, behindTags, infrontTags) {
    var index = groupedSentences.findLastIndex((group) => tag == group[0].tag)
    if (index >= 0) {
      // tag is not missing
      return -1
    }
    // try find the first position after behindTags
    index = groupedSentences.findLastIndex((group) => behindTags.includes(group[0].tag))
    if (index >= 0) {
      // found the position
      return index + 1
    }
    // get the position before infrontTags
    index = groupedSentences.findIndex((group) => infrontTags.includes(group[0].tag))
    if (index >= 0) {
      return index
    }
    // cannot find any, just return the first position
    return 0
  }

  let opinionTagOrder = [
    'Opinion', 'Intro', 'Template',
    'R0', 'missing_p0', 'EX0', 'missing_ex0',
    'R1', 'missing_p1', 'EX1', 'missing_ex1', 'Conclusion']
  let subtaskTagOrder = [
    'Opinion', 'Intro', 'Template',
    's0', 'missing_s0', 'EX0',
    's1', 'missing_s1', 'EX1',
    's2', 'missing_s2', 'EX2',
    's3', 'missing_s3', 'EX3',
    'Conclusion']
  function addMissingTag(tagOrder, tag, missingTag) {
    // console.log('addMissingTag', tag, tagOrder, missingTag)
    let index = findMissingTagPositionFromOrders(tag, tagOrder)
    if (index >= 0) {
      groupedSentences.splice(index, 0, [{'tag': missingTag}])
    }
  }

  let missingTagTemplate = [
    [_feedback.content?.p0_checklist_results?.checklist_4 == 1, opinionTagOrder, 'R0', 'missing_p0'],
    [_feedback.content?.p0_checklist_results?.checklist_345 == 1, opinionTagOrder, 'EX0', 'missing_ex0'],
    [_feedback.content?.p1_checklist_results?.checklist_4 == 1, opinionTagOrder, 'R1', 'missing_p1'],
    [_feedback.content?.p1_checklist_results?.checklist_345 == 1, opinionTagOrder, 'EX1', 'missing_ex1'],
    [_feedback.content?.s0_subtask_label >= 1, subtaskTagOrder, 's0', 'missing_s0'],
    [_feedback.content?.s1_subtask_label >= 1, subtaskTagOrder, 's1', 'missing_s1'],
    [_feedback.content?.s2_subtask_label >= 1, subtaskTagOrder, 's2', 'missing_s2'],
    [_feedback.content?.s3_subtask_label >= 1, subtaskTagOrder, 's3', 'missing_s3'],
  ]

  missingTagTemplate.reverse().forEach(template => {
    if (template[0]) {
      addMissingTag(template[1], template[2], template[3])
    }
  })
}

function _get_tag(tag, grade, type, passage_type) {
  if (tag) {
    const [text, color] = _get_tag_text(tag, grade, type, passage_type);
    return {id: tag, text: text, color: color};
  }
}

function _get_tag_text(tag, grade, type, passage_type) {
  let config = {
    "Opinion": {"text":"意見", "color":"success"},
    "Intro": {"text":"リード文", "color":"primary"},
    "Template": {"text":"リード文の続き", "color":"primary"},
    "R0": {"text":"１つ目の理由", "color":"success"},
    "R1": {"text":"２つ目の理由", "color":"success"},
    "EX0": {"text":"さらなる説明", "color":"primary"},
    "EX1": {"text":"さらなる説明", "color":"primary"},
    "ELSE": {"text":"その他の説明", "color":"secondary"},
    "Conclusion": {"text":"結論", "color":"success"},
    "missing_p0": {"text":"１つ目の理由", "color":"danger"},
    "missing_p1": {"text":"２つ目の理由", "color":"danger"},
    "missing_ex0": {"text":"１つ目の理由の説明", "color":"danger"},
    "missing_ex1": {"text":"２つ目の理由の説明", "color":"danger"},
  }
  if (type == 'email' || type =='summary'){
    let subtask_data_config
    if (type == 'email'){
      subtask_data_config = SubtaskData[grade][type]['config']
    }
    else if (type == 'summary'){
      if (grade == 'G15'){
        subtask_data_config = SubtaskData[grade][type]['config'][passage_type]
      }
      else if (grade == 'G20'){
        subtask_data_config = SubtaskData[grade][type]['config']
      }
    }
    Object.assign(config, subtask_data_config)
  }
  if (!(tag in config)) {
    return [null, null]
  }
  const text = config[tag]["text"]
  const color = config[tag]["color"]
  return [text, color]
}

function feedback2data(feedback, grade, type, passage_type) {
  const _normalize_text = txt => {
    if (txt === undefined) return undefined
    const ends_with_space = txt.endsWith(" ")
    return txt.trim() + (ends_with_space ? " " : "")
  }

  const _get_placeholder = (grade, question_type, passage_type, tag) => {
    let placeholders = {
      'missing_p0': '１つ目の理由がありません',
      'missing_p1': '２つ目の理由がありません',
      'missing_ex0': '１つ目の理由の説明がありません',
      'missing_ex1': '２つ目の理由の説明がありません',
    }

    if (question_type == 'email' || question_type == 'summary') {
      let subtask_data_placeholder
      if (question_type == 'email') {
        subtask_data_placeholder = SubtaskData[grade][question_type]['placeholder']
      }
      else if (question_type == 'summary'){
        if (grade == 'G15') {
          subtask_data_placeholder = SubtaskData[grade][question_type]['placeholder'][passage_type]
        }
        else if (grade == 'G20') {
          subtask_data_placeholder = SubtaskData[grade][question_type]['placeholder']
        }
      }
      Object.assign(placeholders, subtask_data_placeholder)
    }

    return tag in placeholders ? placeholders[tag] : ""
  }

  const tagHasError = (tag) => {
    try {
      if (tag == 'Intro' || tag == 'Opinion') {
        return (
          feedback.content?.checklist_15 ||
          feedback.content?.opinion_label
        )
      }
      else if (tag == 'R0') {
        return (
          feedback.content?.p0_checklist_results.checklist_4 ||
          feedback.content?.p0_checklist_results.checklist_9 ||
          feedback.content?.p0_checklist_results.checklist_10 ||
          feedback.content?.p0_checklist_results.checklist_11 ||
          feedback.content?.p0_checklist_results.checklist_12 ||
          feedback.content?.p0_checklist_results.task_completion_status ||
          feedback.content?.p0_checklist_results.incomplete_score == 1 ||
          feedback.content?.p0_checklist_results.sentence_score == 2
        )
      }
      else if (tag == 'EX0') {
        return (feedback.content?.p0_checklist_results.checklist_345)
      }
      else if (tag == 'R1') {
        return (
          feedback.content?.p1_checklist_results.checklist_4 ||
          feedback.content?.p1_checklist_results.checklist_9 ||
          feedback.content?.p1_checklist_results.checklist_10 ||
          feedback.content?.p1_checklist_results.checklist_11 ||
          feedback.content?.p1_checklist_results.checklist_12 ||
          feedback.content?.p1_checklist_results.task_completion_status ||
          feedback.content?.p1_checklist_results.incomplete_score == 1 ||
          feedback.content?.p1_checklist_results.sentence_score == 2
        )
      }
      else if (tag == 'EX1') {
        return (feedback.content?.p1_checklist_results.checklist_345)
      }
      else if (tag == "s0") {
        return (
          feedback.content?.s0_subtask_label >= 0.5
        )
      }
      else if (tag == "s1") {
        return (
          feedback.content?.s1_subtask_label >= 0.5
        )
      }
      else if (tag == "s2") {
        return (
          feedback.content?.s2_subtask_label >= 0.5
        )
      }
      else if (tag == "s3") {
        return (
          feedback.content?.s3_subtask_label >= 0.5
        )
      }
      else if (tag == "Conclusion") {
        return (
          feedback.content?.checklist_16 ||
          feedback.content?.checklist_17
        )
      }
      else if (tag) {
        return true;
      }
    } catch (error) {
      console.error(error)
    }
    return false
  }

  const sentenceHasError = (sentenceId) => {
    if (!feedback.grammar_vocabulary.sentences) return false
    const sentence = feedback.grammar_vocabulary.sentences[sentenceId]
    if (!sentence) return false
    return sentence?.grammar_err?.length > 0 || sentence?.vocabulary_err?.length > 0
  }

  function sentenceHasImportantError(sentenceId) {
    if (!sentenceId) return false;
    const sentence = feedback.grammar_vocabulary.sentences[sentenceId]
    if (!sentence) return false
    return sentence.is_grammar_error_critical === 1 || sentence.is_vocabulary_error_critical === 1;
  }

  if (feedback == null) return null;

  const sentences = feedback.grammar_vocabulary.sentences
  let groupedSentences;

  if (sentences) {
    sentences.forEach((sentence, i) => sentence.id = i)
    groupedSentences = sentences.reduce(function(prev, curr) {
      if (prev.length && curr.tag === prev[prev.length - 1][0].tag) {
        prev[prev.length - 1].push(curr);
      }
      else {
        prev.push([curr]);
      }
      return prev;
    }, []);

    pushMissingTags(groupedSentences, feedback)
  } else {
    groupedSentences = [[{org_sent: '', cor_sent:""}]]
  }

  // convert
  let action_id = 0
  const flattenedSentences = groupedSentences.flat()
  let data = flattenedSentences.map(sentence => {
    const org_txt = _normalize_text(sentence.org_sent)
    const cor_txt = _normalize_text(sentence.cor_sent)

    const diffs = DiffEx.diff(org_txt, cor_txt);
    const children = diffs.map(diff => {
      if (diff.from == diff.to) {
        return { text: diff.from }
      }
      action_id += 1
      return Object.assign(diff, {action_id, text: diff.from ? diff.from : ""})
    })

    if (sentence.org_sent === undefined && sentence.cor_sent === undefined) {
      const uPlaceholder = _get_placeholder(grade, type, passage_type, sentence.tag)
      return { tag: _get_tag(sentence.tag, grade, type, passage_type), hasError: tagHasError(sentence.tag), children: [{children: [{uPlaceholder, text: ""}]}]}
    }

    return { tag: _get_tag(sentence.tag, grade, type, passage_type), hasError: tagHasError(sentence.tag), children: [{
      id: sentence.id,
      hasError: sentence && sentenceHasError(sentence.id),
      important: sentence && sentenceHasImportantError(sentence.id),
      children
    }]}
  })
  data = data.reduce((accum, cur) => {
    const prevSentence = accum[accum.length - 1]
    if (prevSentence && prevSentence.tag == cur.tag) {
      prevSentence.children.push(...cur.children)
    } else {
      accum.push(cur)
    }
    return accum
  }, [])

  if (data.length > 0) {
    return [ {isRoot: true, children: data}];
  }
  else {
    return text2data("")
  }
}

export default function EssayView(props) {
  const [essay, setEssay] = useState(props.essay);
  const [feedback, setFeedback] = useState(props.feedback);
  const [wordCount, setWordCount] = useState(WordCount(props.essay));
  const { fireEvent } = useContext(AuthContext);
  const [uniqueKeyEditor, setUniqueKeyEditor] = useState(uuidv7())
  const [essayChanged, setEssayChanged] = useState(props.essayChanged);
  const [readOnly, setReadOnly] = useState(props.readOnly);
  const dialogRef = useRef(null);

  useEffect(() => {
    setEssay(props.essay);
    // setUniqueKeyEditor(uuidv7());
  }, [props.essay])

  useEffect(() => {
    setReadOnly(props.readOnly);
  }, [props.readOnly])

  useEffect(() => {
    setFeedback(props.feedback);
  }, [props.feedback])

  useEffect(() => {
    setEssayChanged(props.essayChanged);
  }, [props.essayChanged])

  function handleClear() {
    dialogRef.current?.show({
      title: '書かれている解答をクリアしますか？',
      body: '解答をクリアすると元に戻せません。',
      actions: [
        Dialog.Action(
          'キャンセル',
          (dialog) => dialog.hide(),
          'btn-secondary'
        ),
        Dialog.Action(
          'クリアする',
          () => {
            handleUseResult('');
          },
          'btn-danger'
        )
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      }
    })
  }


  function handleUseResult(text) {
    setFeedback(null);
    setEssay(text);
    setWordCount(WordCount(text));
    props.onEssayChanged?.(text2data(text), text)
    setUniqueKeyEditor(uuidv7());
    setEssayChanged(true);
  }

  function getButtonText(caption) {
    if (Array.isArray(caption)) {
      return caption.map(c => <span style={{display: 'inline-block'}}>{c}</span>);
    }
    return caption
  }

  function renderActionButton() {
    const button = readOnly ? props.actionButton.disabled : props.actionButton.enabled;
    let type = button.type;
    let caption = button.caption;
    let image = button.image;
    let callback = button.callback;
    let disabled = readOnly;
    const styles = {
      primary: "orange-button",
      secondary: "green-button",
      light: "green-button outline",
    }
    return (
      <MDBBtn
        size="lg"
        disabled={disabled}
        className={`${styles[type]} lh-sm strong`}
        onClick={callback}>
        <div className="d-flex align-items-center">
          {image && !(type == "primary" && props.scoring) &&
            <img src={image} className="me-1"/>
          }
          { type == "primary" && props.scoring &&
            <MDBSpinner role='status' className="me-1" style={{ width: '20px', height: '20px' }} />
          }
          {getButtonText(caption)}
        </div>
      </MDBBtn>
    )
  }


  function ClearButton() {
    return (
      <div className="mt-3">
        <div className={`d-flex align-items-center ${readOnly ? 'justify-content-between' : 'justify-content-start'} mt-3`}>
          <div className="small text-muted">
            <span className={`${props.suggestedLength ? (wordCount < props.suggestedLength.min ? 'text-danger' : (wordCount > props.suggestedLength.max ? 'text-warning' : 'text-success')) : ''}`}>
              {wordCount}語
            </span>
            { props.suggestedLength && (
              <span>
                ／{props.suggestedLength.min}語～{props.suggestedLength.max}語
              </span>
            )}
          </div>
          {readOnly && renderActionButton()}
        </div>
        { !readOnly &&
        <div className={`d-flex align-items-center ${readOnly ? 'justify-content-end' : 'justify-content-between'} mt-3`}>
          <div className="d-flex align-items-center">
            {device.isIOSApp() === false && (
              <CameraButton onUseResult={handleUseResult} className='me-2'/>
            )}
            <MDBBtn size="sm" fluid disabled={readOnly} className={`gradient-bubble outline`} rounded onClick={handleClear}>クリア</MDBBtn>
          </div>
          {renderActionButton()}
        </div>
      }
      </div>
    )
  }

  function showChat() {
    fireEvent("chat", {
      'type': 'general',
    })
  }

  function handleEssayChanged(data) {
    const text = data2text(data);
    setEssay(text);
    setWordCount(WordCount(text));
    props.onEssayChanged?.(data, text)
  }

  function getEssayNotSavedWarning() {
    return essayChanged && (
        <Alert key='warning' variant='warning' className='py-1 px-3 mt-3 mb-0'>
          <small>
            書きかけの文章は保存されていません。AI採点して文章を保存しましょう。
          </small>
        </Alert>
      )
  }

  function handleEditAssignment() {
    setReadOnly(false)
  }

  return (
    <Card>
      <Card.Header className="fw-bold answer">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <PenIcon fill={Colors.green}/>
            <span className="align-middle ms-2">{props.title}</span>
          </div>
          {readOnly &&
            <MDBBtn size="sm"
                    className='green-button d-flex align-items-center justify-content-center'
                    onClick={handleEditAssignment}>
            解答を修正する<MDBIcon fas icon="ms-1 arrow-right" />
            </MDBBtn>
          }
          {!readOnly && (props.showChat ?? true) &&
            <UguisSensei onClick={showChat}/>
          }
        </div>
      </Card.Header>
      <Card.Body className='p-md-4 p-1 pb-3'>
        {props.renderTitle?.()}
        {props.renderPreEditor?.()}
        <div className="px-3 py-2">
          <UguisTextEditor2
            key={uniqueKeyEditor}
            data={feedback ? feedback2data(feedback, props.question?.grade, props.question?.type, props.question?.passage_type) : text2data(essay??'')}
            placeholder={props.placeholder}
            onDataChanged={handleEssayChanged}
            keyboardOff={props.keyboardOff}
            onFocused={props.onEditorFocused}
            readOnly={readOnly}
          />
        </div>
        {props.renderPostEditor?.()}
        {ClearButton()}
        {getEssayNotSavedWarning()}
      </Card.Body>
      <Dialog ref={dialogRef} />
    </Card>
  )
}
