import React, { useContext, useRef, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownToggle,
} from 'mdb-react-ui-kit'

import {ReactComponent as HistoryIcon} from "../images/history.svg";
import { useIsMobile } from './WindowDimensions';

export default function RevisionSelectView(props) {
  const isMobile = useIsMobile();

  function toYYYYMMDD(dateString) {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}.${month}.${day}`
  }

  function onRevisionChanged(e, revision_id) {
    e.preventDefault()
    props.onRevisionChanged(revision_id)
  }

  function renderReivisionNumber(revisionNumber) {
    return (
      <div className="d-flex align-items-center">
        練習{revisionNumber}回目
      </div>
    )
  }

  function renderDate(create_at) {
    return (
      <span>
        <span className="me-1 me-md-2">学習日</span>
        <span>{toYYYYMMDD(create_at)}</span>
      </span>
    )
  }

  function renderLevel(totalScore) {
    return (
      <span className="ms-1 ms-md-3">
        <span className="me-1 me-md-2">総合レベル</span>
        <span>{totalScore}</span>
      </span>
    )
  }

  function renderRevision(revisionNumber, create_at, totalScore, latest) {
    return (
      <MDBRow className="revision-dropdown-item flex-nowrap">
        {isMobile &&
          <>
          <MDBCol className="my-1" style={{lineHeight:"20px"}}>
            <MDBRow>
              {renderReivisionNumber(revisionNumber)}
            </MDBRow>
            <MDBRow className="d-flex align-items-center green-text">
              <div>
                {renderDate(create_at)}
                {renderLevel(totalScore)}
              </div>
            </MDBRow>
          </MDBCol>
          <MDBCol className="d-flex align-items-center">
          {getRevisionIcon(revisionNumber)}
          </MDBCol>
          </>
        }
        {!isMobile &&
          <MDBCol className="d-flex align-items-center justify-content-start">
            {renderReivisionNumber(revisionNumber)}
            <span className="green-text ms-3">
              {renderDate(create_at)}
              {renderLevel(totalScore)}
            </span>
            <div className="ms-3">
              {getRevisionIcon(revisionNumber)}
            </div>
          </MDBCol>
        }
      </MDBRow>
    )
  }

  function isLatest(revision_number) {
    return revision_number == props.revisionData.revisions.length;
  }

  function getRevisionIcon(revision_number) {
    if (isLatest(revision_number)) {
      return (
        <div className="revision-icon">最新</div>
      )
    }
  }

  function renderRevisions() {
    return (
      <MDBDropdown>
        <MDBDropdownToggle className="px-3 py-2 caret-off revision">
          <MDBRow className="d-flex justify-content-between flex-nowrap">
            <MDBCol className="d-flex align-items-center">
              <HistoryIcon/>
              <span className="ms-2">履歴</span>
            </MDBCol>
            <MDBCol className="ps-0 d-flex align-items-center justify-content-end">
              <MDBIcon fas icon="angle-down green-text" />
            </MDBCol>
          </MDBRow>
        </MDBDropdownToggle>
        <MDBDropdownMenu className='user-menu-dropdown revision' responsive='end'>
          {props.revisionData.revisions.toReversed().map(r => (
            <>
              {!isLatest(r.revision_number) &&
                <hr className='hr hr-blurry m-0' />
              }
              <MDBDropdownItem className={props.revisionData.revisionNumber == r.revision_number ? 'active':''} link onClick={(e) => onRevisionChanged(e, r.id)}>
                {renderRevision(r.revision_number, r.created_at, r.score, isLatest(r.revision_number))}
              </MDBDropdownItem>
            </>
          ))}
        </MDBDropdownMenu>
      </MDBDropdown>
    )
  }

  return props.revisionData && props.revisionData.revisions.length > 0 && renderRevisions()
}
