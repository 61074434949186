import React, { createContext, useState, useContext, useCallback } from "react"

import { AuthContext } from "../Auth/AuthContext";
import PointConfirmationModal from "./PointConfirmationModal"

export const PointConfirmationContext = createContext()

export function PointConfirmationProvider({ children }) {
  const [showPointConfirmationModal, setShowPointConfirmationModal] = useState(false);
  const [pointConsumeConfirmed, setPointConsumeConfirmed] = useState(false);
  const [resolveModal, setResolveModal] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const { user, profile, freeAccount } = useContext(AuthContext);

  const showConfirmationModal = useCallback((title) => {
    if (!user || !profile || profile.account_type !== freeAccount){
      // always return true without showing modal,
      // if user is not logged in (to let user score sample questions)
      // or if user is not a free account (to allow unlimited API call for paid users)
      return new Promise((resolve) => {
        resolve(true)
      })
    }
    return new Promise((resolve) => {
      setModalTitle(title)
      setShowPointConfirmationModal(true)
      setResolveModal(() => resolve)
    })
  }, [user, profile])

  const handleModalClose = useCallback((confirmed) => {
    setShowPointConfirmationModal(false)
    setModalTitle("")
    setPointConsumeConfirmed(confirmed)
    if (resolveModal) {
      resolveModal(confirmed)
    }
  }, [resolveModal])

  const pointConfirmationValue = {
    showConfirmationModal,
    pointConsumeConfirmed
  }

  return (
    <PointConfirmationContext.Provider value={pointConfirmationValue}>
      {children}
      {showPointConfirmationModal && (
        <PointConfirmationModal
          show={showPointConfirmationModal}
          setShow={setShowPointConfirmationModal}
          title={modalTitle}
          onHide={handleModalClose}
        />
      )}
    </PointConfirmationContext.Provider>
  )
}
