import dayjs from 'dayjs';

Object.defineProperty(Number.prototype, "between", {
    enumerable: false,
    value: function(min, max) { return this >= min && this < max; }
});

Object.defineProperty(Array.prototype, "removeDuplicates", {
    enumerable: true,
    value: function() { return this.filter((item, index) => this.indexOf(item) === index); }
});

const WordCount = (str) => {
  if (!str) return 0;
  return str.replace(/[.,\?!;:/&\n]/g, ' ').split(' ')
    .filter(function(n) { return n != '' })
    .length;
}

Array.prototype.random = function () {
  return this[Math.floor((Math.random()*this.length))];
}

async function timeAsync(action) {
  const startTime = dayjs();
  const res = await action();
  const endTime = dayjs();
  const timeTaken = endTime.diff(startTime);
  return [timeTaken, res];
}

export {WordCount, timeAsync};
