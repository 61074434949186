import { useContext } from "react";
import { MDBIcon } from 'mdb-react-ui-kit';

export default function CorrectionContentFeedbackCardContent(props) {
  return (
    <div className='mt-1 mb-2'>
      <MDBIcon fas icon="info-circle" className='me-2 text-info'/>{props.feedback.exp}
    </div>
  )
}
