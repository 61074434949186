import React, { useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import './TermOfServiceModal.css';
import TermOfService from './TermOfService';
import { UguisLogoTypeYoko } from './images'

const TermOfServiceModal = ({ show, handleAcceptTOS, handleSignOut }) => {
  const [loading, setLoading] = useState(false);

  const handleAccept = async () => {
    setLoading(true);
    try {
      const success = await handleAcceptTOS();
      if (success) {
        handleClose();
      }
    } catch (err) {
      console.error('同意状態の保存処理中にエラーが発生しました：', err);
    }
    setLoading(false);
  };

  const handleClose = () => {
    console.log('TOS Modal closed');
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      scrollable={true}
      size="lg"
      fullscreen="md-down"
    >
      <Modal.Header>
        <Modal.Title>利用規約の更新</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-2 text-center">
          <UguisLogoTypeYoko style={{ width: '75vw', maxWidth: '400px' }} alt="logo" />
        </div>
        <div>
          利用規約が改定されました。引き続きサービスのご利用をご希望の方は新しい利用規約に同意をお願いいたします。
        </div>
        <div className="term-of-service-content">
          <TermOfService />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" className="btn-sm" onClick={handleSignOut}>
          同意しない
        </Button>
        <Button variant="primary" className="green-button justify-content-center" onClick={handleAccept} disabled={loading}>
          同意する
          {loading && <Spinner animation="border" size="sm" className="ms-1 align-text-bottom" />}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TermOfServiceModal;
