import React, { useContext, useState, useEffect } from "react"
import { ChatContext } from "./ChatContext"


import { TypeAnimation } from 'react-type-animation';
import Markdown from 'react-markdown'

import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBSpinner,
  MDBRipple
} from 'mdb-react-ui-kit';

import {ReactComponent as SendIcon} from "../../images/paper-airplane.svg";
import {ReactComponent as UguisIcon} from "../../images/uguis.svg";
import {ReactComponent as BulbIcon} from "../../images/light-bulb.svg";
import Colors from "../../constants/Colors"

export default function ChatContent(props) {
  const {
    currentChat, setCurrentChat,
    chats, setChats,
    loading, setLoading,
    speaking, setSpeaking,
    submitChat,
  } = useContext(ChatContext);

  useEffect(() => {
    scrollToBottom();
  }, [chats])

  function typeAnimationComplete() {
    setSpeaking(false);
  }

  function handleSelectChatCard(text) {
    submitChat(text);
  }

  function renderCards() {
    return props.cards && (
      <div className="d-flex flex-row flex-wrap m-3">
      {
        props.cards?.map(card => {
          return (
            <div className="chat-card">
              <MDBRipple role='button' rippleColor='#6dd6c2' onClick={() => handleSelectChatCard(card["prompt"])} className="h-100 w-100">
                <div className="my-2 mx-3 small d-flex align-items-center">
                  <BulbIcon stroke={Colors.green} className="me-2"/>
                  {card["title"]}
                </div>
              </MDBRipple>
            </div>
          )
        })
      }
      </div>
    )
  }

  function handleChatChange(event) {
    setCurrentChat(event.target.value);
  }

  function scrollToBottom() {
    // console.log('scrollToBottom');
    document.getElementById(`chatinput`).scrollIntoView({ behavior: 'smooth', block: 'start', inline:'start'})
  }

  async function handleChatSubmit() {
    submitChat(currentChat)
    setCurrentChat("");
  }

  function onEnterPress(e) {
    if(e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      handleChatSubmit();
    }
  }

  function uguisIcon() {
    return (
      <div className="d-flex align-items-center justify-content-center"
        style={{
          minWidth: "32px",
          maxWidth: "32px",
          minHeight: "32px",
          maxHeight: "32px",
          borderRadius: "100%",
          backgroundColor: "var(--light-green)",
        }}
      >
        <UguisIcon fill={Colors.green} style={{minWidth:"20px", maxWidth:"20px"}}/>
      </div>
    )
  }

  function renderChat(chat, sectionBeginning, animate=true) {
    return chat[0] == 'ai' ? (
        <div className={`d-flex flex-row justify-content-start align-items-start mb-4 ${sectionBeginning ? 'top-vh thick pt-4' : ''}`}>
          {uguisIcon()}
          <div className="py-2 px-2 px-md-4 ms-2 ms-md-3 small ai-chat">
            { animate &&
              <TypeAnimation
                sequence={
                  [
                    chat[1],
                    typeAnimationComplete,
                ]}
                speed={75}
                cursor={false}
                style={{ whiteSpace: 'pre-line' }}
              />
            }
            { !animate &&
              <Markdown>{chat[1]}</Markdown>
            }
          </div>
        </div>
      ) : (
        <div className="d-flex flex-row justify-content-end mb-4">
          <div className="py-2 px-2 px-md-4 me-1 small user-chat">
            {chat[1]}
          </div>
        </div>
      )
  }

  function renderLoading() {
    return (
      <div className="d-flex flex-row justify-content-start align-items-start mb-4">
        {uguisIcon()}
        <div
          className="px-4 py-2 ms-2 ms-md-3 fw-bold"
          style={{
            borderRadius: "15px",
            backgroundColor: "var(--light-green)",
          }}
        >
          <div className="small chatloading">
            ...
          </div>
        </div>
      </div>
    )
  }

  return (
    <MDBContainer fluid style={{backgroundColor:"white"}}>
      <MDBRow>
        {chats.map((chat, i) => renderChat(chat, i > 0 && chat[2], speaking && i == chats.length - 1))}
        {!loading && !speaking && renderCards()}
        {loading && renderLoading()}
      </MDBRow>
      <MDBRow>
        <div className="d-flex flex-row justify-content-end align-items-center">
          <textarea id='chatinput' className="form-control me-3"
            value={currentChat}
            onChange={handleChatChange}
            rows={1}
            onKeyDown={onEnterPress}
            placeholder="メッセージを入力してください"/>
          <MDBBtn floating className="green-button d-flex align-items-center justify-content-center" onClick={handleChatSubmit} style={{minWidth:'36px', maxWidth:'36px', minHeight:'36px', maxHeight:'36px'}}>
            <SendIcon stroke={Colors.white}/>
          </MDBBtn>
        </div>
      </MDBRow>
    </MDBContainer>
  )
}
