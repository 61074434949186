class Assignment {
  constructor(title, question, essay = null, feedback = null) {
    this.title = title
    this.question = question
    this.essay = essay
    this.feedback = feedback
  }
}

Assignment.fromJSON = function(json) {
  return new Assignment(json.title, Question.fromJSON(json.question), json.essay, json.feedback)
}

class Question {
  constructor(grade, type, question, keywords, exam_type) {
    this.grade = grade
    this.type = type
    this.question = question
    this.keywords = keywords
    this.exam_type = exam_type
  }

  toJSON() {
    return {
      "grade": this.grade,
      "type": this.type,
      "question_text": this.question,
      "keywords": this.keywords,
      "exam_type": this.exam_type,
    }
  }
}

Question.fromJSON = function(json) {
  switch (json.type) {
    case OpinionQuestion.type:
      return OpinionQuestion.fromJSON(json);
    case EmailQuestion.type:
      return EmailQuestion.fromJSON(json);
    case SummaryQuestion.type:
      return SummaryQuestion.fromJSON(json);
    case SimpleQuestion.type:
      return SimpleQuestion.fromJSON(json);
    case SitutationalQuestion.type:
      return SitutationalQuestion.fromJSON(json);
    default:
      return new Question(json.grade, json.type, json.question_text);
  }
};

class OpinionQuestion extends Question {
  static type = "opinion"
  constructor(grade, 
              question, 
              keywords = [], 
              checklist_91, 
              checklist_92, 
              checklist_93, 
              exam_type) {
    super(grade, OpinionQuestion.type, question, keywords, exam_type)
    this.keywords = keywords
    this.checklist_91 = checklist_91
    this.checklist_92 = checklist_92
    this.checklist_93 = checklist_93
  }
  get_max_score() {
    return 4
  }
  toJSON() {
    if (this.checklist_91 != null || this.checklist_92!= null || this.checklist_93!= null) {
      return {
        "grade": this.grade,
        "type": this.type,
        "question_text": this.question,
        "keywords": this.keywords,
        "checklist_91": this.checklist_91,
        "checklist_92": this.checklist_92,
        "checklist_93": this.checklist_93,
        "exam_type": this.exam_type
      }
    }
    else{
      return super.toJSON();
    }
  }
}

OpinionQuestion.fromJSON = function(json) {
  return new OpinionQuestion(json.grade, 
                             json.question_text, 
                             json.keywords, 
                             json.checklist_91, 
                             json.checklist_92, 
                             json.checklist_93,
                             json.exam_type);
};

class SimpleQuestion extends Question{
  static type = 'simple';
  constructor(
    grade,
    question,
    question_jp = null,
    lang,
    qSentType,
    ansType,
    exam_type,
    scoring_config_name,
  ) {
    super(grade, SimpleQuestion.type, question, null, exam_type)
    this.lang = lang
    this.qSentType = qSentType
    this.ansType = ansType
    this.question_jp = question_jp
    this.scoring_config_name = scoring_config_name
  }
  toJSON(){
    return {
      "grade": this.grade,
      "type": this.type,
      "question_text": this.question,
      "keywords": this.keywords,
      "exam_type": this.exam_type,
      "question_text_JP": this.question_jp,
      "scoring_config_name": this.scoring_config_name,
    }
  }
}

SimpleQuestion.fromJSON = function(json) {
  return new SimpleQuestion(
    json.grade, 
    json.question_text,
    json.question_text_JP, 
    json.lang,
    json.qSentType,
    json.ansType,
    json.exam_type,
    json.scoring_config_name,
  );
};

class SitutationalQuestion extends Question {
  static type = 'situational';
  constructor(
    grade,
    question,
    question_jp = null,
    lang,
    qSentType,
    ansType,
    exam_type,
    scoring_config_name,
    passage,
  ) {
    super(grade, SitutationalQuestion.type, question, null, exam_type)
    this.lang = lang
    this.qSentType = qSentType
    this.ansType = ansType
    this.question_jp = question_jp
    this.scoring_config_name = scoring_config_name
    this.passage = passage
  }
  toJSON(){
    return {
      "grade": this.grade,
      "type": this.type,
      "question_text": this.question,
      "keywords": this.keywords,
      "exam_type": this.exam_type,
      "question_text_JP": this.question_jp,
      "scoring_config_name": this.scoring_config_name,
      "passage": this.passage,
    }
  }
}

SitutationalQuestion.fromJSON = function(json) {
  return new SitutationalQuestion(
    json.grade, 
    json.question_text,
    json.question_text_JP, 
    json.lang,
    json.qSentType,
    json.ansType,
    json.exam_type,
    json.scoring_config_name,
    json.passage,
  );
};

class EmailQuestion extends Question {
  static type = "email"
  constructor(
      grade,
      question, 
      wh_question0, 
      wh_question1,
      email,
      sender,
      greeting,
      closing,
      keywords,
      subtasks,
      yes_no_question,
      topic_phrase,
      topic_char_start_index,
      topic_char_end_index,
      exam_type
    ) {
    super(grade, EmailQuestion.type, question, keywords, exam_type)
    this.wh_question0 = wh_question0
    this.wh_question1 = wh_question1
    this.email = email
    this.sender = sender
    this.greeting = greeting
    this.closing = closing
    this.subtasks = subtasks
    this.yes_no_question = yes_no_question
    this.topic_phrase = topic_phrase
    this.topic_char_start_index = topic_char_start_index
    this.topic_char_end_index = topic_char_end_index
  }
  get_max_score() {
    if (this.grade == 'G30'){
      return 3
    }
    else{
      return 4
    }
  }
  toJSON() {
    return Object.assign({}, super.toJSON(), {
      "wh-question_0": this.wh_question0,
      "wh-question_1": this.wh_question1,
      "e-mail": this.email,
      "sender": this.sender,
      "greeting": this.greeting,
      "closing": this.closing,
      "subtasks": this.subtasks,
      "yes-no question": this.yes_no_question,
      "topic_phrase": this.topic_phrase,
      "topic_char_start_index": this.topic_char_start_index,
      "topic_char_end_index": this.topic_char_end_index,
      "exam_type": this.exam_type
    });
  }
}

EmailQuestion.fromJSON = function(json) {
  return new EmailQuestion(
    json.grade,
    json.question_text, 
    json['wh-question_0'], 
    json['wh-question_1'], 
    json['e-mail'], 
    json.sender, 
    json.greeting, 
    json.closing, 
    json.keywords, 
    json.subtasks,
    json['yes-no question'],
    json['topic_phrase'],
    json['topic_char_start_index'],
    json['topic_char_end_index'],
    json.exam_type
  );
}

class SummaryQuestion extends Question {
  static type = "summary"
  constructor(
      grade,
      question, 
      passage,
      passage_type = null,
      exam_type,
    ) {
    super(grade, SummaryQuestion.type, question, null, exam_type)
    this.passage = passage
    this.passage_type = passage_type
  }
  get_max_score() {
      return 4
  }
  toJSON() {
    return Object.assign({}, super.toJSON(), {
      "passage": this.passage,
      "passage_type": this.passage_type,
      "exam_type": this.exam_type,
    });
  }
}

SummaryQuestion.fromJSON = function(json) {
  return new SummaryQuestion(
    json.grade,
    json.question_text, 
    json.passage,
    json.passage_type, 
    json.exam_type
  );
}

export {
  Question,
  Assignment,
  OpinionQuestion,
  EmailQuestion,
  SummaryQuestion,
}
