import React from "react";
import { MDBIcon } from 'mdb-react-ui-kit';
import parse from 'html-react-parser';

export default function ContentCoherenceFeedbackCardContent(props) {
  return props.feedback['texts'].map((text, i) => (
    <div key={`feedback-text-${props.id}`} className='mb-1'>
    <MDBIcon fas icon="times-circle" className='me-2 text-danger'/>{parse(text)}</div>
  ))
}
