import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="py-5 m-0 blockquote-footer" style={{ backgroundColor: 'var(--light-green)' }}>
      <div className="container text-center">
        <a href="https://www.jiem.co.jp/">© 2025 JIEM, Inc.</a>
        <span>  |  </span>
        <Link to="/term-of-service">利用規約</Link>
        <span>  |  </span>
        <Link to="/privacy-policy">プライバシーポリシー</Link>
        <span>  |  </span>
        <a href="https://www.jiem.co.jp/security/">情報セキュリティ基本方針</a>
        <span>  |  </span>
        <a href="https://support.uguis.ai/portal/ja/kb">お問い合わせ</a>
      </div>
    </footer>
  )
}

export default Footer;
