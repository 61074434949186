import React from "react";
import {Container, Row, Col, Card, OverlayTrigger, Tooltip, Stack, Alert } from 'react-bootstrap';
import {MDBIcon} from 'mdb-react-ui-kit';
import { useIsMobile } from './WindowDimensions';

import {ReactComponent as ResultIcon} from "../images/result.svg";
import Colors from '../constants/Colors'

import SummaryScore from './SummaryScore'
import Perspectives from './Perspectives'

export default function SummaryScoreCard(props) {
  const isMobile = useIsMobile();

  if (!props.scores) return null;

  function summary() {
    return (
      <>
      <SummaryScore scores={props.scores}/>
      <Row className="mt-3 small">
        <div>あなたの解答はレベル{props.scores.summaryScore}です。</div>
        { props.scores.summaryScore < props.scores.maxScore &&
          <div>苦手とするポイントを改善してレベル{props.scores.maxScore}を目指しましょう。</div>
        }
        { props.scores.summaryScore == props.scores.maxScore &&
          <div>この級の最高得点です！</div>
        }
      </Row>
      </>
    )
  }

  return (
    <Card>
      <Card.Header className="fw-bold">
        <div>
          <ResultIcon fill={Colors.green} className="me-2 align-middle"/>
          <span className="align-middle fw-bold">採点結果</span>
        </div>
      </Card.Header>
      <Card.Body className="px-0">
        <Container className="mt-3">
          {isMobile &&
            <Row className="d-flex align-items-center justify-content-center">
              <Row className="small pb-4 d-flex align-items-center justify-content-center">
                {summary()}
              </Row>
              <Row className="pt-4 top-vh thick d-flex align-items-center justify-content-center">
                <Perspectives scores={props.scores}/>
              </Row>
            </Row>
          }
          {!isMobile &&
            <Row className="d-flex align-items-center justify-content-center">
              <Col className="small" md={7}>
                <Perspectives scores={props.scores}/>
              </Col>
              <Col className="ps-4 left-vr thick">
                {summary()}
              </Col>
            </Row>
          }
        </Container>
      </Card.Body>
    </Card>
  )

}
