import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput
}
from 'mdb-react-ui-kit';

import { useState } from "react"
import { confirmPassword } from "./auth"
import { useParams } from "react-router-dom"
import {checkPassword, PasswordChecker} from './PasswordChecker'

import Alert from 'react-bootstrap/Alert';
import { UguisLogoTypeYoko } from '../images'

export default function ResetPassword() {
  let { param_email, param_code } = useParams();
  const [email, setEmail] = useState(param_email ?? "")
  const [confirmationCode, setConfirmationCode] = useState(param_code ?? "")
  const [newPassword, setNewPassword] = useState("")
  const [newPassword2, setNewPassword2] = useState("")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError("")

    try {
      await confirmPassword(email, confirmationCode, newPassword)
      setSuccess(true)
    } catch (err) {
      if (err.name == 'InvalidParameterException' && err.message.includes('\'password\' failed to satisfy constraint')) {
        setError('入力されたパスワードは条件を満たしていません。8文字以上で、少なくとも1つの数字と１つの英字を含めてください。')
      }
      else if (err.name == 'InvalidParameterException' && err.message.includes('\'confirmationCode\' failed to satisfy constraint')) {
        setError('入力されたアクセスコードは正しくありません。正しいアクセスコードを入力してください。')
      }
      else {
        setError(err.message)
      }
    }
  }

  function renderContent() {
    if (success) {
      return (
        <div className="my-3">
          <div>パスワードはリセットされました。新しいパスワードでログインしてください。</div>
          <MDBBtn className="mb-4 w-100 green-button" href="/login">ログイン</MDBBtn>
        </div>
      )
    }
    else {
      return (
        <>
          <MDBRow center className="my-3">
            <MDBCol md="8">
              <MDBInput size='lg' className='mb-4' label='メールアドレス' type='email' value={email} onChange={(e) => setEmail(e.target.value)}/>
            </MDBCol>
          </MDBRow>
          <MDBRow center className="mt-3">
            <MDBCol md="8">
              <MDBInput size='lg' label='新しいパスワード' type='password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/>
            </MDBCol>
          </MDBRow>
          <MDBRow center className="mt-3">
            <MDBCol md="8">
              <MDBInput size='lg' label='新しいパスワード（確認）' type='password' value={newPassword2} onChange={(e) => setNewPassword2(e.target.value)}/>
            </MDBCol>
          </MDBRow>
          <MDBRow center className="my-1">
            <MDBCol size="8">
              <PasswordChecker password={newPassword} confirmPassword={newPassword2}/>
            </MDBCol>
          </MDBRow>
          <MDBRow center className="my-3">
            <MDBCol md="8">
            入力されたメールアドレスに届いたアクセスコードを入力してください
            </MDBCol>
          </MDBRow>
          <MDBRow center className="my-3">
            <MDBCol md="8">
              <MDBInput size='lg' className='mb-4' label='アクセスコード' type='text' value={confirmationCode} onChange={(e) => setConfirmationCode(e.target.value)}/>
            </MDBCol>
          </MDBRow>
          <MDBRow center className="text-center my-3">
            <MDBCol md="8">
              <MDBBtn size='lg' className="mb-4 green-button" disabled={!confirmationCode || !newPassword || !email || !checkPassword(newPassword, newPassword2)} onClick={handleSubmit}>パスワードをリセット</MDBBtn>
            </MDBCol>
          </MDBRow>
        </>
      )
    }
  }

  return (
    <MDBContainer>
      <MDBRow className="mb-3">
        <MDBCol>
          <div>
            <div className="text-center">
              <a href="/">
                <UguisLogoTypeYoko style={{'width': '60vw', 'maxWidth': '400px'}} alt="logo" />
              </a>
              <h4 className="mt-3 mb-5 pb-1">
                <dvi>合格への近道は練習にあり！</dvi>
                <div>英語ライティング学習サービス</div>
              </h4>
              <div>パスワードのリセット</div>
            </div>
            {renderContent()}
            {error &&
              <Alert variant="danger">
                <Alert.Heading>パスワードのリセットが失敗しました</Alert.Heading>
                <div>{error}</div>
              </Alert>
            }
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}
