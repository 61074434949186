import React, { Component } from "react";

import { Card, Col, Row } from 'react-bootstrap'

import {ReactComponent as ChatIcon} from "../../images/chat.svg";
import Colors from '../../constants/Colors'

import ChatContent from './ChatContent'

import { MDBIcon } from 'mdb-react-ui-kit';

export default function ChatDialog(props) {
  return (
    <div className="chat-popup">
      <Card>
        <Card.Header className="chat" style={{height:"36px"}}>
          <Row className='clickable d-flex align-items-center justify-content-between small white-text' onClick={props.onShowAndHide}>
            <Col className='d-flex align-items-center justify-content-start'>
              <ChatIcon className="ms-1 me-2" stroke={Colors.white}/>
              UGUIS先生に聞く
            </Col>
            <Col className='d-flex justify-content-end'>
              <MDBIcon fas icon={props.show ? "chevron-down" : "chevron-up"} className="m-1"/>
            </Col>
          </Row>
        </Card.Header>
        {props.show &&
          <Card.Body className="px-1 px-md-3 chat-popup-content">
            <div className="">
              <ChatContent {...props}/>
            </div>
          </Card.Body>
        }
      </Card>
    </div>
  );
}
