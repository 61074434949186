import React from "react";
import { MDBBtn } from 'mdb-react-ui-kit';

import {ReactComponent as ChatIcon} from "../images/chat.svg";
import Colors from '../constants/Colors'

export default function UguisSensei(props) {
  return (
    <MDBBtn {...props} size="sm" className="green-button d-flex align-items-center justify-content-center">
      <ChatIcon stroke={Colors.white} />
      <span className="ms-1">UGUIS先生</span>
    </MDBBtn>
  )
}
