import './App.css';
import React, { useContext, useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  MDBBtn,
  MDBSpinner,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
} from 'mdb-react-ui-kit';
import { AuthContext } from "../Auth/AuthContext"
import { Container, Stack, Row, Col, OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
import { QuestionCard, QuestionSupplementCard } from './QuestionView';
import { VerticalFeedback, HorizontalFeedback } from './Feedback'
import * as api from '../api'
import EssayView from "./EssayView"
import Dialog from 'react-bootstrap-dialog'
import { useIsMobile } from './WindowDimensions';
import * as gtm from '../services/gtm'
import {WindowDimensionComponent} from './WindowDimensions';
import eventBus from "../EventBus";
import '../utils'
import { v7 as uuidv7 } from 'uuid'
import SummaryScoreCard from './SummaryScoreCard';

import {ReactComponent as HomeIcon} from "../images/home.svg";
import Colors from "../constants/Colors"

import {ChatContext} from './chat/ChatContext'
import ChatContent from './chat/ChatContent'
import ChatPopup from './chat/ChatPopup';

export default function AssignmentView(props) {
  var assignmentId;
  var windowScrollY = 0;
  var previousWindowScrollY = 0;

  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { showChat, setShowChat } = useContext(ChatContext);
  const isMobile = useIsMobile();
  const [mode, setMode] = useState("keyboard");
  const [activeTab, setActiveTab] = useState(props.question ? "question" : "answer");
  const [spacerHeight, setSpacerHeight] = useState(0);
  const [uniqueKeyEssayView, setUniqueKeyEssayView] = useState(uuidv7())

  useEffect(() => {
    // if chat window is on, switch to chat tab
    if (showChat) {
      setActiveTab("chat");
    }
  }, [showChat]);

  useEffect(() => {
    // initialize view
    setShowChat(false);
    setActiveTab(props.question ? "question" : "answer")
  }, []);

  async function handleHome(e) {
    const canNavigate = await props.canNavigate?.() ?? true
    if (canNavigate) {
      navigate('/');
    }
  }

  function questionAnswerTabHeader() {
    return (
      <div className="sticky-top px-0 tab-header mb-3 text-nowrap">
        <MDBTabs fill justify id='questionAnswerTab'>
         { props.question &&
            <MDBTabsItem>
              <MDBTabsLink active={activeTab === "question"} onClick={() => {
                setActiveTab("question");
                setShowChat(false);
              }}>
                問題</MDBTabsLink>
            </MDBTabsItem>
          }
          <MDBTabsItem>
            <MDBTabsLink active={activeTab === "answer"} onClick={() => {
              setActiveTab("answer");
              setShowChat(false);
            }}>
              {props.answerTabTitle ?? 'あなたの解答'}</MDBTabsLink>
          </MDBTabsItem>
          {props.question && props.feedback &&
            <MDBTabsItem>
              <MDBTabsLink active={activeTab === "summary"} onClick={() => {
                setActiveTab("summary");
                setShowChat(false);
              }}>
                採点結果</MDBTabsLink>
            </MDBTabsItem>
          }
          <MDBTabsItem>
            <MDBTabsLink active={activeTab === "chat"} onClick={() => {
              setActiveTab("chat");
              setShowChat(true);
            }}>
              UGUIS先生</MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>
      </div>
    )
  }

  function breadcrumbView() {
    return (
      <MDBBreadcrumb className="my-3 fw-bold">
        <MDBBreadcrumbItem>
          <div className="d-flex align-items-center clickable green-text" onClick={handleHome}>
            <HomeIcon className="me-1" stroke={Colors.green}/>ホーム
          </div>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem className="me-2" style={{whiteSpace: "nowrap", textOverflow: "ellipsis",overflow: "hidden"}}>
          {props.title}
        </MDBBreadcrumbItem>
        {props.renderAdditionalBreadcrumb &&
          <Row>
          {props.renderAdditionalBreadcrumb()}
          </Row>
        }
      </MDBBreadcrumb>
    )
  }

  function spinner() {
    return (
      <Alert className="p-3 p-md-5 m-3 m-md-5" variant="secondary">
        <Container>
          <Row className="text-center text-muted">
            <Col>
              {props.loadingMessage}
            </Col>
          </Row>
          <Row className="text-center mt-3">
            <Col>
              <MDBSpinner style={{ width: '3rem', height: '3rem' }} color='secondary' />
            </Col>
          </Row>
        </Container>
      </Alert>
    )
  }

  useEffect(() => {
    // componentDidMount
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  useEffect(() => {
    setUniqueKeyEssayView(uuidv7());
  }, [props.feedback]);


  function questionView() {
    return props.question && (
      <div className={`${!isMobile || activeTab === "question" ? "visible" : "collapse"} mb-3`}>
        <QuestionCard question={props.question} />
      </div>
    )
  }

  function questionSuppliementView() {
    return props.question && (
      <div className={`${!isMobile || activeTab === "question" ? "visible" : "collapse"} mb-3`}>
        <QuestionSupplementCard question={props.question} />
      </div>
    )
  }

  function chatView() {
    return isMobile && showChat && (
      <Row fluid className="py-3" style={{backgroundColor:"white"}}>
        <ChatContent cards={props.chatChards}/>
      </Row>
    )
  }

  function feedbackView() {
    return props.feedbackCards && (
      <VerticalFeedback
        feedbackCards={props.feedbackCards}
        scores={props.scores}
        question_data={props.question}
      />
    )
  }

  function answerView() {
    return (
      <div className={`${!isMobile || activeTab === "answer" ? "visible" : "collapse"}`}>
        <EssayView
          key={uniqueKeyEssayView}
          question={props.question}
          feedback={props.feedback}
          essay={props.answer}
          keyboardOff={mode == 'feedback'}
          essayChanged={props.answerChanged}
          onEssayChanged={props.onAnswerChanged}
          title={props.answerTitle}
          renderTitle={props.renderAnswerTitle}
          renderPreEditor={props.renderPreAnswer}
          renderPostEditor={props.renderPostAnswer}
          suggestedLength={props.suggestedLength}
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          showChat={props.showEssayChat ?? true}
          actionButton={props.actionButton}
          scoring={props.scoring}
        />
        <div>
          {props.renderPreButton?.()}
        </div>
        <div style={{ height: spacerHeight}}/>
      </div>
    )
  }

  function getView(name) {
    var views = {
      question: questionView,
      feedback: feedbackView,
      answer: answerView,
      question_supplement: questionSuppliementView,
    }
    return views[name]();
  }

  function layoutViews(layout) {
    if (layout) {
      return !Array.isArray(layout) ? getView(layout) : layout?.map(rows => {
        return (
          <Row>
            {!Array.isArray(rows) ? getView(rows) : rows?.map(cols => {
              return (
                <Col className="p-1 ps-md-3 pe-md-0">
                  {!Array.isArray(cols) ? getView(cols) : cols?.map(item => getView(item))}
                </Col>
              )
            }
            )}
          </Row>
        );
      })
    }
  }

  function handleHorizontalFeedbackSizeUpdated(contentRect) {
    setSpacerHeight(contentRect.height);
  }

  function showAndHideChat() {
    setShowChat(!showChat);
  }

  return (
    <>
    {props.loadingMessage && spinner()}
    {!props.loadingMessage &&
      <Container className="no-gutters">
        <Row>
          <Col>
          {breadcrumbView()}
          </Col>
        </Row>
        <Row>
          <Col>
          {props.renderHeader?.()}
          </Col>
        </Row>
        <Row>
          <Col>
            {isMobile && questionAnswerTabHeader()}
            {layoutViews(props.layout)}
            {chatView()}
          </Col>
        </Row>
        { props.question && (
          <Row className={`mt-3 text-center ${isMobile && activeTab === "question" ? "visible" : "collapse"}`}>
            <Col>
              <MDBBtn
                size="lg"
                className="green-button px-5"
                onClick={() => {
                  setActiveTab("answer");
                }}
              >
                解答しに行く
              </MDBBtn>
            </Col>
          </Row>
        )}
        { isMobile && props.feedback &&
          <Row className={`d-block d-md-none ${activeTab === "answer"? "visible":"invisible"}`}>
            <HorizontalFeedback
              feedbackCards={props.feedbackCards}
              onModeChange={props.handleFeedbackModeChange}
              question_data={props.question}
              sizeUpdated={handleHorizontalFeedbackSizeUpdated}
            />
          </Row>
        }
        { isMobile && props.feedback &&
          <Row className={`${activeTab === "summary"? "visible":"collapse"}`}>
            <Container className="mb-3 px-1">
              <SummaryScoreCard scores={props.scores}/>
            </Container>
          </Row>
        }
        {!isMobile &&
          <ChatPopup show={showChat} onShowAndHide={showAndHideChat} cards={props.chatChards}/>
        }
      </Container>
    }
    </>
  )
}
