import React from "react";
import { getStatement } from './SampleData';
import { Row, Card, Stack } from 'react-bootstrap';
import parse from 'html-react-parser';

import {ReactComponent as DocumentIcon} from "../images/document.svg";
import Colors from "../constants/Colors"

export function SummaryQuestionSupplement(props) {
  const question = props.question

  let statement_data = getStatement(question);

  const subject_title = statement_data['subject_title'];
  const articles = question.passage.split('\n').map((article, i) => {
    return <p key={i}>{article}</p>
  })

  return (
    <>
      <Card.Header className="fw-bold">
        <div>
          <DocumentIcon className="me-2 align-middle" stroke={Colors.green}/>
          <span className="align-middle">{subject_title}</span>
        </div>
      </Card.Header>
      <Card.Body className="p-md-4 px-1 py-2">
        <div className="article ms-1">
          {articles}
        </div>
      </Card.Body>
    </>
  );
}

export function SummaryQuestion(props) {
  return null;
}
