import React from "react";
import Score from './Score';

function HeaderedScore(props) {
  if (props.score == null){
    return null;
  }
  return (
    <div className="d-flex align-items-center">
      <span className="me-2 text-nowrap">{props.header}</span>
      <Score score={props.score} max_score={props.max_score} type={props.type}/>
    </div>
  );
}

export default HeaderedScore;
