import { useContext } from "react";

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
  MDBModalTitle,
  // MDBModalFooter,
} from 'mdb-react-ui-kit';

import { AuthContext } from "../Auth/AuthContext";

export default function PointConfirmationModal({
  show, setShow, title, onHide,
}) {
  const { currentPoint } = useContext(AuthContext);
  const handleConfirm = () => {
    setShow(false);
    onHide(true);
  }
  const handleHide = () => {
    onHide(false);
  }
  return (
    <MDBModal show={show} setShow={setShow} onHide={handleHide}>
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalTitle>
            {title}
          </MDBModalTitle>
          <MDBModalBody>
            <h4>
              Confirmation
            </h4>
            <p>
              You have {currentPoint} points now.
            </p>
            <MDBBtn onClick={handleConfirm} color="primary">
              Accept
            </MDBBtn>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  )
}
