import { useContext } from "react";
import { MDBIcon } from 'mdb-react-ui-kit';

export default function CorrectionContentFeedbackCardContent(props) {
  return (
    <div>
    <div className='mb-1'>
      <MDBIcon fas icon="info-circle" className='me-2 text-info'/><b>{props.feedback.title}</b>
    </div>
    <div className='mb-1'>
      {props.feedback.description}
    </div>
    <div className='mb-1'>
      <b>修正例：</b>{props.feedback.rewrite}
    </div>
    </div>
  )
}
