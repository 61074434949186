import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput
}
from 'mdb-react-ui-kit';

import { useState } from "react"
import { forgotPassword } from "./auth"
import { Navigate } from "react-router-dom"

import Alert from 'react-bootstrap/Alert';
import { UguisLogoTypeYoko } from '../images'

export default function ForgotPassword() {
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError("")

    try {
      await forgotPassword(email)
      setSuccess(true)
    } catch (err) {
      if (err.name == 'InvalidParameterException' && err.message.includes('failed to satisfy constraint')) {
        setError('入力されたメールアドレスは正しくありません。正しいメールアドレスを入力してください。')
      }
      else {
        setError(err.message)
      }
    }
  }

  function renderContent() {
    if (success) {
      return (
        <div className="my-3">
          <Navigate to={`/reset-password/${email}`}/>
          <div>メールでアクセスをご確認いただき、<a href={`/reset-password/${email}`}><b>こちら</b></a>でパスワードをリセットしてください。</div>
        </div>
      )
    }
    else {
      return (
        <>
          <MDBRow center className="my-3">
            <MDBCol md="8">
              <MDBInput size='lg' className='mb-4' label='メールアドレス' type='email' value={email} onChange={(e) => setEmail(e.target.value)}/>
            </MDBCol>
          </MDBRow>
          <MDBRow center className="mb-3">
            <MDBCol md="8">
              <MDBBtn size='lg' className="mb-4 green-button" disabled={!email} onClick={handleSubmit}>アクセスコードを取得</MDBBtn>
              {error &&
                <Alert variant="danger">
                  <Alert.Heading>リセットコードの取得に失敗しました</Alert.Heading>
                  <div>{error}</div>
                </Alert>
              }
            </MDBCol>
          </MDBRow>
        </>
      )
    }
  }

  return (
    <MDBContainer>
      <MDBRow className="text-center mb-3">
        <MDBCol className="mb-5">
          <div className="d-flex flex-column">
            <div className="text-center">
              <a href="/">
                <UguisLogoTypeYoko style={{'width': '60vw', 'maxWidth': '400px'}} alt="logo" />
              </a>
              <h4 className="mt-3 mb-5 pb-1">
                <div>合格への近道は練習にあり！</div>
                <div>英語ライティング学習サービス</div>
              </h4>
              <div>パスワードを忘れました</div>
            </div>
            {renderContent()}
            <div className="d-flex flex-row align-items-center justify-content-center pb-4 mb-4">
              <MDBBtn className='mx-2 green-button outline' href="/login">
                ログインに戻る
              </MDBBtn>
            </div>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}
