import React from "react";

export default function Level(props) {
  const level = props.level;
  const maxLevel = props.maxLevel ?? 5;
  const color = level <= 2 ? '#0396A0' : (level >= maxLevel ? '#ED6609' : '#8768E3')
  return (
    <span className="text-center">
      <span className="strong" style={{fontSize: "64px", color: color}}>
        {level}
      </span>
      <span style={{fontSize: "21px"}}>
        /{maxLevel}
      </span>
    </span>
  )
}
