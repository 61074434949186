import React, { useEffect, useState, useContext, useRef } from "react";
import { Helmet } from "react-helmet";
import AssignmentView from './AssignmentView';
import { useSearchParams } from 'react-router-dom';
import * as api from '../api'
import * as gtm from '../services/gtm'
import * as utils from '../utils'
import { AuthContext } from "../Auth/AuthContext"
import Dialog from 'react-bootstrap-dialog'
import { SampleAssignments } from './SampleData';
import { Assignment, Question } from "./model/Assignment";
import SamplingWithoutReplacement from '../SamplingWithoutReplacement';
import { JsonLocalStorage } from '../Storage';
import TopicData from "../TopicData"
import { useIsMobile } from './WindowDimensions';
import { useApiWithPointUpdate } from "../apiPointConsuming";

export default function EikenAssignmentDetail(props) {
  const { user } = useContext(AuthContext);
  const { scoreAssignmentWrapped, generateQuestionUnivEntExamWrapped } = useApiWithPointUpdate();
  const isMobile = useIsMobile();

  const [searchParams, setSearchParams] = useSearchParams();
  const [grade, setGrade] = useState(searchParams.get('grade'));
  const [type, setType] = useState(searchParams.get('type'));
  const [assignmentId, setAssignmentId] = useState(searchParams.get('id'));
  const [topic, setTopic] = useState(searchParams.get('topic'));
  const [isSample, setIsSample] = useState(searchParams.get('isSample', false));
  const [lang, setLang] = useState(searchParams.get('lang'));
  const [qSentType, setQSentType] = useState(searchParams.get('qSentType'));
  const [ansType, setAnsType] = useState(searchParams.get('ansType'));

  const [loadingMessage, setLoadingMessage] = useState(null);
  const [question, setQuestion] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [scoring, setScoring] = useState(false);
  const [title, setTitle] = useState(null);

  const dialogRef = useRef(null);

  const [sampleAssignment, setSampleAssignment] = useState(null);
  const [essayChanged, setEssayChanged] = useState(false);

  useEffect(() => {
    setGrade(searchParams.get('grade'));
    setType(searchParams.get('type'));
    setAssignmentId(searchParams.get('id'));
    setTopic(searchParams.get('topic'));
    setIsSample(searchParams.get('isSample', false));
    setLang(searchParams.get('lang'));
    setQSentType(searchParams.get('qSentType'));
    setAnsType(searchParams.get('ansType'));
  }, [searchParams])

  useEffect(() => {
    if (assignmentId) {
      loadAssignment(assignmentId);
    }
    else if (grade && type && lang && qSentType && ansType) {
      getQuestion(grade, type, isSample, lang, qSentType, ansType);
    }
  }, [assignmentId]);

  useEffect(() => {
    if (!assignmentId && grade && type && lang && qSentType && ansType) {
      getQuestion(grade, type, isSample, lang, qSentType, ansType);
    }
  }, [grade, type, topic, isSample, lang, qSentType, ansType]);

  function getQuestion(_grade, _type, _isSample, _lang, _qSentType, _ansType) {
    if (_isSample) {
      loadSample(_grade, _type);
    }
    else {
      generateQuestion(_grade, _type, _lang, _qSentType, _ansType);
    }
  }


  async function loadAssignment(id) {
    setLoadingMessage('課題をロードしています...');
    const assignment = await api.getUserAssignment(id).assignment;
    setQuestion(assignment.question);
    setFeedback(assignment.feedback);
    // TODO: load localStorage
    // const storedData = JSON.parse(localStorage.getItem(assignment_id))
    // if (storedData) {
    //   this.setState(prevState => ({
    //     ...prevState,
    //     assignment: {
    //       ...assignment.assignment,
    //       feedback: storedData.feedback
    //     },
    //     draft: storedData.draft
    //   }))
    // }
  }

  function loadSample(grade, type) {
    const assignment = Assignment.fromJSON(SampleAssignments[grade][type])
    setQuestion(assignment.question);
    setTitle(`${assignment.title}（サンプル）`);
    setSampleAssignment(assignment);
  }

  async function generateQuestion(grade, type, lang, qSentType, ansType) {
    setLoadingMessage('問題を生成しています...')
    let subtopic = null;

    let topicSampler = new SamplingWithoutReplacement(
      new JsonLocalStorage(`Uguis_remainingIndexes__univ_ent_exam__${grade}__${type}`),
      TopicData['univ_ent_exam'][grade]['univ_ent_exam'].flatMap(t => t.subTopics));

    if (!topic) {
      subtopic = topicSampler.sample();
    }
    else {
      // This process is left in case the topic is provided
      const topics = TopicData['univ_ent_exam'][grade]['univ_ent_exam']
      const target_topic = topics.find(candidate => candidate.id === topic);
      subtopic = target_topic.subTopics.random();
    }

    let [timeTaken, generated_question] = await utils.timeAsync(async () => {
      return await generateQuestionUnivEntExamWrapped(
        grade,
        type,
        subtopic,
        lang,
        qSentType,
        ansType
      );
    });

    generated_question['grade'] = grade;
    generated_question['type'] = type;
    generated_question['exam_type'] = 'univ_ent_exam';

    // Set univ ent exam specific information
    generated_question['lang'] = lang;
    generated_question['qSentType'] = qSentType;
    generated_question['ansType'] = ansType;

    const question = Question.fromJSON(generated_question);
    const assignment = new Assignment(
      generated_question.question_text,
      question);
    setLoadingMessage(null);
    setTitle(assignment.title);
    setQuestion(assignment.question);
    setAssignmentId(null);

    gtm.sendGenerationQuestionEvent(grade, type, subtopic, timeTaken);
  }

  function tryNavigate() {
    // return true if can navigate, else false
    return new Promise(async (resolve, reject) => {
      if (!essayChanged) {
        resolve(true);
        return;
      }
      dialogRef.current?.show({
        title: '解答を保存して移動しますか？',
        actions: [
          Dialog.Action(
            'キャンセル',
            (dialog) => {
              dialog.hide();
              resolve(false);
            },
            'btn-secondary'
          ),
          Dialog.Action(
            '保存せずに移動',
            () => {
              resolve(true);
            },
            'btn-danger'
          ),
          Dialog.DefaultAction(
            '保存してから移動',
            async () => {
              await handleScore()
              resolve(true);
            },
            'btn-success'
          )
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide();
          resolve(false);
        }
      })
      // }
      // else {
      //   action()
      // }
    });
  }

  function handleEssayChanged(draft) {
    setEssayChanged(true);
  }

  function handleScore() {}

  async function score(assignment) {
    setFeedback(null);
    setScoring(true);
    // score
    let [timeTaken, feedback] = await utils.timeAsync(async () => {
      return await scoreAssignmentWrapped(assignment)
    });
    assignment = {...assignment,
      feedback:feedback,
    }

    let coherence_score
    switch (assignment.question.type) {
      case 'opinion':
      case 'simple':
      case 'situational':
        coherence_score = feedback.coherence.sys_coherence
        break
      case 'email':
        coherence_score = feedback.content.sys_content
        break
      case 'summary':
        coherence_score = feedback.coherence.sys_coherence
        break
      default:
        throw new Error(`unknown question type: ${assignment.question.type}`)
    }

    assignment.score = feedback.scores.total_score

    gtm.sendScoreEvent(assignment.question.grade, assignment.question.type, assignment.score, timeTaken)

    // if logged in, save feedback
    if (user != null) {
      if (!assignmentId) {
        // new assignment, create
        // TODO
        assignmentId = await api.createUserAssignment(assignment)
      }
      else {
        // existing assignment, update
        // console.log('saving:upate')
        await api.updateUserAssignment(assignmentId, assignment)
      }
    }
    else {
      console.log('not logged in. skip saving.')
    }
    // update essay saved status
    // TODO
    // this.essayRef.current?.essaySaved()

    // show feedback and hide spinner
    setFeedback(feedback)
    setScoring(false);
  }

  let layout;
  if (isMobile) {
    layout = [ [["question", "question_supplement", "answer"]] ];
  }
  else if (type == "simple") {
    if (feedback == null) {
      layout = [ [], [["question"], ["answer"]] ];
    } else {
      layout = [ [], [["question", "answer"], ["feedback"]] ];
    }
  }
  else if (type == "situational") {
    if (feedback == null) {
      layout = [ ["question"], [["question_supplement"], ["answer"]] ];
    } else {
      layout = [ [["question", "question_supplement", "answer"], ["feedback"]] ];
    }
  }
  else{
    throw new Error(`unknown type: ${type}`);
  }

  let buttons;
  if (isSample && feedback == null) {
    buttons = [["light", ["サンプル解答で", "AI採点"], loadSampleEssay], ["primary", ["AI", "採点"], handleScore, "/ai.svg"]];
  } else {
    buttons = [["secondary", ["新しい問題を学習する"], null], ["primary", ["AI", "採点"], handleScore, "/ai.svg"]];
  }

  function loadSampleEssay() {
    setFeedback(sampleAssignment.feedback);
  }

  return (
    <div style={{ backgroundColor: 'var(--light-green)'}}>
      <Helmet>
        <title>UGUIS.AI - 英語ライティング練習サービス - 学習{isSample ? '（サンプル）' : ''}</title>
      </Helmet>
      <AssignmentView
        grade={grade}
        type={type}
        topic={topic}
        title={title}
        loadingMessage={loadingMessage}
        scoring={scoring}
        question={question}
        feedback={feedback}
        isSample={isSample}
        layout={layout}
        buttons={buttons}
        canNavigate={tryNavigate}
        onEssayChanged={handleEssayChanged}
      />
      <Dialog ref={dialogRef} />
    </div>
  );
}
