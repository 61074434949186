export default class Scores {
  constructor(type, feedback, max_score) {
    this.type = type;
    this.feedback = feedback;
    this.max_score = max_score;
  }
  get grammarScore() {
    return this.feedback.grammar_vocabulary.sys_grammar + 1
  }

  get vocabularyScore() {
    return this.feedback.grammar_vocabulary.sys_vocabulary + 1
  }

  get grammarVocabularyScore() {
    return Math.floor((this.grammarScore + this.vocabularyScore) / 2)
  }

  get contentScore() {
    return this.feedback.content.sys_content + 1;
  }

  get summaryScore() {
    return  Math.floor((this.contentScore + this.coherenceScore + this.grammarScore + this.vocabularyScore) / 4)
  }

  get coherenceScore() {
    switch (this.type) {
      case "opinion":
        return this.feedback.coherence.sys_coherence + 1;
      case "summary":
        return this.feedback.coherence.sys_coherence + 1;
      case "email":
        return this.feedback.content.sys_content + 1;
      default:
        throw new Error(`unsupported ${this.type}`);
    }
  }

  get contentCoherenceScore() {
    return Math.floor((this.coherenceScore + this.contentScore) / 2)
  }

  get maxScore() {
    return this.max_score + 1;
  }

  get isGrammarFullScore() {
    return this.grammarScore == this.maxScore;
  }

  get isVocabularyFullScore() {
    return this.vocabularyScore == this.maxScore;
  }

  get isContentFullScore() {
    return this.contentScore == this.maxScore;
  }

  get isGrammarVocabularyFullScore() {
    return this.grammarVocabularyScore == this.maxScore
  }

  get isContentCoherenceFullScore() {
    return this.contentCoherenceScore == this.maxScore
  }
}
