import { Container, Row, Col, Card, OverlayTrigger, Tooltip, Stack, Alert } from 'react-bootstrap';
import { MDBRipple, MDBCard, MDBCardBody, MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import FeedbackContent from './FeedbackContent';
import React, { Component } from "react";
import SummaryScoreCard from './SummaryScoreCard';
import HeaderedScore from './HeaderedScore';
import eventBus from "../EventBus";
import '../utils'
import { pointImg } from '../images';

import {ReactComponent as PointIcon} from "../images/point.svg";
import Colors from '../constants/Colors'

class VerticalFeedback extends Component {
  render() {
    return (
      <Container className="feedback-sticky-container p-0">
        {this.props.question_data && (
          <Row>
            <Container className="mb-3">
              <SummaryScoreCard scores={this.props.scores}/>
            </Container>
          </Row>
        )}
        <Row className='feedback-pane'>
          <Container className="mb-3">
            <Card className='feedback-card-pane'>
              <Card.Header>
                <div>
                  <PointIcon fill={Colors.green} className='me-2 align-middle'/>
                  <span className="align-middle fw-bold">改善のポイント</span>
                </div>
              </Card.Header>
              <Card.Body>
                <Row>
                  <FeedbackContent
                    direction="vertical"
                    type="tag"
                    disabledChat={this.props.disabledChat}
                    feedbackCards={this.props.feedbackCards}
                  />
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </Row>
      </Container>
    );
  }
}

class HorizontalFeedback extends Component {
  sizeObserver;

  constructor(props) {
    super(props);
    this.modes = [
      "keyboard",
      "feedback"
    ]
    this.state = {...this.state,
      mode: this.modes.indexOf("feedback")
    }
  }

  handleModeChange = async(e) => {
    this.setState({...this.state,
      mode: ((this.state.mode + 1) % this.modes.length)},
      () => {
        if (this.props.onModeChange) {
          this.props.onModeChange(this.modes[this.state.mode]);
        }
      });
  }

  componentDidMount() {
    if (this.props.onModeChange) {
      this.props.onModeChange(this.modes[this.state.mode]);
    }

    // console.log('componentDidMount')
    if (this.sizeObserver == null) {
      const feedbackPane = document.getElementById('horizontal-feedback');
      if (feedbackPane) {
        this.sizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            this.props.sizeUpdated?.(entry.contentRect);
          }
        });
        this.sizeObserver.observe(feedbackPane);
      }
    }
  }

  componentWillUnmount() {
    this.sizeObserver?.disconnect();
    this.sizeObserver = null;
  }

  get modeIcon() {
    return this.modes[this.state.mode] == "keyboard" ? "chevron-up" : "chevron-down"
  }

  render() {
    return (
      <div id="horizontal-feedback" className="fixed-bottom p-0">
        <Card className="p-0" style={{zIndex:1100}}>
          <Card.Header className='py-2' style={{height:"42px"}}>
            <Row className='d-flex align-items-center justify-content-between fw-bold clickable' onClick={this.handleModeChange}>
              <Col className='d-flex justify-content-start'>
                <PointIcon fill={Colors.green} className='ms-1 me-2'/>
                改善のポイント
              </Col>
              <Col className='d-flex justify-content-end'>
                <MDBIcon fas icon={ this.modeIcon } role="button" className="m-1"/>
              </Col>
            </Row>
          </Card.Header>
          {this.modes[this.state.mode] == "feedback" && (
            <Card.Body className="feedback-card-pane p-2">
              <FeedbackContent
                direction="horizontal"
                disabledChat={this.props.disabledChat}
                feedbackCards={this.props.feedbackCards}
              />
            </Card.Body>
          )}
        </Card>
      </div>
    );
  }
}

export { VerticalFeedback, HorizontalFeedback };
