import { useContext } from 'react'

import { isSignedIn } from './Auth/auth'
import { AuthContext } from './Auth/AuthContext';
import { callAPI, callAuthenticatedAPI, get_ocr_result } from './api'
import { retry } from "./retry";

const withPointUpdate = (apiFunction, getCurrentPoint) => {
  return async (...args) => {
    try {
      const result = await apiFunction(...args);
      getCurrentPoint();
      return result;
    } catch (error) {
      console.error('API call failed:', error);
      throw error;
    }
  };
};

export const useApiWithPointUpdate = () => {
  const { getCurrentPoint } = useContext(AuthContext);
  const scoreAssignmentWrapped = withPointUpdate(
    scoreAssignment, 
    getCurrentPoint, 
  );
  const generateQuestionEikenWrapped = withPointUpdate(
    generateQuestionEiken, 
    getCurrentPoint, 
  );
  const generateQuestionUnivEntExamWrapped = withPointUpdate(
    generateQuestionUnivEntExam, 
    getCurrentPoint, 
  );
  const ocrWrapped = withPointUpdate(
    ocr, 
    getCurrentPoint, 
  );
  const create_chat_wrapped = withPointUpdate(
    create_chat, 
    getCurrentPoint, 
  );
  const getCorrectionCustomWrapped = withPointUpdate(
    getCorrectionCustom,
    getCurrentPoint,
  );

  return {
    scoreAssignmentWrapped,
    generateQuestionEikenWrapped,
    generateQuestionUnivEntExamWrapped,
    ocrWrapped,
    create_chat_wrapped,
    getCorrectionCustomWrapped,
  }
}


async function scoreAssignment(assignment) {
  const isLoggedIn = await isSignedIn();
  let response;

  if (isLoggedIn) {
    response = await callAuthenticatedAPI('POST', `/api/score/english`, JSON.stringify(assignment));
  } else {
    response = await callAPI('POST', `/api/score/english`, {}, JSON.stringify(assignment));
  }

  if (response.status == 200) {
    return response.json;
  } else {
    return null;
  }
}

async function generateQuestionEiken(grade, type, topic) {
  const response = await callAuthenticatedAPI(
    'POST',
    `/api/generate/english`,
    JSON.stringify({ 'grade': grade, 'type': type, 'topic': topic }))
  if (response.status == 200) {
    return response.json
  }
  else {
    return null
  }
}

async function generateQuestionUnivEntExam(grade, type, topic, lang, question_sent_type, answer_type) {
  const response = await callAuthenticatedAPI(
    'POST',
    `/api/univ_ent_exam/english/generate`,
    JSON.stringify({
      'grade': grade,
      'type': type,
      'topic': topic,
      'lang': lang,
      'question_sent_type': question_sent_type,
      'answer_type': answer_type
    }))
  if (response.status == 200) {
    return response.json
  }
  else {
    return null
  }
}

const convertDataURIToBinary = (dataURI) => {
  //Convert base64 to file
  let arr = dataURI.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return u8arr;
}

async function ocr(imgData) {
  let response = await callAuthenticatedAPI('POST', `/api/ocr`, convertDataURIToBinary(imgData))
  if (response.status == 201) {
    let task_id = response.json;

    let result = await retry(async () => { return await get_ocr_result(task_id) }, 5, 0)
    // console.log('ocr result:', result)
    return result;
  }
  else {
    return null
  }
}

async function create_chat() {
  let response = await callAuthenticatedAPI('POST', `/api/chat`, JSON.stringify({}))
  if (response.status == 201) {
    let thread_id = response.json;
    return thread_id;
  }
  else {
    return null
  }
}

async function getCorrectionCustom(content, options) {
  let response
  let request_data = {
    content: content,
    options: options,
    lang: 'en'
  }
  response = await callAuthenticatedAPI('POST', `/api/correction/custom`, JSON.stringify(request_data))

  if (response.status == 200) {
    return response.json
  }
  else {
    return null
  }
}
