import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { Helmet } from "react-helmet";
import {
  MDBBtn,
  MDBIcon,
} from 'mdb-react-ui-kit';
import AssignmentView from './AssignmentView';
import RevisionSelectView from './RevisionSelectView';
import { useSearchParams } from 'react-router-dom';
import * as api from '../api'
import * as gtm from '../services/gtm'
import * as utils from '../utils'
import { AuthContext } from "../Auth/AuthContext"
import Dialog from 'react-bootstrap-dialog'
import { getStatement, SampleAssignments } from './SampleData';
import { Assignment, Question } from "./model/Assignment";
import SamplingWithoutReplacement from '../SamplingWithoutReplacement';
import { JsonLocalStorage } from '../Storage';
import TopicData from "../TopicData"
import { useIsMobile } from './WindowDimensions';
import EikenLogo from "../EikenLogo"
import QuestionTypeName from './QuestionTypeName';
import { Stack, Row, Card } from 'react-bootstrap';
import eventBus from "../EventBus";
import * as device from '../device';
import { v7 as uuidv7 } from 'uuid'
import { useApiWithPointUpdate } from "../apiPointConsuming";
import { PointConfirmationContext } from '../point/PointConfirmationContext';
import { ProfileIcon } from '../images'
import parse from 'html-react-parser';
import { SubtaskData } from "./SubTaskData";
import Scores from './Scores';
import GrammarVocabularyFeedbackCardContent from './EikenGrammarVocabularyFeedbackCardContent';
import ContentCoherenceFeedbackCardContent from './EikenContentCoherenceFeedbackCardContent'
import {ChatContext} from './chat/ChatContext'
import SummaryDialog from './SummaryDialog'

function getContentCoherenceFeedbacks(feedback, type, grade, passage_type) {
  function pushTagFeedback(detailedFeedbacks, sentences, template) {
    // console.log('pushing', template, sentences)
    let show = false
    let validFeedbacks = template.feedbacks.filter(f => f[0])
    if (template.tagMustExist) {
      if (template.tag) {
        if (sentences) {
          let index = sentences.findIndex(sentence => sentence.tag == template.tag)
          if (index >= 0) {
            if (validFeedbacks.some(v => true)) {
              show = true
            }
          }
        }
      }
    }
    else {
      if (validFeedbacks.some(v => true)) {
        show = true
      }
    }

    if (show) {
      detailedFeedbacks.push({
        type: 'tag',
        tag: template.tag,
        texts: validFeedbacks.map(f => f[1])
      })
    }
  }

  let detailedFeedbacks = []
  if (feedback && type){
    let sentences = feedback.grammar_vocabulary.sentences
    let tagFeedbackTemplates = [
      {tag: 'coherence.checklist_2', tagMustExist: false, feedbacks: [
        [feedback.coherence?.checklist_2, '文章が短すぎます。もっと内容を書きましょう。'],
      ]},
      {tag: 'coherence.checklist_20', tagMustExist: false, feedbacks: [
        [feedback.coherence?.checklist_20, '文章が少し短いです。もう少し内容を書きましょう。'],
      ]},
      {tag: 'coherence.checklist_22', tagMustExist: false, feedbacks: [
        [feedback.coherence?.checklist_22, 'ポイントについての内容はまとめて書きましょう。同じポイントについて前で書いたり後ろで書いたりしないようにしましょう。'],
      ]},
      {tag: 'coherence.checklist_29', tagMustExist: false, feedbacks: [
        [feedback.coherence?.checklist_29, '主題の要約の位置が不適切です。主題は最初に要約するようにしましょう。'],
      ]},
      {tag: 'coherence.order_issue', tagMustExist: false, feedbacks: [
        [(feedback.coherence?.checklist_32 || feedback.coherence?.checklist_34),
          '元の英文と、要約文の内容の順番を揃えましょう。'],
      ]},
      {tag: 'content.offtopic_score', tagMustExist: false, feedbacks: [
        [type == 'opinion' && (feedback.content.offtopic_score == 0
          || feedback.content.offtopic_score == null), '質問に関係のない内容を書いているようです。質問に答えましょう。'],
      ]},
      {tag: 'content.num_else', tagMustExist: false, feedbacks: [
        [type == 'summary' && (feedback.content.num_else >= 1
          || feedback.content.num_else == null),
         '余分な内容を省き要点をまとめましょう。'],
      ]},
      {tag:'Intro', tagMustExist: true, feedbacks: [
        [feedback.content.checklist_15, '適切なリード文がありません。'],
        [feedback.content.opinion_label, 'もっとお題と関係のある内容を書きましょう。'],
      ]},
      {tag:'Opinion', tagMustExist: true, feedbacks: [
        [feedback.content.checklist_15, '適切なリード文がありません。'],
        [feedback.content.opinion_label, 'もっとお題と関係のある内容を書きましょう。'],
      ]},
      {tag:'R0', tagMustExist: true, feedbacks: [
        [feedback.content.p0_checklist_results?.checklist_4, 'こちらの理由をもう少し詳しく書きましょう。'],
        [feedback.content.p0_checklist_results?.checklist_9, 'こちらの理由はトピックとあまり関係ないようです。もう少し関係のある理由を書きましょう。'],
        [feedback.content.p0_checklist_results?.checklist_10, 'こちらの理由をもう少し詳しく書きましょう。'],
        // we have all the corrections in the main text already
        // [feedback.content.p0_checklist_results.checklist_11, '英単語を使用してください。'],
        // [feedback.content.p0_checklist_results.checklist_12, '理由が不十分な文です'],
        [feedback.content.p0_checklist_results?.task_completion_status == 1, '質問に対して適切に答えていません。'],
        // TODO: address in sentence comment
        // [feedback.content.p0_checklist_results && feedback.content.p0_checklist_results.incomplete_score == 1, '不完全な文です。'],
        // [feedback.content.p0_checklist_results && feedback.content.p0_checklist_results.sentence_score == 2, '文の意味に影響する文法の誤りがあります。'],
      ]},
      {tag:'EX0', tagMustExist: true, feedbacks: [[feedback.content.p0_checklist_results?.checklist_345, 'こちらの理由に対する説明をもう少し詳しく書きましょう。']]},
      {tag:'R1', tagMustExist: true, feedbacks: [
        [feedback.content.p1_checklist_results?.checklist_4, 'こちらの理由をもう少し詳しく書きましょう。'],
        [feedback.content.p1_checklist_results?.checklist_9, 'こちらの理由はトピックとあまり関係ないようです。もう少し関係のある理由を書きましょう。'],
        [feedback.content.p1_checklist_results?.checklist_10, 'こちらの理由をもう少し詳しく書きましょう。'],
        // we have all the corrections in the main text already
        // [feedback.content.p1_checklist_results?.checklist_11, '英単語を使用してください。'],
        // [feedback.content.p1_checklist_results?.checklist_12, '理由が不十分な文です'],
        [feedback.content.p1_checklist_results?.task_completion_status == 1, '質問に対して適切に答えていません。'],
        // TODO: address in sentence comment
        // [feedback.content.p1_checklist_results?.incomplete_score == 1, '不完全な文です'],
        // [feedback.content.p1_checklist_results?.sentence_score == 2, '文の意味に影響する文法の誤りがあります'],
      ]},
      {tag:'EX1', tagMustExist: true, feedbacks: [[feedback.content.p1_checklist_results?.checklist_345, 'こちらの理由に対する説明をもう少し詳しく書きましょう。']]},
      {tag:'Conclusion', tagMustExist: true, feedbacks: [
        [feedback.content.checklist_16, '適切な結論がありません。'],
        [feedback.content.checklist_17, 'リード文と結論の関係性が明確ではありません。'],
      ]},
    ]

    if (type === 'email'){
      const subtask_templates_list = SubtaskData[grade][type]['feedback_templates']['content']
      subtask_templates_list.forEach(templates_subtask => {
        const label_name = templates_subtask[0] + '_subtask_label'
        tagFeedbackTemplates.push(
          {
            tag: templates_subtask[0], tagMustExist: false, feedbacks: [
              [feedback.content[label_name] >= 1, templates_subtask[1]],
              [feedback.content[label_name]?.between(0.5, 1), templates_subtask[2]]
            ]
          }
        )
      })
    }
    else if(type === 'summary'){
      let subtask_templates_list;
      if (grade === 'G15'){
        subtask_templates_list = SubtaskData[grade][type]['feedback_templates']['content'][passage_type]
      }
      else if (grade === 'G20'){
        subtask_templates_list = SubtaskData[grade][type]['feedback_templates']['content']
      }
      else{
        throw new Error(`Unsupported grade: ${grade}`);
      }
      subtask_templates_list.forEach(templates_subtask => {
        const label_name = templates_subtask[0] + '_subtask_label'
        let model_sentence_feedback = '';
        // if (feedback.content[templates_subtask[0]]['model_sentence'] != null){
        //   model_sentence_feedback = '下の模範解答を参考にしながら要約しなおしてみましょう。<br/><div class="p-1 m-1" style="border: 1px solid #000000">' + feedback.content[templates_subtask[0]]['model_sentence'] + '</div>'
        // }
        tagFeedbackTemplates.push(
          {
            tag: templates_subtask[0], tagMustExist: false, feedbacks: [
              [feedback.content[label_name] >= 1, templates_subtask[1] + model_sentence_feedback],
              [feedback.content[label_name]?.between(0.5, 1), templates_subtask[2] + model_sentence_feedback],
              [feedback.content[templates_subtask[0]]?.copy_score >= 1, '自分の言葉に置き換えて要約しましょう。']
            ]
          }
        )
      })
    }
    else if(type === 'opinion'){
      // no type specific process for opinion
      ;
    }
    else{
      throw new Error(`Unsupported type: ${type}`);
    }


     tagFeedbackTemplates.forEach(template => {
      pushTagFeedback(detailedFeedbacks, sentences, template)
    })

    // console.log(detailedFeedbacks)
    return detailedFeedbacks;
  }
}

export default function EikenAssignmentDetail(props) {
  const { startChat, setShowChat } = useContext(ChatContext);
  const { user, tenantId, event, fireEvent } = useContext(AuthContext);
  const { scoreAssignmentWrapped, generateQuestionEikenWrapped } = useApiWithPointUpdate();
  const { showConfirmationModal } = useContext(PointConfirmationContext);

  const isMobile = useIsMobile();

  const [searchParams, setSearchParams] = useSearchParams();
  const [grade, setGrade] = useState(searchParams.get('grade'));
  const [type, setType] = useState(searchParams.get('type'));
  const [userId, setUserId] = useState(searchParams.get('user_id'));
  const [assignmentId, setAssignmentId] = useState(searchParams.get('id'));
  const [revisionId, setRevisionId] = useState(searchParams.get('revision_id'));
  const [topic, setTopic] = useState(searchParams.get('topic'));
  const [isSample, setIsSample] = useState(searchParams.get('isSample', false));

  const [loadingMessage, setLoadingMessage] = useState(null);
  const [readOnly, setReadOnly] = useState(false)

  const [question, setQuestion] = useState(null);

  const [answer, setAnswer] = useState('');
  const [feedback, setFeedback] = useState(null);
  const [scoring, setScoring] = useState(false);
  const [title, setTitle] = useState(null);
  const [revisionData, setRevisionData] = useState(null);

  const dialogRef = useRef(null);

  const [answerChanged, setAnswerChanged] = useState(false);

  const [showSummaryDialog, setShowSummaryDialog] = useState(false);

  const getUserAssignment = async (assignment_id, revision_id) => {
    if (!user) {
      return
    }
    const targetUserId = userId ? userId : user.userId
    return await api.getUserAssignment(tenantId, targetUserId, assignment_id, revision_id);
  }

  function getInitialChatMessage(question, feedback, answer, aFeedback) {
    const statement = getStatement(question)
    const levels = {
      'G15': 'B2',
      'G20': 'B1',
      'G25': 'A2',
      'G30': 'A1',
    }
    let message = "私は以下の英語ライティングの問題をもらいました。\n" +
      "設問はこちら：" + JSON.stringify(statement) + '\n' +
      "問題はこちら：" + (question["question"] ?? '') +
      (question["passage"] ?? '') + (question["email"] ?? '') + '\n' +
      "キーワードはこちら：" + (question["keywords"] ?? '') + '\n'
    if (answer) {
      message += "私の作文はこちら：" + answer + '\n'
    }
    else {
      message += "私の作文はまだ書いていません。書くためのガイダンスをお願いします。"
    }

    if (aFeedback) {
      if (aFeedback["type"] == "tag") {
        let full_sentence = ""
        feedback["grammar_vocabulary"]["sentences"]?.forEach(sentence =>
        {
          if (sentence["tag"] == aFeedback["id"]) {
            full_sentence += sentence["org_sent"];
          }
        })
        message += "私の文章の問題となる箇所はこちら：" + full_sentence + '\n';
      } else if (aFeedback["type"] == "sentence") {
        message += "私の文章に以下の文法と語彙の問題点があります。"
      }

      message += "問題点はこちら：" + aFeedback["text"] + '\n'
      message += "解答ではCEFRレベル" + levels[question["grade"]] + "の英語を使います";
    }
    return message;
  }

  const showChat = (aFeedback) => {
    if (!question) return;
    if (user == null) {
      dialogRef.current?.show({
        title: 'UGUIS先生にチャットで質問',
        body: 'AIのUGUIS先生にチャットでアドバイスがもらえます。チャットを始めるには、無料のユーザ登録をしてログインしてください。',
        actions: [
          Dialog.Action(
            'キャンセル',
            (dialog) => dialog.hide(),
            'btn-secondary'
          ),
          Dialog.Action(
            'ログイン',
            async () => {
              eventBus.dispatch("login");
            },
            'btn-secondary'
          ),
          Dialog.DefaultAction(
            'ユーザ登録',
            async () => {
              eventBus.dispatch("signup");
            },
            'btn-success'
          )
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide()
        }
      })
    }
    else {
      setShowChat(true)
      startChat(getInitialChatMessage(question, feedback, answer, aFeedback));
    }
  }

  // TODO: show confirmation modal when page reloaded or direct landing
  // useEffect(() => {

  // }, [])

  useEffect(() => {
    setGrade(searchParams.get('grade'));
    setType(searchParams.get('type'));
    setUserId(searchParams.get('user_id'));
    setAssignmentId(searchParams.get('id'));
    setRevisionId(searchParams.get('revision_id'))
    setTopic(searchParams.get('topic'));
    setIsSample(searchParams.get('isSample', false));
  }, [searchParams])

  useEffect(() => {
    if (assignmentId) {
      loadAssignment(assignmentId, revisionId);
    }
  }, [user, userId, assignmentId, revisionId]);

  useEffect(() => {
    // DO NOT get question again if assignmentId is valid
    if (!assignmentId && grade && type) {
      getQuestion(grade, type, isSample);
    }
  }, [user, assignmentId, grade, type, topic, isSample]);

  function getQuestion(_grade, _type, _isSample){
    if (_isSample) {
      loadSample(_grade, _type);
    }
    else {
      generateQuestion(_grade, _type);
    }
  }

  useEffect(() => {
    if (event?.name == 'chat') {
      showChat(event.data)
    }
  }, [event]);

  function getScores() {
    if (feedback) {
      return new Scores(question.type, feedback, question.get_max_score())
    }
  }

  async function loadAssignment(id, revision_id) {
    setLoadingMessage('課題をロードしています...');
    const res = await getUserAssignment(id, revision_id);
    if (!res) return

    if (revision_id) { setReadOnly(true) }
    const assignment = res.assignment;
    setQuestion(assignment.question);
    setAnswer(assignment.essay);
    setTitle(`${assignment.title}`);
    setGrade(assignment.question.grade);
    setType(assignment.question.type);
    setFeedback(assignment.feedback);
    setLoadingMessage(null);
    setAnswerChanged(false);
    setAnswer(assignment.essay);
    setRevisionData({
      revisionNumber: res.revision_number,
      totalScore: res.score,
      revisions: res.revisions
    })
    // TODO: load localStorage
    // const storedData = JSON.parse(localStorage.getItem(assignment_id))
    // if (storedData) {
    //   this.setState(prevState => ({
    //     ...prevState,
    //     assignment: {
    //       ...assignment.assignment,
    //       feedback: storedData.feedback
    //     },
    //     draft: storedData.draft
    //   }))
    // }
  }

  function loadSample(_grade, _type) {
    const assignment = Assignment.fromJSON(SampleAssignments[_grade][_type])
    setQuestion(assignment.question);
    setTitle(`${assignment.title}（サンプル）`);
    setAnswer(assignment.essay);
  }

  async function generateQuestion(_grade, _type) {
    if (assignmentId) {
      setSearchParams({'grade': _grade, 'type': _type});
      return
    }
    if (!user) {
      // To avoid calling generation twice when reloading page
      return
    }
    setLoadingMessage('問題を生成しています...')
    setAnswer(null);
    setFeedback(null);
    setAnswerChanged(false);
    let subtopic = null;

    let topicSampler = new SamplingWithoutReplacement(
      new JsonLocalStorage(`Uguis_remainingIndexes__eiken__${_grade}__${_type}`),
      TopicData['eiken'][_grade][_type].flatMap(t => t.subTopics));

    if (!topic) {
      subtopic = topicSampler.sample();
    }
    else {
      // This process is left in case the topic is provided
      const topics = TopicData['eiken'][_grade][_type]
      const target_topic = topics.find(candidate => candidate.id === topic);
      subtopic = target_topic.subTopics.random();
    }

    let [timeTaken, generated_question] = await utils.timeAsync(async () => {
      return await generateQuestionEikenWrapped(_grade, _type, subtopic);
    });

    generated_question['grade'] = _grade;
    generated_question['type'] = _type;
    generated_question['exam_type'] = 'eiken';

    const question = Question.fromJSON(generated_question);
    setLoadingMessage(null);
    setTitle(question.question);
    setQuestion(question);

    gtm.sendGenerationQuestionEvent(_grade, _type, subtopic, timeTaken);
  }

  async function handleNewAssignment() {
    if (user == null) {
      dialogRef.current?.show({
        title: '新しい問題を学習',
        body: '新しいの問題をAI自動生成します。生成するには、無料のユーザ登録をしてログインしてください。',
        actions: [
          Dialog.Action(
            'キャンセル',
            (dialog) => dialog.hide(),
            'btn-secondary'
          ),
          Dialog.Action(
            'ログイン',
            async () => {
              eventBus.dispatch("login");
            },
            'btn-secondary'
          ),
          Dialog.DefaultAction(
            'ユーザ登録',
            async () => {
              eventBus.dispatch("signup");
            },
            'btn-success'
          )
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide()
        }
      })
      return;
    }
    else {
      const confirmed = await showConfirmationModal('問題生成')
      if (!confirmed) return;
      if (answerChanged) {
        dialogRef.current?.show({
          title: '新しい問題を学習',
          body: '今の解答を保存してから新しい問題をAI自動生成しますか？',
          actions: [
            Dialog.Action(
              'キャンセル',
              (dialog) => {
                dialog.hide();
              },
              'btn-secondary'
            ),
            Dialog.Action(
              '保存せずに問題を生成',
              () => {
                generateQuestion(grade, type);
              },
              'btn-danger'
            ),
            Dialog.DefaultAction(
              '保存して問題を生成',
              async () => {
                await handleScore()
                generateQuestion(grade, type);
              },
              'btn-success'
            )
          ],
          bsSize: 'small',
          onHide: (dialog) => {
            dialog.hide();
          }
        })
      }
      else {
        dialogRef.current?.show({
          title: '新しい問題を学習',
          body: '新しい問題をAI自動生成しますか？',
          actions: [
            Dialog.Action(
              'キャンセル',
              (dialog) => {
                dialog.hide();
              },
              'btn-secondary'
            ),
            Dialog.DefaultAction(
              '問題を生成',
              () => {
                generateQuestion(grade, type);
              },
              'btn-success'
            )
          ],
          bsSize: 'small',
          onHide: (dialog) => {
            dialog.hide();
          }
        });
      }
    }

  }

  function tryNavigate() {
    // return true if can navigate, else false
    return new Promise(async (resolve, reject) => {
      if (!answerChanged) {
        resolve(true);
        return;
      }
      dialogRef.current?.show({
        title: '画面移動',
        body: '解答を保存して移動しますか？',
        actions: [
          Dialog.Action(
            'キャンセル',
            (dialog) => {
              dialog.hide();
              resolve(false);
            },
            'btn-secondary'
          ),
          Dialog.Action(
            '保存せずに移動',
            () => {
              resolve(true);
            },
            'btn-danger'
          ),
          Dialog.DefaultAction(
            '保存してから移動',
            async () => {
              await handleScore();
              resolve(true);
            },
            'btn-success'
          )
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide();
          resolve(false);
        }
      })
    });
  }

  function handleAnswerChanged(data, text) {
    setAnswerChanged(true);
    setAnswer(text);
  }

  function handleRevisionChanged(revision_id) {
    const params = new URLSearchParams(searchParams)
    params.set('id', assignmentId)
    params.set('revision_id', revision_id)
    setSearchParams(params)
  }

  function handleEditAssignment() {
    setReadOnly(false)
  }

  async function handleScore() {
    await score({
      question: question,
      essay: answer,
      title: title,
    });
    setShowSummaryDialog(true);
  }

  async function score(assignment) {
    const pointConsumingConfirmed = await showConfirmationModal('採点')
    if (!pointConsumingConfirmed) {
      return
    }
    setScoring(true);
    // score
    let [timeTaken, feedback] = await utils.timeAsync(async () => {
      return await scoreAssignmentWrapped(assignment)
    });
    assignment.feedback = feedback;

    let coherence_score
    switch (assignment.question.type) {
      case 'opinion':
        coherence_score = feedback.coherence.sys_coherence
        break
      case 'email':
        coherence_score = feedback.content.sys_content
        break
      case 'summary':
        coherence_score = feedback.coherence.sys_coherence
        break
      default:
        throw new Error(`unknown question type: ${assignment.question.type}`)
    }

    assignment.score = feedback.scores.total_score

    gtm.sendScoreEvent(assignment.question.grade, assignment.question.type, assignment.score, timeTaken)

    // if logged in, save feedback
    if (user != null) {
      console.log('saving', assignment)
      if (!assignmentId) {
        // new assignment, create
        // TODO
        const res = await api.createUserAssignment(assignment);
        setAssignmentId(res.assignment_id);
      }
      else {
        // existing assignment, update
        // console.log('saving:upate')
        const res = await api.updateUserAssignment(assignmentId, assignment)

        if (revisionId) {
          searchParams.delete("revision_id")
          setSearchParams(searchParams)
        } else {
          loadAssignment(assignmentId, revisionId);
        }
      }
    }
    else {
      console.log('not logged in. skip saving.')
    }

    // show feedback and hide spinner
    setFeedback(feedback)
    setScoring(false);
    setAnswerChanged(false);
  }

  let layout;
  if (isMobile) {
    layout = [ [["question", "question_supplement", "answer"]] ];
  }
  else if (type == "opinion") {
    if (feedback == null) {
      layout = [ [], [["question"], ["answer"]] ];
    } else {
      layout = [ [], [["question", "answer"], ["feedback"]] ];
    }
  }
  else if (type == "summary" || type == "email") {
    if (feedback == null) {
      layout = [ ["question"], [["question_supplement"], ["answer"]] ];
    } else {
      layout = [ [["question", "question_supplement", "answer"], ["feedback"]] ];
    }
  }

  let actionButton = {
    disabled: {
      type: "light",
      caption: "採点済",
      image: "/ai_dark.svg",
    },
    enabled: {
      type: "primary",
      caption: ["AI", "採点"],
      image: "/ai.svg",
      callback: handleScore,
    }
  }

  function renderAdditionalBreadcrumb() {
    return (
      <RevisionSelectView
        revisionData={revisionData}
        onRevisionChanged={handleRevisionChanged}
      />
    )
  }

  function renderHeader() {
    return question && (
      <Row className="py-3 assignment-title-pane">
        <Stack direction='horizontal'>
          <div>
            <EikenLogo grade={grade} style={{ width: '150' }} />
            <span className='fw-bold ms-5 h3'>{QuestionTypeName(type)}</span>
          </div>
        </Stack>
      </Row>
    )
  }

  function renderAnswerTitle() {
    return question?.type == "email" && (
      <Card.Title>
        <ProfileIcon height="30" alt="avatar" loading="lazy" className='rounded-circle me-2'/>
        <span className="h6">me</span>
      </Card.Title>
    )
  }

  function renderPreAnswer() {
    const statement = (question) ? getStatement(question) : null;
    return question?.type == "email" && (
      <div className="email email-reply">
        <div>{question.greeting}</div>
        <div>{statement['content_start']}</div>
      </div>
    )
  }

  function renderPostAnswer() {
    return question?.type == "email" && (
      <div className="email email-reply">{question.closing}</div>
    )
  }

  function getPlaceholder() {
    return device.isIOSApp() === false ? '解答を英文で入力、または、手書きをカメラで読み込みましょう' : '解答を英文で入力しましょう'
  }

  if (!question) {
    return null;
  }

  let feedbackCards;
  if (feedback) {
    const contentFeedbackData = getContentCoherenceFeedbacks(feedback, question.type, question.grade, question.passage_type)
    const contentFeedbackCards = contentFeedbackData.map(data => ({
      id: data.tag,
      selectType: 'tag',
      isContent: true,
      isCoherence: true,
      isVocabulary: false,
      isGrammar: false,
      renderContent: () =>
          <ContentCoherenceFeedbackCardContent feedback={data}/>
      ,
      showChat: () => {
        fireEvent("chat", {
          'type': 'tag',
          'id': data.tag,
          'text': data['texts'].map(text => parse(text))
        })
      }
    }));
    let vocabularyFeedbackCards;
    if (feedback.grammar_vocabulary.sentences) {
      vocabularyFeedbackCards = feedback.grammar_vocabulary.sentences.map((sentence, i) =>
        ({ "id": i, "sentence": sentence })
      ).filter((indexedSentence) =>
        indexedSentence.sentence.grammar_err.length > 0 || indexedSentence.sentence.vocabulary_err.length > 0
      ).map((indexedSentence) => {
        const sentence = indexedSentence.sentence;
        const id = indexedSentence.id;
        return {
          id: id,
          selectType: 'sentence',
          isContent: false,
          isCoherence: false,
          isVocabulary: true,
          isGrammar: true,
          renderContent: () =>
            <GrammarVocabularyFeedbackCardContent feedback={sentence}/>
          ,
          showChat: () => {
            let text = "元の文章は：" + sentence["org_sent"] + " 薦められた修正は：" + sentence["cor_sent"]
            fireEvent("chat", {
              'type': 'sentence',
              'id': sentence.id,
              'text': text
            })
          }
        }
      }
      );
    } else {
      vocabularyFeedbackCards = []
    }

    feedbackCards = vocabularyFeedbackCards.concat(contentFeedbackCards);

    // console.log('feedbackCards', feedbackCards)
  }

  // console.log('scores', scores)

  return (
    <div style={{ backgroundColor: 'var(--light-green)'}}>
      <Helmet>
        <title>UGUIS.AI - 英語ライティング練習サービス - 学習{isSample ? '（サンプル）' : ''}</title>
      </Helmet>
      <AssignmentView
        title={title}
        loadingMessage={loadingMessage}
        scoring={scoring}
        question={question}
        answer={answer}
        answerTabTitle={feedback ? '解答と添削' : 'あなたの解答'}
        answerTitle={question?.type == "email" ? `Re: ${title}` : 'あなたの解答'}
        answerChanged={answerChanged}
        feedback={feedback}
        feedbackCards={feedbackCards}
        scores={getScores()}
        layout={layout}
        actionButton={actionButton}
        canNavigate={tryNavigate}
        onAnswerChanged={handleAnswerChanged}
        renderAdditionalBreadcrumb={revisionData?.revisions.length > 0 ? renderAdditionalBreadcrumb : null}
        renderHeader={renderHeader}
        renderAnswerTitle={renderAnswerTitle}
        renderPreAnswer={renderPreAnswer}
        renderPostAnswer={renderPostAnswer}
        suggestedLength={(question) ? getStatement(question)['suggested_length'] : null}
        placeholder={getPlaceholder()}
        readOnly={readOnly}
        chatChards={[
          {"title": "もっとアドバイスをください", "prompt": "もっとアドバイスをください"},
          {"title": "ヒント出して", "prompt": "ヒント出して"},
          {"title": "解答例出して", "prompt": "解答例出して"},
          {"title": "模範解答出して", "prompt": "模範解答出して"},
          {"title": "問題和訳して", "prompt": "問題和訳して"},
          {"title": "別解答教えて", "prompt": "別解答教えて"},
        ]}
      />
      <Dialog ref={dialogRef} />
      <SummaryDialog scores={getScores()} show={showSummaryDialog} onHide={() => {setShowSummaryDialog(false)}}/>
    </div>
  );
}
